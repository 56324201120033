import React from "react";

const AssMeanMedian = ({ mean, stdDeviation, min, max, median, title }) => {
    const StatItem = ({ label, value }) => (
        <div className="flex flex-col items-center justify-center p-2 bg-white rounded-lg shadow h-full">
            <span className="text-xs font-semibold text-gray-500 uppercase">{label}</span>
            <span className="text-sm font-bold text-gray-800">
                {typeof value === 'number' ? value.toFixed(2) : value}
            </span>
        </div>
    );

    return (
        <div className="mb-6">
            <h1 className="text-xl font-bold mb-3 ml-5">
                {title ? title : "Student Total Score Statistics"}
            </h1>
            <div className="flex justify-center"> {/* New container for centering */}
                <div className="bg-gray-100 p-3 rounded-xl sexy-border w-[90%]"> {/* Adjusted width and padding */}
                    <div className="grid grid-cols-5 gap-1"> {/* Reduced gap */}
                        <StatItem label="MINIMUM" value={min} />
                        <StatItem label="MEDIAN" value={median} />
                        <StatItem label="MAXIMUM" value={max} />
                        <StatItem label="MEAN" value={mean} />
                        <StatItem label="STD DEV" value={stdDeviation} />
                    </div>
                </div>
            </div>
        </div>
    );
};

export default AssMeanMedian;