import React, {useEffect, useState, useRef} from "react";

function CloneModal({
                        isOpen,
                        onClose,
                        onConfirm,
                        assignmentName,
                        setAssignmentName,
                        dueDate,
                        dueTime,
                        timezone,
                        setDueDate,
                        setDueTime,
                        errorMessage,
                        setErrorMessage,
                        originalCloneName,
                        submissionType,
                        setSubmissionType,
                        isEssay,
                        cloneClassId,
                        setCloneClassId,
                        userClasses,
                        isGroupSubmission,
                        setIsGroupSubmission,

                    }) {
    const modalRef = useRef();
    const [dateFocused, setDateFocused] = useState(false);
    const [timeFocused, setTimeFocused] = useState(false);
    const [isClassSelectionVisible, setIsClassSelectionVisible] = useState(false);

    useEffect(() => {
        const handleClickOutside = (event) => {
            if (modalRef.current && !modalRef.current.contains(event.target)) {
                onClose();
            }
        };

        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, [onClose]);

    return (
        <div
            className="fixed inset-0 bg-black bg-opacity-50 overflow-y-auto h-full w-full flex z-50 justify-center items-center">
            <div ref={modalRef} className="sexy-border relative mx-auto p-6 bg-white w-full max-w-[400px]">
                <div className="space-y-4">
                    <div className="flex flex-row justify-between">
                        <h2 className="custom-title-sm text-black font-bold">Clone {originalCloneName}</h2>
                        <button className="desktop:hover:opacity-50 transition-all duration-300" onClick={onClose}>
                            <img src="/assets/X.png" alt="Exit" className="w-[20px] h-[20px]"/>
                        </button>
                    </div>
                    <form onSubmit={(e) => {
                        e.preventDefault();
                        onConfirm();
                    }}>
                        <div className="space-y-3">
                            <div className="flex flex-col mb-5">
                                <div
                                    className={`flex items-center input-entry-box font-normal bg-white ${errorMessage !== "" ? "border-red-500" : ""}`}
                                    onFocus={() => setErrorMessage("")}>
                                    <input
                                        type="text"
                                        value={assignmentName}
                                        className={`actual-input ml-2.5 mr-2.5 ${errorMessage !== "" ? "text-red-500" : ""}`}
                                        placeholder="Assignment Name"
                                        onChange={(e) => setAssignmentName(e.target.value)}
                                    />
                                </div>
                                {!isEssay && (
                                    <div className="flex items-center input-entry-box font-normal bg-white mt-3">
                                        <select
                                            value={submissionType}
                                            className="ml-2.5 actual-input text-black placeholder-black"
                                            onChange={(e) => setSubmissionType(e.target.value)}
                                        >
                                            <option value="" disabled selected>Define Submission</option>
                                            <option value="student">Student</option>
                                            <option value="teacher">Teacher</option>
                                        </select>
                                    </div>
                                )}
                                {submissionType != 'teacher' &&
                                    <div
                                        className={`relative flex items-center mt-3 font-normal bg-white input-entry-box ${errorMessage !== "" ? "border-red-500" : ""}`}
                                        onFocus={() => {
                                            setDateFocused(true);
                                            setErrorMessage("");
                                        }}
                                        onBlur={() => setDateFocused(false)}>
                                        <div
                                            className={`flex items-center w-full ${errorMessage !== "" ? "border-red-500" : ""}`}>
                                            {!dateFocused && dueDate === "" && (
                                                <div
                                                    className="absolute ml-2.5 pointer-events-none text-gray-400 bg-white w-36"
                                                    style={{userSelect: "none"}}>
                                                    Due Date
                                                </div>
                                            )}
                                            <input
                                                type="date"
                                                value={dueDate}
                                                className={`actual-input ml-2.5 mr-2.5 w-full ${errorMessage !== "" ? "text-red-500" : ""}`}
                                                onChange={(e) => setDueDate(e.target.value)}
                                                onFocus={() => setErrorMessage("")}
                                            />
                                        </div>
                                        <div
                                            className="size-6 flex items-center justify-center pointer-events-none absolute right-0 bg-white mr-2">
                                            <img
                                                src={errorMessage === "" ? "/assets/calendar.png" : "/assets/red-calendar.png"}
                                                className={dateFocused || errorMessage !== "" ? "size-5" : "size-5 opacity-30"}
                                                alt={errorMessage === "" ? "Assignment Due Date Icon" : "Error with Assignment Due Date"}
                                            />
                                        </div>
                                    </div>}
                                {submissionType != "teacher" &&
                                    <div
                                        className={`relative flex items-center mt-3 font-normal bg-white input-entry-box ${errorMessage !== "" ? "border-red-500" : ""}`}
                                    >
                                        <label className="w-full cursor-pointer">
                                            <div
                                                className={`flex items-center w-full ${errorMessage !== "" ? "border-red-500" : ""}`}
                                                onClick={() => {
                                                    setTimeFocused(true);
                                                    setErrorMessage("");
                                                }}
                                            >
                                                {!timeFocused && dueTime === "" && (
                                                    <div
                                                        className="absolute ml-2.5 pointer-events-none text-gray-400 bg-white w-36"
                                                        style={{userSelect: "none"}}
                                                    >
                                                        Due Time
                                                    </div>
                                                )}
                                                <input
                                                    type="time"
                                                    value={dueTime}
                                                    className={`actual-input ml-2.5 mr-2.5 w-full ${errorMessage !== "" ? "text-red-500" : ""}`}
                                                    onChange={(e) => setDueTime(e.target.value)}
                                                    onFocus={() => {
                                                        setTimeFocused(true);
                                                        setErrorMessage("");
                                                    }}
                                                    onBlur={() => setTimeFocused(false)}
                                                />
                                                <div className="flex items-center absolute right-0 bg-white mr-2">
                                                    <span className="text-sm text-gray-500 mr-2">{timezone}</span>
                                                    <div
                                                        className="size-6 flex items-center justify-center pointer-events-none">
                                                        <img
                                                            src={errorMessage === "" ? "/assets/clock.png" : "/assets/red-clock.png"}
                                                            className={timeFocused || errorMessage !== "" ? "size-5" : "size-5 opacity-30"}
                                                            alt="Clock icon"
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                        </label>
                                    </div>
                                }
                                {(submissionType === "student" || isEssay) && (
                                    <div className="flex justify-center">
                                        <div className="flex items-center">
                                            <input
                                                type="checkbox"
                                                id="groupcheckbox"
                                                checked={isGroupSubmission}
                                                className="sr-only peer"
                                                onChange={(e) => setIsGroupSubmission(e.target.checked)}
                                            />
                                            <label
                                                htmlFor="groupcheckbox"
                                                className={`
        relative inline-flex h-6 w-11 items-center rounded-full transition-colors duration-200 ease-in-out mr-2 cursor-pointer mt-5
        ${isGroupSubmission
                                                    ? 'bg-orange-200 border-orange-300'
                                                    : 'bg-gray-200 hover:bg-gray-300'
                                                }
      `}
                                            >
                                                <span
                                                    className={`
          inline-block h-4 w-4 transform rounded-full bg-white transition-transform duration-200 ease-in-out
          ${isGroupSubmission ? 'translate-x-6' : 'translate-x-1'}
        `}
                                                />
                                            </label>
                                            <label
                                                htmlFor="groupcheckbox"
                                                className="mt-5 text-sm font-medium text-black"
                                            >
                                                Allow Group Submission
                                            </label>
                                        </div>
                                    </div>
                                )}
                            </div>
                            {errorMessage && (
                                <div className="text-red-500 text-sm my-2">
                                    {errorMessage}
                                </div>
                            )}
                        </div>
                        <div className="flex flex-col w-full mb-5 mt-8">
                            <button
                                type="button" // Change this from implicit 'submit' to explicitly 'button'
                                className="flex items-center text-sm text-gray-500 mb-2"
                                onClick={(e) => {
                                    e.preventDefault(); // Prevent any form submission
                                    e.stopPropagation(); // Stop event from bubbling up
                                    setIsClassSelectionVisible(!isClassSelectionVisible);
                                }}
                            >
                                <img
                                    src="/assets/back_arrow.png"
                                    className={`size-5 opacity-60 transform ${isClassSelectionVisible ? 'rotate-90' : '-rotate-90'} mr-2`}
                                    alt="Toggle class selection"
                                />
                                Select Class (Optional)
                            </button>
                            {isClassSelectionVisible && (
                                <div className="flex items-center input-entry-box font-normal bg-white mt-3">
                                    <select
                                        value={cloneClassId}
                                        className="ml-2.5 actual-input text-black placeholder-black w-full"
                                        onChange={(e) => setCloneClassId(e.target.value)}
                                    >
                                        {userClasses.map((classItem) => (
                                            <option key={classItem.id} value={classItem.id}>
                                                {classItem.name}
                                            </option>
                                        ))}
                                    </select>
                                </div>
                            )}
                        </div>
                        <div className="flex flex-col space-y-2 mt-8">


                            <div className="flex justify-end space-x-2">
                                <button
                                    type="submit"
                                    disabled={!assignmentName ||
                                        ((!dueDate || !dueTime) && submissionType !== "teacher") ||
                                        (!isEssay && !submissionType) ||
                                        !cloneClassId}
                                    className={`sexy-button w-full ${assignmentName &&
                                    (dueDate && dueTime || submissionType === "teacher") &&
                                    (isEssay || submissionType) &&
                                    cloneClassId
                                        ? ""
                                        : "cursor-not-allowed opacity-50"
                                    }`}
                                >
                                    Clone Assignment
                                </button>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    );
}

export default CloneModal;