import React from 'react';
import {useParams, Link} from 'react-router-dom';
import AddDropBig from "../../components/Teacher/AddDropBig.js"
import SideBar from '../../components/SideBar';

const ClassSettings = () => {
    const {class_id} = useParams();

    return (
        <div id="Main-container" className="flex flex-row w-full min-h-screen">
            {/* Left Sidebar */}
            <div
                id="Left-Sidebar"
                className="fixed left-0 top-0 h-screen w-side-bar-size overflow-y-auto"
            >
                <SideBar activePage="Settings"/>
            </div>

            {/* Main Content */}
            <div className="ml-side-bar-size flex-1">
                {/* Header */}
                <div className="p-8 pb-4">
                    <div className="flex flex-row items-center">
                        <Link to="/tclasses">
                            <span className="text-black text-xl font-medium">My Classes</span>
                        </Link>
                        <Link to="/tclasses">
                            <div className="size-6 flex items-center justify-center">
                                <button>
                                    <img src="/assets/back_arrow.png" className="size-6" alt="Back"/>
                                </button>
                            </div>
                        </Link>
                        <Link to="/tassignments">
                            <span className="text-black text-xl font-medium">Class Settings</span>
                        </Link>
                    </div>
                </div>

                {/* AddDropBig Component */}
                <div id="Right-content" className="w-full overflow-y-auto">
                    <AddDropBig class_id={class_id}/>
                </div>
            </div>

        </div>
    );
};

export default ClassSettings;