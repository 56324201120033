import { SortableContext, verticalListSortingStrategy } from '@dnd-kit/sortable';
import { FieldArray, useFormikContext } from 'formik';
import React, { useState, useEffect, useRef } from 'react';
import { TQuestionNew } from './TQuestionNew';
import { gradeApi } from '../../../api.js';
import { useAuth } from '../../../auth/AuthContext';
import { Loader2, FileUp } from 'lucide-react';

export const TQuestionColumnNew = ({
    fieldName,
    examPaper,
    isPublished,
    disabledStyle,
    isCreated,
    isDraftDataLoading,
    viewedQuestions,
    setViewedQuestions,
    setError,
    error,
    uploadQuestions,
    updateViewedQuestions,
    handleChange,
    copiedRubricItems,
    onCopyRubricItems,
    onPasteRubricItems,
    isValid,
    assignmentId,
    generateRubricsRef
}) => {
    const [showAllRubricsModal, setShowAllRubricsModal] = useState(false);
    const [totalPoints, setTotalPoints] = useState('');
    const [isGeneratingAllRubrics, setIsGeneratingAllRubrics] = useState(false);
    const [pollingInterval, setPollingInterval] = useState(null);
    const { setFieldValue, submitForm } = useFormikContext();
    const { user } = useAuth();
    const { errors } = useFormikContext();
    const questionsContainerRef = useRef(null);

    const scrollToBottom = () => {
        setTimeout(() => {
            if (questionsContainerRef.current) {
                questionsContainerRef.current.scrollIntoView({ behavior: 'smooth', block: 'end' });
            }
        }, 100); // Small delay to ensure the new question is rendered
    };

    useEffect(() => {
        return () => {
            if (pollingInterval) {
                clearInterval(pollingInterval);
            }
        };
    }, [pollingInterval]);

    const startPolling = () => {
        const interval = setInterval(checkRubricGenerationStatus, 3000); // Poll every 3 seconds
        setPollingInterval(interval);
    };
    const calculateTotalExamScore = (examPaper) => {
        return examPaper.reduce((totalScore, question) => {
            const questionScore = question.subQuestions.reduce((subTotal, subQuestion) => {
                if (subQuestion.questionType === 'mcq') {
                    // For MCQ, use the weight directly
                    return subTotal + (parseInt(subQuestion.weight) || 0);
                } else {
                    // For freeform, get max score from rubric items
                    const maxRubricScore = subQuestion.rubricItems?.reduce((max, item) => {
                        return Math.max(max, parseInt(item.value) || 0);
                    }, 0) || 0;
                    return subTotal + maxRubricScore;
                }
            }, 0);
            return totalScore + questionScore;
        }, 0);
    };

    const getLoadingStateStyles = (defaultStyle = '') => {
        return `${defaultStyle} ${isGeneratingAllRubrics ? 'opacity-50 cursor-not-allowed' : ''}`;
    };

    const isFieldDisabled = (defaultDisabled = false) => {
        return isPublished || defaultDisabled || isGeneratingAllRubrics;
    };
    const checkRubricGenerationStatus = async () => {
        try {
            const response = await gradeApi.get({
                path: `/api/assignment/${assignmentId}/generate-all-rubrics-status/`,
                config: {
                    headers: { Authorization: `Bearer ${user.session_token}` }
                }
            });

            if (response.data.status === 'completed') {
                clearInterval(pollingInterval);
                setPollingInterval(null);
                setIsGeneratingAllRubrics(false);

                // Update the exam paper with the generated rubrics
                setFieldValue('examPaper', response.data.examPaper);

                // Submit the form to save the changes
                setTimeout(async () => {
                    await submitForm();
                }, 500);
            } else if (response.data.status === 'failed') {
                clearInterval(pollingInterval);
                setPollingInterval(null);
                setIsGeneratingAllRubrics(false);
                setError('Failed to generate rubrics. Please try again.');
            }
            // If status is 'in_progress', continue polling
        } catch (error) {
            console.error('Error checking rubric generation status:', error);
            clearInterval(pollingInterval);
            setPollingInterval(null);
            setIsGeneratingAllRubrics(false);
            setError('Error checking rubric generation status. Please try again.');
        }
    };

    const handleGenerateAllRubrics = async () => {
        const numericTotalPoints = parseInt(totalPoints, 10);
        if (isNaN(numericTotalPoints) || numericTotalPoints <= 0) {
            setError('Please enter a valid positive number for total points.');
            return;
        }
        setShowAllRubricsModal(false);
        setIsGeneratingAllRubrics(true);
        setError("");
        try {
            const response = await gradeApi.post({
                path: '/api/assignment/generate-all-rubrics/',
                body: {
                    examPaper: examPaper,
                    totalPoints: numericTotalPoints,
                    assignmentId: assignmentId,
                },
                config: {
                    headers: { Authorization: `Bearer ${user.session_token}` }
                }
            });

            if (response.data.status === 'started') {
                startPolling();
            } else {
                throw new Error('Failed to start rubric generation');
            }
        } catch (error) {
            console.error('Error generating rubrics:', error);
            console.log(error?.response?.data?.error);
            setError(error?.response?.data?.error || 'Failed to generate rubrics. Please try again.');
            setIsGeneratingAllRubrics(false);
        }
    };

    const getValidationErrors = (errors) => {
        if (!errors?.examPaper) {
            return [];
        }

        let errorMessages = [];

        if (Array.isArray(errors.examPaper)) {
            errors.examPaper.forEach((questionError, questionIndex) => {
                if (questionError?.description) {
                    errorMessages.push(`Question ${questionIndex + 1} missing description`);
                }

                // Check if subQuestions exists and is an array before using forEach
                if (questionError?.subQuestions && Array.isArray(questionError.subQuestions)) {
                    questionError.subQuestions.forEach((subQuestionError, subIndex) => {
                        const subLetter = String.fromCharCode(97 + subIndex);

                        if (subQuestionError?.questionSizing) {
                            errorMessages.push(`Question ${questionIndex + 1}.${subLetter} missing question sizing`);
                        }

                        if (subQuestionError?.weight) {
                            errorMessages.push(`Question ${questionIndex + 1}.${subLetter} needs valid weight (min: 1)`);
                        }

                        if (subQuestionError?.mcqOptions) {
                            errorMessages.push(`Question ${questionIndex + 1}.${subLetter} MCQ needs at least 2 options with one correct answer`);
                        }

                        if (subQuestionError?.rubricItems && Array.isArray(subQuestionError.rubricItems)) {
                            const rubricErrors = subQuestionError.rubricItems;
                            rubricErrors.forEach((rubricError, rubricIndex) => {
                                if (rubricError?.descriptor) {
                                    errorMessages.push(`Question ${questionIndex + 1}.${subLetter} rubric item ${rubricIndex + 1} missing descriptor`);
                                }
                                if (rubricError?.value) {
                                    errorMessages.push(`Question ${questionIndex + 1}.${subLetter} rubric item ${rubricIndex + 1} missing points`);
                                }
                            });
                        }
                    });
                }
            });
        }
        return errorMessages;
    };

    return (
        <div>
            <SortableContext items={examPaper} strategy={verticalListSortingStrategy}>
                <FieldArray name={fieldName}>
                    {({ remove, push }) => (
                        <div ref={questionsContainerRef}>
                            {examPaper.map((question, index) => (
                                <TQuestionNew
                                    key={`${question.id}-${index}`}
                                    index={index}
                                    fieldName={fieldName}
                                    examPaper={examPaper}
                                    question={question}
                                    isPublished={isFieldDisabled()}
                                    deleteQuestion={remove}
                                    disabledStyle={getLoadingStateStyles(disabledStyle)}
                                    viewedQuestions={viewedQuestions}
                                    setViewedQuestions={setViewedQuestions}
                                    updateViewedQuestions={updateViewedQuestions}
                                    setError={setError}
                                    handleChange={handleChange}
                                    copiedRubricItems={copiedRubricItems}
                                    onCopyRubricItems={onCopyRubricItems}
                                    onPasteRubricItems={onPasteRubricItems}
                                />
                            ))}

                            {/* Fixed Bottom Bar */}
                            <div className="fixed bottom-0 left-0 w-full bg-white border-t border-gray-200 shadow-lg px-4 py-3 z-50">
                                <div className="container mx-auto flex items-center justify-between">
                                    {/* Left side buttons group */}
                                    <div className="flex items-center space-x-24">
                                        <button
                                            type="button"
                                            disabled={isFieldDisabled()}
                                            className={getLoadingStateStyles(`flex items-center gap-2 px-4 py-2 rounded-full less-sexy-button h-[38px] transition-all duration-200 text-sm font-medium disabled:opacity-50 disabled:cursor-not-allowed ${disabledStyle}`)}
                                            onClick={() => {
                                                push({
                                                    id: examPaper.length + 1,
                                                    description: '',
                                                    subQuestions: []
                                                });
                                                setViewedQuestions(prev => new Map([...prev, [examPaper.length + 1, []]]));
                                                scrollToBottom();
                                            }}
                                        >
                                            <img src="/assets/add-circle.png" className="size-4" alt="Add question" />
                                            Add Question
                                        </button>

                                        <div className="relative group">
                                            {isGeneratingAllRubrics && (
                                                <div className="absolute bottom-full right-0 mb-2 w-max">
                                                    <div className="bg-white border border-gray-300 rounded-lg p-3 shadow-lg flex items-center">
                                                        <Loader2 className="h-5 w-5 animate-spin text-gray-500" />
                                                        <span className="ml-2 text-sm text-gray-500">Generating all rubrics...</span>
                                                    </div>
                                                    <div className="absolute -bottom-2 right-6 w-4 h-4 transform rotate-45 bg-white border-r border-b border-gray-300"></div>
                                                </div>
                                            )}
                                            <button
                                                ref={generateRubricsRef}
                                                type="button"
                                                className={`flex items-center gap-2 px-4 py-2 rounded-full less-sexy-button h-[38px] transition-all duration-200 text-sm font-medium disabled:opacity-50 disabled:cursor-not-allowed
                                                ${!(isValid) || isPublished || isDraftDataLoading ? 'opacity-50 cursor-not-allowed' : ''} 
                                                ${disabledStyle}`}
                                                disabled={!(isValid) || isFieldDisabled() || isDraftDataLoading || isPublished}
                                                onClick={() => setShowAllRubricsModal(true)}
                                            >
                                                <img src="/assets/magic-wand.png" alt="Generate All Rubrics" className="w-5 h-5" />
                                                Generate All Rubrics
                                            </button>

                                            {error && (
                                                <div className="absolute bottom-full right-0 mb-2 w-max">
                                                    <div className="bg-white border border-red-200 rounded-lg p-3 shadow-lg max-h-[200px] overflow-y-auto">
                                                        <div className="text-red-500 text-sm whitespace-nowrap mb-1 last:mb-0">
                                                            {error}
                                                        </div>
                                                    </div>
                                                    <div className="absolute -bottom-2 right-6 w-4 h-4 transform rotate-45 bg-white border-r border-b border-red-200"></div>
                                                </div>
                                            )}
                                        </div>

                                        <div className="relative group">
                                            <button
                                                type="button"
                                                className={`flex items-center gap-2 px-4 py-2 rounded-full transition-all less-sexy-button h-[38px] duration-200 text-sm font-medium disabled:opacity-50 disabled:cursor-not-allowed
                                                ${!(isValid) || isPublished || isDraftDataLoading ? 'opacity-50 cursor-not-allowed' : ''} 
                                                ${disabledStyle}`}
                                                disabled={!(isValid) || isFieldDisabled() || isDraftDataLoading || isPublished}
                                                onClick={() => uploadQuestions()}
                                            >
                                                <FileUp className="w-5 h-5" />
                                                {isCreated ? 'Update PDF' : 'Generate PDF'}
                                            </button>

                                            {!isValid && (
                                                <div className="absolute bottom-full left-0 mb-2 w-max">
                                                    <div className="bg-white border border-red-200 rounded-lg p-3 shadow-lg max-h-[200px] overflow-y-auto">
                                                        {getValidationErrors(errors).map((error, index) => (
                                                            <div key={index} className="text-red-500 text-sm whitespace-nowrap mb-1 last:mb-0">
                                                                {error}
                                                            </div>
                                                        ))}
                                                    </div>
                                                    <div className="absolute -bottom-2 left-6 w-4 h-4 transform rotate-45 bg-white border-r border-b border-red-200"></div>
                                                </div>
                                            )}
                                        </div>
                                    </div>

                                    {/* Right side points display */}
                                    <div className="flex items-center">
                                        <span className="text-2xl font-semibold">
                                            {calculateTotalExamScore(examPaper)}
                                        </span>
                                        <span className="ml-1 text-lg text-gray-600">
                                            points
                                        </span>
                                    </div>


                                </div>
                            </div>
                        </div>
                    )}
                </FieldArray>
            </SortableContext>

            {showAllRubricsModal && (
                <div className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center z-50">
                    <div className="bg-white p-6 rounded-xl shadow-lg max-w-sm w-full">
                        <h3 className="text-lg font-semibold mb-4">Generate All Rubrics</h3>
                        <p className="mb-4">Enter the total number of points for the entire assignment:</p>
                        <input
                            type="number"
                            value={totalPoints}
                            className="border rounded px-2 py-1 mb-4 w-full"
                            onChange={(e) => setTotalPoints(e.target.value)}
                        />
                        <div className="flex justify-end space-x-2 mt-4">
                            <button
                                className="less-sexy-button"
                                onClick={() => setShowAllRubricsModal(false)}
                            >
                                Cancel
                            </button>
                            <button
                                className="sexy-button"
                                disabled={isGeneratingAllRubrics}
                                onClick={handleGenerateAllRubrics}
                            >
                                Generate
                            </button>
                        </div>
                    </div>
                </div>
            )}
        </div>
    );
};