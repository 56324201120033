import React from 'react';

const ConfirmationModal = ({isOpen, onClose, onConfirm, message, children}) => {
    if (!isOpen) {
        return null;
    }

    return (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center z-50">
            <div className="bg-white p-6 rounded-xl shadow-lg max-w-sm w-full">
                <p>{message}</p>
                {children}
                <div className="flex justify-end space-x-2 mt-4">
                    <button className="less-sexy-button" onClick={onClose}>
                        Cancel
                    </button>
                    <button className="sexy-button" onClick={onConfirm}>
                        Confirm
                    </button>
                </div>
            </div>
        </div>
    );
};

export default ConfirmationModal;
