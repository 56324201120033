import React, { useCallback, useState } from 'react';

function DragAndDropUpload({ handleImageUpload, isPublished = false }) {
  const [dragging, setDragging] = useState(false);

  const handleDrop = useCallback(
    e => {
      e.preventDefault();
      e.stopPropagation();
      if (!isPublished && e.dataTransfer.files && e.dataTransfer.files.length > 0) {
        const newEvent = {
          target: {
            files: e.dataTransfer.files
          }
        };
        handleImageUpload(newEvent);
        e.dataTransfer.clearData();
        setDragging(false);
      }
    },
    [handleImageUpload, isPublished]
  );

  const handleDrag = useCallback(e => {
    e.preventDefault();
    e.stopPropagation();
  }, []);

  const handleDragIn = useCallback(
    e => {
      e.preventDefault();
      e.stopPropagation();
      if (!isPublished) {
        setDragging(true);
      }
    },
    [isPublished]
  );

  const handleDragOut = useCallback(e => {
    e.preventDefault();
    e.stopPropagation();
    setDragging(false);
  }, []);

  return (
    <div className="">
      <label
        className={`flex justify-center w-full h-16 px-4 transition bg-white border-2 
                    ${dragging && !isPublished ? 'border-blue-300' : 'border-gray-500'}
                    ${isPublished ? 'opacity-50 cursor-not-allowed' : 'cursor-pointer hover:border-gray-900'}
                    border-dashed border-[1px] rounded-md appearance-none focus:outline-none`}
        onDrop={handleDrop}
        onDragOver={handleDrag}
        onDragEnter={handleDragIn}
        onDragLeave={handleDragOut}
      >
        <span className="flex items-center space-x-2">
          <span
            className={`text-gray-600 italic text-smallish ${isPublished ? 'line-through' : ''}`}
          >
            {isPublished ? 'Image upload disabled' : 'Drag & drop file here or '}
            {!isPublished && <span className="underline">Browse</span>}
          </span>
        </span>

        <input
          type="file"
          disabled={isPublished}
          accept="image/*"
          name="file_upload"
          className="hidden"
          onChange={event => handleImageUpload(event)}
        />
      </label>
    </div>
  );
}

export default DragAndDropUpload;
