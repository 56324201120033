import React, { useEffect, useState } from 'react';
import { useAuth } from '../auth/AuthContext';
// import "./normalize.css"
// import "./webflow.css"
import { apiUrl, gradeApi } from '../api';
import Latex from '../components/Latex';
import './gradewiz-webflow.css';

function SDetails(props) {
  const { user } = useAuth();
  const [message, setMessage] = useState('');
  const [assignmentData, setAssignmentData] = useState({});
  const [submitted, setSubmitted] = useState(false);
  const [published, setPublished] = useState(false);
  const [file, setFile] = useState();
  const [examPaper, setExamPaper] = useState([]);
  const [demoResponse, setDemoResponse] = useState([]);
  const [loading, setLoading] = useState(false);
  const [tried, setTried] = useState(false);
  const [input, setInput] = useState('');
  const [canAccess, setCanAccess] = useState(false);

  const handleInputChange = event => {
    setInput(event.target.value);
  };

  const convertSpacesToHspace = text => {
    if (!text) {
      return '';
    }
    return text.replace(/ /g, '\\hspace{0.5em}');
  };

  const handleASubmit = event => {
    event.preventDefault(); // Prevent the form from being submitted to the server
    if (input === 'IthacaPilot' || input === 'CalPilot' || input === 'HumboldtPilot') {
      setCanAccess(true);
    } else {
      setCanAccess(false);
      setTried(true);
    }
  };

  const handleSubmit = async e => {
    e.preventDefault();

    const formData = new FormData();
    formData.append('file_data', file);
    formData.append('user_id', 1);

    try {
      setMessage('');
      setLoading(true);
      const response = await gradeApi.post({ path: `/api/demo/grade/`, body: formData });
      setSubmitted(true);
      setDemoResponse(response.data);
      console.log(response.data);
      setLoading(false);
    } catch (error) {
      console.error('Error submitting assignment:', error);
      setMessage('Error submitting assignment. Please try again.');
      setLoading(false);
    }
  };

  //     const uploadAssignments = async (e) => {
  //     e.preventDefault();
  //     setLoading(true);
  //
  //     if (!file) {
  //         setErrorMessage("Please select a file to upload.");
  //         setLoading(false);
  //         return;
  //     }
  //
  //     const formData = new FormData();
  //     formData.append('file_data', file);
  //     formData.append('user_id', user.user.id);
  //
  //     try {
  //         const response = await axios.post(
  //             `${url}/api/assignment/${assignment.id}/batch-upload/`,
  //             formData,
  //             {
  //                 headers: {
  //                     'Authorization': `Bearer ${user.session_token}`,
  //                     'Content-Type': 'multipart/form-data',
  //                 },
  //             }
  //         );
  //         if (response.status === 201) {
  //             setSubmitted(true);
  //             getSubmissions();
  //         }
  //     } catch (error) {
  //         console.error("Error uploading assignments:", error);
  //         setErrorMessage("Error uploading assignments. Please try again.");
  //     } finally {
  //         setLoading(false);
  //     }
  // }
  const getAssignmentDetails = async () => {
    try {
      const response = await gradeApi.get({
        path: `/api/demo/assignment`,
        config: {
          headers: {
            Authorization: `Bearer ${user.session_token}`
          }
        }
      });
      const data = response.data;
      setAssignmentData(data);
      setExamPaper(data.questions);
      setPublished(true);
    } catch (error) {
      console.error('Error fetching assignment details:', error);
      setMessage('Error fetching assignment details. Please try again.');
    }
  };

  const hasRubricItem = (subquestionId, rubricId) => {
    var num = parseInt(demoResponse[subquestionId].rub);
    console.log('qn:');
    console.log(subquestionId);
    console.log('aifb:');
    console.log(num);
    console.log('rid');
    console.log(rubricId);
    return num === rubricId;
  };

  const getFeedback = subquestionId => {
    var str = demoResponse[subquestionId].out;
    console.log(demoResponse[subquestionId]);
    console.log(demoResponse[subquestionId].out);
    return str;
  };

  // getAssignmentDetails();
  // getAssignmentSubmission();

  useEffect(() => {
    getAssignmentDetails();
    // getAssignmentSubmission();
  }, []);

  return (
    <div className={`flex flex-col ${submitted ? 'h-full' : ''}`}>
      <div className="fixed w-full">
        <div className="page-wrapper">
          <div
            data-animation="default"
            className="navbar-component w-nav"
            data-easing2="ease"
            fs-scrolldisable-element="smart-nav"
            data-easing="ease"
            data-collapse="medium"
            data-w-id="2bbb12c9-9300-15f1-6549-3c38dcff06fb"
            role="banner"
            data-duration="400"
          >
            <div className="navbar-container">
              <a
                href="marketing/index.html"
                aria-current="page"
                className="navbar-logo-link w-nav-brand w--current"
              >
                <img
                  src="./gradewiz_logo.jpg"
                  loading="lazy"
                  width="51"
                  alt="GradeWiz Icon"
                  className="logo-image"
                />
              </a>
              <nav role="navigation" className="navbar-menu w-nav-menu">
                <div className="navbar-menu-left">
                  <a
                    href="marketing/index.html"
                    aria-current="page"
                    className="navbar-link w-nav-link w--current"
                  >
                    About
                  </a>
                  <a href="marketing/pilot.html" className="navbar-link w-nav-link">
                    Pilot Program
                  </a>
                  <a href="marketing/index.html#Features" className="navbar-link w-nav-link">
                    Features
                  </a>
                  <a href="marketing/index.html#Team" className="navbar-link w-nav-link">
                    Team
                  </a>
                  <a href="/demo" className="navbar-link w-nav-link">
                    Demo
                  </a>
                </div>
              </nav>
              <div className="navbar-menu-button w-nav-button">
                <div className="menu-icon">
                  <div className="menu-icon-line-top"></div>
                  <div className="menu-icon-line-middle">
                    <div className="menu-icon-line-middle-inner"></div>
                  </div>
                  <div className="menu-icon-line-bottom"></div>
                </div>
              </div>
              <div className="navbar-menu-desktop">
                <a href="marketing/index.html#Contact-Section" className="navbar-link w-nav-link">
                  Contact
                </a>
                <a href="/login" className="navbar-link w-nav-link">
                  Login
                </a>
                <div className="w-layout-vflex flex-block-2">
                  <a
                    href="https://calendly.com/gradewiz/pilot?month=2024-02"
                    className="button is-small w-button"
                  >
                    Schedule a Meeting
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {canAccess ? (
        <div className="content-container mt-[90px] p-6 bg-gray-100 shadow-lg rounded-lg max-w-5xl mx-auto">
          {!submitted ? (
            <>
              <div className="instructions mb-4">
                <h2 className="text-2xl font-semibold mb-2 mt-10">
                  Step 1: Download the Assignment
                </h2>
                <p>Click below to view and download the assignment PDF.</p>
                {assignmentData.url ? (
                  <iframe
                    src={`${apiUrl}/api/gen/${assignmentData.url}`}
                    title="Assignment PDF Viewer"
                    className="w-full h-full border rounded mb-2"
                  />
                ) : (
                  ''
                )}
                <button
                  className="bg-black hover:bg-gray-700 text-white font-bold py-2 px-4 rounded"
                  onClick={() => window.open(`${apiUrl}/api/gen/${assignmentData.url}`, '_blank')}
                >
                  Download PDF
                </button>
              </div>

              <div className="upload-section">
                <h2 className="text-2xl font-semibold mb-2 mt-10">
                  Step 2: Upload Your Completed Assignment
                </h2>
                <p>
                  After completing the assignment, please upload your work using the button below.
                </p>
                <input
                  type="file"
                  className="file-input bg-white text-gray-800 py-2 px-4 border border-gray-400 rounded cursor-pointer mb-2"
                  onChange={e => setFile(e.target.files[0])}
                />
                <button
                  type="submit"
                  disabled={!file}
                  className="bg-black hover:bg-gray-700 text-white font-bold py-2 px-4 ml-4 rounded"
                  onClick={handleSubmit}
                >
                  {submitted ? 'Resubmit' : 'Submit'}
                </button>
              </div>
            </>
          ) : (
            <div className="flex flex-col items-center justify-start pt-8 w-full">
              <div className="flex flex-row w-full mt-[72px]">
                <div className="w-1/2 flex flex-col">
                  <iframe
                    src={`${URL.createObjectURL(file)}`}
                    title="PDF Viewer"
                    style={{ height: 'calc(100vh - 144px)' }} // Adjusting height to take full available height after offset
                    className="w-full flex-grow"
                  />
                </div>
                <div className="w-1/2 overflow-auto p-4" style={{ height: 'calc(100vh - 144px)' }}>
                  {examPaper.map((question, questionIndex) => (
                    <div key={questionIndex} className="bg-gray-100 rounded-lg shadow-md mb-4 p-4">
                      <div className="font-bold text-lg mb-2">
                        <Latex>{`Question \\hspace{0.5em} ${questionIndex + 1}: ${question.description}`}</Latex>
                      </div>
                      {question.subQuestions.map((subQuestion, subQuestionIndex) => (
                        <div key={subQuestionIndex} className="mb-4">
                          <Latex>{`${String.fromCharCode(97 + subQuestionIndex)}) \\hspace{0.5em} ${subQuestion.question}`}</Latex>

                          <div className="grid grid-cols-1 md:grid-cols-2 gap-4 mt-2">
                            {subQuestion.rubricItems.map((rubricItem, rubricIndex) => (
                              <button
                                key={rubricIndex}
                                className={`rounded border-2 px-4 py-2 transition-colors duration-150 
                                                                    ${hasRubricItem(subQuestion.id, rubricItem.id) ? 'bg-green-400 hover:bg-green-200' : 'bg-red-400 hover:bg-red-200'}`}
                              >
                                {rubricItem.descriptor}: {rubricItem.value}
                              </button>
                            ))}
                          </div>
                          <div>
                            <Latex>{convertSpacesToHspace(getFeedback(subQuestion.id))}</Latex>
                          </div>
                        </div>
                      ))}
                    </div>
                  ))}
                </div>
              </div>
            </div>
          )}
          {loading && <p className="text-center text-blue-500">Uploading...</p>}
          {message && (
            <p className={`text-center ${submitted ? 'text-green-500' : 'text-red-500'}`}>
              {message}
            </p>
          )}
        </div>
      ) : (
        <div className="flex flex-col items-center justify-start pt-20 w-full min-h-screen bg-gray-100">
          <div className="w-full max-w-md p-8 bg-white rounded shadow-lg">
            <h1 className="text-2xl font-bold text-center mb-6">Access Control Page</h1>
            <form className="space-y-4" onSubmit={handleASubmit}>
              <input
                type="text"
                value={input}
                placeholder="Enter your access code"
                className="w-full p-2 text-lg border-2 border-gray-300 rounded focus:outline-none focus:border-blue-500"
                onChange={handleInputChange}
              />
              <button
                type="submit"
                className="w-full bg-black hover:bg-gray-700 text-white font-bold py-2 px-4 rounded focus:outline-none"
              >
                Submit
              </button>
            </form>
            <p className={`${canAccess ? 'text-green-500' : 'text-red-500'} mt-4 font-semibold`}>
              {tried ? (canAccess ? 'Access Granted' : 'Access Denied') : ''}
            </p>
          </div>
        </div>
      )}
    </div>
  );
}

export default SDetails;
