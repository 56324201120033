import React, {useEffect, useState} from 'react';
import {gradeApi} from '../../api.js';
import {useAuth} from '../../auth/AuthContext';
import {ViewSelectIcon} from '../ViewSelect';

function ADashboard() {
    const [teachers, setTeachers] = useState([]);
    const [selectedTeacher, setSelectedTeacher] = useState(null);
    const [teacherStats, setTeacherStats] = useState(null);
    const [selectedClass, setSelectedClass] = useState(null);
    const [selectedAssignment, setSelectedAssignment] = useState(null);
    const [errorMessage, setErrorMessage] = useState('');
    const {user} = useAuth();

    useEffect(() => {
        getTeachers();
    }, []);

    const getTeachers = async () => {
        try {
            const response = await gradeApi.get({
                path: '/api/teachers/',
                config: {
                    headers: {
                        Authorization: `Bearer ${user.session_token}`
                    }
                }
            });
            if (response.data && Array.isArray(response.data)) {
                setTeachers(response.data);
            } else {
                setErrorMessage('Unexpected response format for teachers.');
            }
        } catch (error) {
            setErrorMessage('Failed to fetch teachers. Please try again.');
        }
    };

    const getTeacherStats = async (teacherId) => {
        try {
            const response = await gradeApi.get({
                path: `/api/teachers/${teacherId}/statistic/`,
                config: {
                    headers: {
                        Authorization: `Bearer ${user.session_token}`
                    }
                }
            });
            setTeacherStats(response.data);
        } catch (error) {
            setErrorMessage('Failed to fetch teacher statistics. Please try again.');
        }
    };

    const handleTeacherSelect = (teacherId) => {
        setSelectedTeacher(teacherId);
        getTeacherStats(teacherId);
        setSelectedClass(null);
        setSelectedAssignment(null);
    };

    const ClassCard = ({classData}) => {
        return (
            <div
                className="flex flex-col justify-between p-4 h-40 bg-white sexy-border relative transition-all duration-300 desktop:hover:-translate-y-0.5 desktop:hover:shadow overflow-hidden cursor-pointer"
                onClick={() => setSelectedClass(classData)}
            >
                <div className="text-base font-medium text-black line-clamp-2">
                    {classData.name}
                </div>
                <div className="flex flex-col space-y-1">
                    <div className="text-gray-900 text-smallish truncate">Students: {classData.studentsCount}</div>
                    <div
                        className="text-gray-900 text-smallish truncate">Assignments: {classData.assignmentsTotalCount}</div>
                </div>
                <div className="flex justify-between items-center space-x-4">
                    <div className="flex items-center space-x-1 truncate">
                        <div className="text-black font-semibold text-sm">{classData.assignmentsPublishedCount}</div>
                        <div className="text-gray-900 text-xs">published</div>
                    </div>
                </div>
            </div>
        );
    };

    const ClassDetailsPopup = ({classData, onClose}) => {
        return (
            <div className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center z-50">
                <div className="bg-white p-6 rounded-lg max-w-2xl w-full max-h-[90vh] overflow-y-auto">
                    <h2 className="text-2xl font-bold mb-4">{classData.name}</h2>
                    <p><strong>Students:</strong> {classData.studentsCount}</p>
                    <p><strong>Total Assignments:</strong> {classData.assignmentsTotalCount}</p>
                    <p><strong>Published Assignments:</strong> {classData.assignmentsPublishedCount}</p>
                    <p><strong>Grades Published:</strong> {classData.assignmentsGradesPublishedCount}</p>
                    <h3 className="text-xl font-semibold mt-4 mb-2">Assignments:</h3>
                    <div className="space-y-2">
                        {classData.assignments.map((assignment, index) => (
                            <div
                                key={index}
                                className="p-2 bg-gray-100 rounded cursor-pointer hover:bg-gray-200"
                                onClick={() => setSelectedAssignment(assignment)}
                            >
                                {assignment.name}
                            </div>
                        ))}
                    </div>
                    <button
                        className="mt-4 bg-blue-500 text-white px-4 py-2 rounded hover:bg-blue-600"
                        onClick={onClose}
                    >
                        Close
                    </button>
                </div>
            </div>
        );
    };

    const AssignmentDetailsPopup = ({assignment, onClose}) => {
        return (
            <div className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center z-50">
                <div className="bg-white p-6 rounded-lg max-w-2xl w-full max-h-[90vh] overflow-y-auto">
                    <h2 className="text-2xl font-bold mb-4">{assignment.name}</h2>
                    <p><strong>Due Date:</strong> {assignment.dueDatetime}</p>
                    <p><strong>Timezone:</strong> {assignment.timezone}</p>
                    <p><strong>Is Essay:</strong> {assignment.isEssay ? 'Yes' : 'No'}</p>
                    <p><strong>Is Exam:</strong> {assignment.isExam ? 'Yes' : 'No'}</p>
                    <p><strong>Is Group Task:</strong> {assignment.isGroupTask ? 'Yes' : 'No'}</p>
                    <p><strong>Question Count:</strong> {assignment.questionCount}</p>
                    <p><strong>Sub-Question Count:</strong> {assignment.subQuestionCount}</p>
                    <p><strong>Total Submissions:</strong> {assignment.totalSubmissionsCount}</p>
                    <p><strong>Graded Submissions:</strong> {assignment.gradedSubmissionsCount}</p>
                    <p><strong>Reviewed Submissions:</strong> {assignment.reviewedSubmissionsCount}</p>
                    <p><strong>AI Regrade Count:</strong> {assignment.aiRegradeCount}</p>
                    <p><strong>Manual Regrade Count:</strong> {assignment.manualRegradeCount}</p>
                    <h3 className="text-xl font-semibold mt-4 mb-2">Submissions:</h3>
                    <ul className="list-disc pl-5">
                        {assignment.submissions.map((submission, index) => (
                            <li key={index}>
                                Grade: {submission.grade}, Late: {submission.late ? 'Yes' : 'No'},
                                Date: {submission.submittedDate}
                            </li>
                        ))}
                    </ul>
                    <button
                        className="mt-4 bg-blue-500 text-white px-4 py-2 rounded hover:bg-blue-600"
                        onClick={onClose}
                    >
                        Close
                    </button>
                </div>
            </div>
        );
    };

    const handleRegenerateAll = async () => {
        try {
            await gradeApi.post({
                path: 'api/regenerate/',
                config: {
                    headers: {
                        Authorization: `Bearer ${user.session_token}`
                    }
                }
            });
            alert('Regeneration process initiated successfully.');
        } catch (error) {
            setErrorMessage('Failed to initiate regeneration process. Please try again.');
        }
    };

    return (
        <div id="AdminDashboard" className="flex flex-row p-8 justify-start">
            <div id="Main-Content-container" className="flex flex-col space-y-4 w-full">
                <div id="Overview" className="flex flex-col space-y-2 w-full">
                    <div className="flex flex-row justify-between items-center">
                        <div className="flex flex-row items-center">
                            <div>
                                <ViewSelectIcon cardView={true} toggleCardView={() => {
                                }}/>
                            </div>
                            <h1 className="text-black text-xl font-medium ml-4">Teacher Statistics Dashboard</h1>
                        </div>
                        <button
                            className="sexy-button bg-red-500 text-white transition-all duration-300 desktop:hover:-translate-y-0.5 desktop:hover:shadow h-9 px-4 py-2"
                            onClick={handleRegenerateAll}
                        >
                            DO NOT CLICK - REGENERATE ALL PDF
                        </button>
                        <div className="flex space-x-4">
                            <select
                                className="sexy-button transition-all duration-300 desktop:hover:-translate-y-0.5 desktop:hover:shadow h-9 px-4 py-2"
                                onChange={(e) => handleTeacherSelect(e.target.value)}
                            >
                                <option value="">Select a teacher</option>
                                {teachers.map((teacher) => (
                                    <option key={teacher.id} value={teacher.id}>
                                        {`${teacher.firstName} ${teacher.lastName} (${teacher.email})`}
                                    </option>
                                ))}
                            </select>
                        </div>
                    </div>
                </div>

                {selectedTeacher && teacherStats && (
                    <div className="container mx-auto mt-6 relative pb-16">
                        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4 min-w-20">
                            {teacherStats.map((classData, classIndex) => (
                                <ClassCard key={classIndex} classData={classData}/>
                            ))}
                        </div>
                    </div>
                )}

                {selectedClass && (
                    <ClassDetailsPopup
                        classData={selectedClass}
                        onClose={() => setSelectedClass(null)}
                    />
                )}

                {selectedAssignment && (
                    <AssignmentDetailsPopup
                        assignment={selectedAssignment}
                        onClose={() => setSelectedAssignment(null)}
                    />
                )}
            </div>

            {errorMessage && (
                <div className="mt-4 p-4 bg-red-100 text-red-700 rounded">
                    {errorMessage}
                </div>
            )}
        </div>
    );
}

export default ADashboard;