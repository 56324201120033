import React, {useEffect, useRef, useState} from 'react';
import {Link, useNavigate, useParams} from 'react-router-dom';
import {apiUrl, gradeApi} from '../../api.js';
import {useAuth} from '../../auth/AuthContext';
import ConfirmationModal from '../ConfirmationModal';
import AIRegrade from './AIRegrade';
import {PDFView} from "./PDFRendered";
import StudentSearchDropdown from './StudentSearchDropdown';
import {Check} from 'lucide-react';


const TQuestionDetails = props => {
    const {user} = useAuth();
    const {assignment_id, subquestion_id, submission_id} = useParams();
    const [submissionData, setSubmissionData] = useState({});
    const [submitted, setSubmitted] = useState(false);
    const [examPaper, setExamPaper] = useState([]);
    const [feedbackItems, setFeedbackItems] = useState({});
    const [editableFeedback, setEditableFeedback] = useState({});
    const [message, setMessage] = useState('');
    const feedbackRefs = useRef({});
    const [assignment, setAssignment] = useState(null);
    const [studentName, setStudentName] = useState('');
    const navigate = useNavigate();
    const [isRegradeOpen, setIsRegradeOpen] = useState(false);
    const [isConfirmationOpen, setIsConfirmationOpen] = useState(false);
    const [regradeData, setRegradeData] = useState(null);
    const [isLoading, setIsLoading] = useState(false);
    const [activeSubQuestionId, setActiveSubQuestionId] = useState(null);
    const [refreshKey, setRefreshKey] = useState(0);
    const [isSeen, setIsSeen] = useState(false);
    const [students, setStudents] = useState([]);
    const [assignedStudentId, setAssignedStudentId] = useState(null);
    const [error, setError] = useState(null);
    const [isStudentAssignmentConfirmationOpen, setIsStudentAssignmentConfirmationOpen] =
        useState(false);
    const [newStudentAssignment, setNewStudentAssignment] = useState(null);
    const [regradeInProgress, setRegradeInProgress] = useState({});
    const pollingInterval = useRef(null);
    const [gradePending, setGradePending] = useState(false);
    const [groupStudents, setGroupStudents] = useState([]);
    const [questionMapping, setQuestionMapping] = useState(null);
    const [isMounting, setIsMounting] = useState(false);
    const [viewedCount, setViewedCount] = useState(0);
    const [pageNumber, setPageNumber] = useState(null);
    const [showAllReviewedPopup, setShowAllReviewedPopup] = useState(false);
    const [showNextSubquestionPopup, setShowNextSubquestionPopup] = useState(false);
    const [nextSubQuestionId, setNextSubQuestionId] = useState(null);

    const getCurrentQuestion = () => {
        for (const question of examPaper) {
            for (const subQuestion of question.subQuestions) {
                if (subQuestion.id === Number(subquestion_id)) {
                    return question;
                }
            }
        }
        return null;
    };


    // Fetching assignment submission details
    const getAssignmentSubmission = async () => {
        setIsMounting(true);
        try {
            const response = await gradeApi.get({
                path: `/api/assignment/${assignment_id}/submission/${submission_id}/`,
                config: {
                    headers: {
                        Authorization: `Bearer ${user.session_token}`
                    }
                }
            });
            const data = response.data;
            setSubmitted(data.submitted);
            setSubmissionData(data.submitted ? data : {});
            setFeedbackItems(data.submitted ? data.feedback : {});
            setIsSeen(data.feedback.is_viewed);
            setAssignedStudentId(data.user.id);
            setStudentName(`${data.user.first_name} ${data.user.last_name}`);
        } catch (error) {
            setMessage(error.response.data.error);
        } finally {
            setTimeout(() => setIsMounting(false), 0);
        }
    };

    const getQuestionMapping = async () => {
        try {
            const response = await gradeApi.get({
                path: `/api/assignment/${assignment_id}/question-mapping/`,
                config: {
                    headers: {
                        Authorization: `Bearer ${user.session_token}`
                    }
                }
            });
            console.log(response);
            const data = JSON.parse(response.data);
            console.log(JSON.parse(data));
            setQuestionMapping(JSON.parse(data));
        } catch (error) {
            setMessage(error.response.data.error);
        }
    };

    const getStudents = async () => {
        try {
            const response = await gradeApi.get({
                path: `/api/courses/${assignment.course_id}/students/`,
                config: {
                    headers: {
                        Authorization: `Bearer ${user.session_token}`
                    }
                }
            });
            const users = response.data.users;
            const userInfo = users.map(user => ({
                name: user.first_name + ' ' + user.last_name,
                id: user.id,
                is_null_user: user.is_null_user,
                email: user.email
            }));
            setStudents(userInfo);
            setAssignedStudentId(submissionData.user.id);
        } catch (error) {
            console.error('Error fetching students:', error);
            setError('Failed to fetch students. Please try again.');
        }
    };

    const handleStudentAssignment = event => {
        const newStudentId = event.target.value;
        const selectedStudent = students.find(s => s.id.toString() === newStudentId);

        if (selectedStudent) {
            setNewStudentAssignment(selectedStudent);
        } else {
            // If the student is not in the list (which shouldn't happen normally),
            // we'll create a placeholder with just the ID
            setNewStudentAssignment({id: newStudentId, name: `Unknown Student (ID: ${newStudentId})`});
        }

        setIsStudentAssignmentConfirmationOpen(true);
    };

    const handleConfirmStudentAssignment = async () => {
        try {
            await gradeApi.post({
                path: `/api/assignment/${assignment_id}/submissions/${submission_id}/assign-student/`,
                body: {new_student_id: newStudentAssignment.id},
                config: {
                    headers: {
                        Authorization: `Bearer ${user.session_token}`
                    }
                }
            });
            setAssignedStudentId(newStudentAssignment.id);
            setStudentName(newStudentAssignment.name);

            // Refresh the submission data
            getAssignmentSubmission();
            setIsStudentAssignmentConfirmationOpen(false);
        } catch (error) {
            console.error('Failed to assign student:', error);
            setError('Failed to assign student. Please try again.');
        }
    };

    useEffect(() => {
        if (assignment?.is_exam && assignment.course_id && submissionData.user) {
            getStudents();
        }
    }, [assignment, submissionData]);

    const fetchGroupStudents = async () => {
        try {
            if (!assignedStudentId) {
                return;
            }
            const response = await gradeApi.get({
                path: `/api/assignment/get_students/${assignment.id}/student/${assignedStudentId}`, // Make sure to use assignment.id, not aid
                config: {
                    headers: {
                        Authorization: `Bearer ${user.session_token}`
                    }
                }
            });
            setGroupStudents(response.data); // Assuming response.data is an array
        } catch (error) {
            console.error('Error fetching group students:', error);
            // Consider setting an error state here instead of using setError
        }
    };

    useEffect(() => {
        fetchGroupStudents();
        getPageMapping();
    }, [assignment]);

    // replace Mark Reviewed with Mark viewed
    const handleMarkSeen = async (forceTrue = false) => {
        try {
            // If forceTrue is true, we want to mark it as reviewed regardless
            // Otherwise, toggle the current status
            console.log(isSeen)
            console.log(forceTrue)
            console.log(!isSeen)
            const newReviewedStatus = forceTrue === true ? true : !isSeen;
            console.log(newReviewedStatus)
            const response = await gradeApi.post({
                path: `/api/assignment/${assignment_id}/submissions/${submission_id}/feedback/${subquestion_id}/mark-viewed/`,
                config: {
                    headers: {
                        Authorization: `Bearer ${user.session_token}`
                    }
                }
            });
            setIsSeen(newReviewedStatus);
            setViewedCount(response.data.viewed_count)
        } catch (error) {
            console.error('Failed to update review status:', error);
            setMessage('Failed to update review status.');
        }
    };

    // And modify the useEffect to use forceTrue
    useEffect(() => {
        console.log('useEffect', assignment_id, submission_id);
        if (!isSeen && !isMounting) {
            handleMarkSeen(true);
        }
    }, [assignment_id, submission_id, assignedStudentId, studentName, isMounting, submissionData]);

    const startPolling = () => {
        if (!pollingInterval.current) {
            pollingInterval.current = setInterval(getQuestions, 5000);
        }
    };

    const stopPolling = () => {
        if (pollingInterval.current) {
            clearInterval(pollingInterval.current);
            pollingInterval.current = null;
            if (!isRegradeOpen) {
                window.location.reload();
            }
        }
    };

    useEffect(() => {
        getQuestions();
        getAssignmentSubmission();
        getAssignment();
        return () => stopPolling();
    }, [assignment_id, submission_id, refreshKey]);

    const getQuestions = async () => {
        try {
            const response = await gradeApi.get({
                path: `/api/assignment/${assignment_id}/questions/`,
                config: {
                    headers: {
                        Authorization: `Bearer ${user.session_token}`
                    }
                }
            });
            const questionsFromDB = response.data;
            // console.log(questionsFromDB);
            setExamPaper(questionsFromDB.questions);

            // Check for regrade_in_progress
            const newRegradeStatus = {};
            questionsFromDB.questions.forEach(question => {
                question.subQuestions.forEach(subQuestion => {
                    if (subQuestion.regrade_in_progress) {
                        newRegradeStatus[subQuestion.id] = true;
                    }
                });
            });
            setRegradeInProgress(newRegradeStatus);

            // Start or stop polling based on regrade status
            if (Object.values(newRegradeStatus).some(status => status)) {
                startPolling();
            } else {
                stopPolling();
            }
        } catch (error) {
            console.log(error);
        }
    };

    // Fetching assignment details
    const getAssignment = async () => {
        try {
            const response = await gradeApi.get({
                path: `/api/assignment/${assignment_id}/only/`,
                config: {
                    headers: {
                        Authorization: `Bearer ${user.session_token}`
                    }
                }
            });
            setAssignment(response.data);
        } catch (error) {
            setMessage('Failed to fetch assignment details');
        }
    };

    // Update student name when submission data changes
    useEffect(() => {
        if (submissionData.user) {
            setStudentName(`${submissionData.user.first_name} ${submissionData.user.last_name}`);
        }
    }, [submissionData, refreshKey]);

    // Re-fetch data when assignment_id or submission_id changes
    useEffect(() => {
        getAssignmentSubmission();
        getQuestions();
        getAssignment();
    }, [assignment_id, submission_id, refreshKey]);


    useEffect(() => {
        const currentViewedCount = getSubQuestionViewedCount();
        setViewedCount(currentViewedCount);
    }, [examPaper, subquestion_id]);


    const clickRight = () => {
        if (!props.submissionIds || props.submissionIds.length === 0) {
            console.error('No submissions available for navigation');
            return;
        }

        const currentIndex = props.submissionIds.indexOf(Number(submission_id));
        let prevIndex =
            currentIndex === -1
                ? props.submissionIds.length - 1
                : (currentIndex - 1 + props.submissionIds.length) % props.submissionIds.length;

        navigateToSubmission(prevIndex, -1);
    };

    const clickLeft = () => {
        if (!props.submissionIds || props.submissionIds.length === 0) {
            console.error('No submissions available for navigation');
            return;
        }

        const currentIndex = props.submissionIds.indexOf(Number(submission_id));
        let nextIndex = currentIndex === -1 ? 0 : (currentIndex + 1) % props.submissionIds.length;

        navigateToSubmission(nextIndex, 1);
    };

    const clearEditableFeedback = () => {
        setEditableFeedback({});
    };

    const navigateToSubmission = (startIndex, direction) => {
        if (props.submissionIds.length === 1) {
            return;
        }
        clearEditableFeedback(); // Clear editable feedback before navigation

        let currentIndex = startIndex;
        const maxAttempts = props.submissionIds.length;

        for (let attempt = 0; attempt < maxAttempts; attempt++) {

            const nextSubmissionId = props.submissionIds[currentIndex];
            console.log(props.submissionIds)
            if (nextSubmissionId != null) {
                navigate(
                    `/tclasses/${props.class_id}/tassignments/${assignment_id}/tsubmissions/${nextSubmissionId}/tquestiondetails/${subquestion_id}`
                );
                return;
            }
            currentIndex =
                (currentIndex + direction + props.submissionIds.length) % props.submissionIds.length;
        }

        console.error('No valid submissions found to navigate to');
    };
    const hasRubricItem = (subquestionId, rubricId) => {
        if (!feedbackItems) {
            return false;
        }
        if (!subquestionId) {
            return false;
        }
        if (subquestionId in feedbackItems) {
            let rubricItems = feedbackItems[subquestionId]?.rubric_items;
            for (let i = 0; i < rubricItems.length; i++) {
                if (rubricItems[i].id === rubricId) {
                    return true;
                }
            }
        }
        return false;
    };

    const getFeedback = subquestionId => {
        if (!feedbackItems) {
            return '';
        }
        if (subquestionId in feedbackItems) {
            return feedbackItems[subquestionId].feedback;
        }
        return '';
    };

    const getRR = subquestionId => {
        if (!feedbackItems) {
            return '';
        }
        if (subquestionId in feedbackItems) {
            return feedbackItems[subquestionId].regrade_request;
        }
        return '';
    };

    const getRRText = subquestionId => {
        if (!feedbackItems) {
            return '';
        }
        if (subquestionId in feedbackItems) {
            return feedbackItems[subquestionId].regrade_text;
        }
        return '';
    };

    const handleRubricItemChange = (subquestionId, rubricItem) => () => {
        gradeApi
            .post({
                path: `/api/assignment/${assignment_id}/submissions/${submission_id}/grade/`,
                body: {
                    subquestionId: subquestionId,
                    rubric: rubricItem
                },
                config: {
                    headers: {
                        Authorization: `Bearer ${user.session_token}`
                    }
                }
            })
            .catch(error => {
                // console.log(error);
                setGradePending(false);
            })
            .then(response => {
                setFeedbackItems(response.data.feedback);
                setSubmissionData({...submissionData, grade: response.data.grade});
                setGradePending(false);
            });
    };

    const handleFeedbackEdit = subquestionId => {
        setEditableFeedback(prev => ({
            ...prev,
            [subquestionId]: getFeedback(subquestionId) || ''
        }));
    };

    const handleFeedbackChange = (subquestionId, value) => {
        setEditableFeedback(prev => ({
            ...prev,
            [subquestionId]: value
        }));
    };

    const handleFeedbackSave = subquestionId => {
        gradeApi
            .post({
                path: `/api/assignment/${assignment_id}/submissions/${submission_id}/feedback/`,
                body: {
                    subquestionId: subquestionId,
                    feedback: editableFeedback[subquestionId]
                },
                config: {
                    headers: {
                        Authorization: `Bearer ${user.session_token}`
                    }
                }
            })
            .catch(error => {
                console.log('Error updating feedback:', error);
            })
            .then(response => {
                if (response) {
                    setFeedbackItems(prevFeedbackItems => ({
                        ...prevFeedbackItems,
                        [subquestionId]: {
                            ...prevFeedbackItems[subquestionId],
                            feedback: editableFeedback[subquestionId]
                        }
                    }));

                    setEditableFeedback(prev => {
                        const {[subquestionId]: _, ...rest} = prev;
                        return rest;
                    });

                    // console.log('Feedback updated successfully:', response.data);
                }
            });
    };

    const isEditing = subquestionId => editableFeedback.hasOwnProperty(subquestionId);

    const handleRegradeReq = subquestionId => {
        if (subquestionId in feedbackItems) {
            handleRegradeReqDel(subquestionId);
        }
    };

    const handleRegradeReqDel = subquestionId => {
        const submissionID = submissionData.id;

        gradeApi
            .post({
                path: `/api/assignment/${assignment_id}/submissions/${submissionID}/regrade-request/`,
                body: {
                    subquestionId: subquestionId,
                    regradeRequest: false
                },
                config: {
                    headers: {
                        Authorization: `Bearer ${user.session_token}`
                    }
                }
            })
            .catch(error => {
                console.log('Error updating regrade request:', error);
            })
            .then(response => {
                if (response) {
                    setFeedbackItems(prevFeedbackItems => ({
                        ...prevFeedbackItems,
                        [subquestionId]: {
                            ...prevFeedbackItems[subquestionId],
                            regrade_request: false
                        }
                    }));

                    // console.log('Regrade request updated successfully:', response.data);
                }
            });
    };

    const renderFeedback = feedback => {
        return <div className="mb-2">{feedback}</div>;
    };

    const findNextSubquestion = () => {
        let currentSubQuestionFound = false;
        let foundNextSubQuestionId = null;

        for (const question of examPaper) {
            for (const subQuestion of question.subQuestions) {
                if (currentSubQuestionFound) {
                    foundNextSubQuestionId = subQuestion.id;
                    break;
                }
                if (subQuestion.id === Number(subquestion_id)) {
                    currentSubQuestionFound = true;
                }
            }
            if (foundNextSubQuestionId) {
                break;
            }
        }

        setNextSubQuestionId(foundNextSubQuestionId);
        setShowNextSubquestionPopup(true);

        setTimeout(() => {
            setShowNextSubquestionPopup(false);
            if (foundNextSubQuestionId) {
                navigate(
                    `/tclasses/${props.class_id}/tassignments/${assignment_id}/tsubmissions/${submission_id}/tquestiondetails/${foundNextSubQuestionId}`
                );
                window.location.reload();
            } else {
                navigate(
                    `/tclasses/${props.class_id}/tassignments/${assignment_id}/tsubmissions`
                );
                window.location.reload();
            }
        }, 1500);
    };

    const findNextUnseen = async () => {
        try {
            const response = await gradeApi.get({
                path: `/api/assignment/${assignment_id}/submissions/next-unseen/${subquestion_id}/?current_submission_id=${submission_id}`,
                config: {
                    headers: {
                        Authorization: `Bearer ${user.session_token}`
                    }
                }
            });

            if (response.data.next_submission_id === -1) {
                let currentSubQuestionFound = false;
                let nextSubQuestionId = null;
                for (const question of examPaper) {
                    for (const subQuestion of question.subQuestions) {
                        if (currentSubQuestionFound) {
                            nextSubQuestionId = subQuestion.id;
                            break;
                        }
                        if (subQuestion.id === Number(subquestion_id)) {
                            currentSubQuestionFound = true;
                        }
                    }
                    if (nextSubQuestionId) {
                        break;
                    }
                }
                if (nextSubQuestionId) {
                    setShowAllReviewedPopup(true);
                    // If there's a next subquestion, navigate to it
                    setTimeout(() => {
                        setShowAllReviewedPopup(false);
                        navigate(
                            `/tclasses/${props.class_id}/tassignments/${assignment_id}/tsubmissions/${submission_id}/tquestiondetails/${nextSubQuestionId}`
                        );
                        window.location.reload();
                    }, 3000);
                } else {
                    // Show popup
                    setShowAllReviewedPopup(true);
                    // Wait 3 seconds then navigate
                    setTimeout(() => {
                        setShowAllReviewedPopup(false);
                        navigate(
                            `/tclasses/${props.class_id}/tassignments/${assignment_id}/tsubmissions`
                        );
                    }, 1500);
                }
            } else {
                // Navigate to next unseen submission
                navigate(
                    `/tclasses/${props.class_id}/tassignments/${assignment_id}/tsubmissions/${response.data.next_submission_id}/tquestiondetails/${subquestion_id}`
                );
            }
        } catch (error) {
            console.error('Error finding next unseen submission:', error);
        }
    };

    const [isEditingEssay, setIsEditingEssay] = useState(false);
    const [editableEssayFeedback, setEditableEssayFeedback] = useState('');
    const feedbackRef = useRef(null);

    const handleEssayFeedbackChange = value => {
        setEditableEssayFeedback(value);
    };

    const handleEssayFeedbackEdit = () => {
        setEditableEssayFeedback(submissionData?.file?.filename || '');
        setIsEditingEssay(true);
    };

    const handleEssayFeedbackSave = () => {
        gradeApi
            .post({
                path: `/api/assignment/${assignment_id}/update-feedback/${submission_id}`,
                body: {
                    feedback: editableEssayFeedback
                },
                config: {
                    headers: {
                        Authorization: `Bearer ${user.session_token}`
                    }
                }
            })
            .catch(error => {
                console.log('Error updating feedback:', error);
            })
            .then(response => {
                if (response) {
                    submissionData.file.filename = editableEssayFeedback
                    console.log(submissionData)

                    setIsEditingEssay(false);

                    // Optionally refetch the submission data if necessary
                    getAssignmentSubmission();

                    // console.log('Feedback updated successfully:', response.data);
                }
            });
    };

    const handleRegradeClick = subQuestionId => {
        setActiveSubQuestionId(subQuestionId);
        setIsRegradeOpen(true);
    };

    const handleRegradeClose = () => {
        setIsRegradeOpen(false);
        setActiveSubQuestionId(null);
        window.location.reload();
    };

    const handleRegradeSubmit = (newRubric, regradeInstructions, regradeFeedbackInstructions, solution) => {
        setRegradeData({newRubric, regradeInstructions, regradeFeedbackInstructions, solution});
        setIsConfirmationOpen(true);
    };

    const handleConfirmRegrade = async () => {
        setIsConfirmationOpen(false);
        setIsLoading(true);
        try {
            const response = await gradeApi.post({
                path: `/api/assignment/${assignment_id}/ai-regrade/`,
                body: {
                    subQuestionId: activeSubQuestionId,
                    newRubric: regradeData.newRubric,
                    regradeInstructions: regradeData.regradeInstructions,
                    regradeFeedbackInstructions: regradeData.regradeFeedbackInstructions,
                    solution: regradeData.solution
                },
                config: {
                    headers: {
                        Authorization: `Bearer ${user.session_token}`
                    }
                }
            });
            // console.log('AI-Regrade initiated:', response.data);
            getQuestions(); // Fetch the updated questions
            window.location.reload();
            setMessage('AI-Regrade initiated. The system will update the grades shortly.');
        } catch (error) {
            console.error('AI-Regrade failed:', error);
            setMessage('AI-Regrade failed. Please try again.');
        } finally {
            setIsLoading(false);
            setIsRegradeOpen(false);
            setActiveSubQuestionId(null);
        }
    };

    const SortRubricItemsAndDisplay = ({subQuestion}) => {
        subQuestion.rubricItems.sort((a, b) => {
            if (a.value == b.value) {
                return 0;
            }
            return a.value > b.value ? -1 : 1;
        });
        return (
            <div className="rounded-xl overflow-hidden border border-gray-300">
                {subQuestion.rubricItems.map((rubricItem, rubricIndex) => (
                    <div
                        key={rubricIndex}
                        className={`
                        flex items-center px-3 py-2 cursor-pointer
                        ${hasRubricItem(subQuestion.id, rubricItem.id)
                            ? 'bg-orange-200'
                            : 'bg-white hover:bg-gray-50'
                        }
                        ${rubricIndex !== 0 ? 'border-t border-gray-300' : ''}
                    `}
                        onClick={handleRubricItemChange(subQuestion.id, rubricItem)}
                    >
                        <div className="flex items-center flex-1">
                            <div className={`
                            w-5 h-5 rounded-full border border-gray-400 
                            flex items-center justify-center mr-3
                            ${hasRubricItem(subQuestion.id, rubricItem.id)
                                ? 'bg-gray-500 border-gray-700'
                                : ''
                            }
                        `}>
                                {hasRubricItem(subQuestion.id, rubricItem.id) && (
                                    <Check className="w-3 h-3 text-white stroke-2"/>
                                )}
                            </div>
                            <div className="flex items-center">
                                <span className="text-xl font-medium mr-3">{rubricItem.value}</span>
                                <span className="text-sm">{rubricItem.descriptor}</span>
                            </div>
                        </div>
                    </div>
                ))}
            </div>
        );
    };

    const HasSubQuestion = (questionId) => {
        for (const question of examPaper) {
            if (question.id !== questionId) {
                continue;
            }
            for (const subQuestion of question.subQuestions) {
                if (subQuestion.id === Number(subquestion_id)) {
                    return true;
                }
            }
        }
        return false;
    }

    const getSubQuestionViewedCount = () => {
        for (const question of examPaper) {
            for (const subQuestion of question.subQuestions) {
                if (subQuestion.id === Number(subquestion_id)) {
                    return subQuestion.viewed_count || 0;
                }
            }
        }
        return 0;
    }

    const getPageMapping = () => {
        getQuestionMapping();
        let num = 1;
        for (const question of examPaper) {
            for (const subQuestion of question.subQuestions) {
                if (subQuestion.id == subquestion_id) {
                    const mappedPage = questionMapping[String(num)];
                    setPageNumber(mappedPage); // Set the page number when we find it
                    return mappedPage;
                }
                num++;
            }
        }
    }

    return (
        <div
            key={`${assignment_id}-${submission_id}-${refreshKey}`}
            className="flex flex-col mt-5 w-full overflow-x-hidden"
        >
            <style>{`
    @keyframes fade-in {
        0% {
            opacity: 0;
            transform: scale(0.95);
        }
        100% {
            opacity: 1;
            transform: scale(1);
        }
    }
    .animate-fade-in {
        animation: fade-in 0.3s ease-out forwards;
    }
    .backdrop-blur-sm {
        backdrop-filter: blur(4px);
        -webkit-backdrop-filter: blur(4px);
    }
`}</style>
            {/* navigation trail */}
            <div className="flex flex-col fixed w-full py-7 z-40 bg-white px-5">
                <div className="flex items-center justify-between mb-4">
                    <div className="flex items-center space-x-2">
                        <Link to="/tclasses">
                            <div className="size-10 flex items-center justify-center bg-gray-50 rounded-full">
                                <img src="/assets/sidebar/menu-home.png" className="size-5" alt="Home"/>
                            </div>
                        </Link>
                        <Link to="/tclasses">
                            <button>
                                <img src="/assets/back_arrow.png" className="size-6" alt="Back"/>
                            </button>
                        </Link>
                        <Link to="/tclasses" className="text-black text-lg font-medium ml-4">
                            My Classes
                        </Link>
                        <Link to={`/tclasses/${assignment?.course_id}/tassignments`}>
                            <button>
                                <img src="/assets/back_arrow.png" className="size-6" alt="Back"/>
                            </button>
                        </Link>
                        <Link
                            to={`/tclasses/${assignment?.course_id}/tassignments`}
                            className="text-black text-lg font-medium ml-4"
                        >
                            {assignment?.course?.name.length > 20
                                ? assignment?.course?.name.substring(0, 17) + '...'
                                : assignment?.course?.name}
                        </Link>
                        <Link
                            to={`/tclasses/${assignment?.course_id}/tassignments/${assignment?.id}/tsubmissions`}
                        >
                            <button>
                                <img src="/assets/back_arrow.png" className="size-6" alt="Back"/>
                            </button>
                        </Link>
                        <Link
                            to={`/tclasses/${assignment?.course_id}/tassignments/${assignment?.id}/tsubmissions`}
                            className="text-black text-lg font-medium ml-4"
                        >
                            {assignment?.title.length > 20
                                ? assignment?.title.substring(0, 17) + '...'
                                : assignment?.title}
                        </Link>
                        <Link
                            to={`/tclasses/${assignment?.course_id}/tassignments/${assignment?.id}/tsubmissions`}
                        >
                            <img src="/assets/back_arrow.png" className="size-6" alt="Back"/>
                        </Link>
                        <h1 className="text-black text-lg font-medium ml-4">
                            {studentName.length > 15 ? studentName.substring(0, 12) + '...' : studentName}
                        </h1>
                    </div>


                    <div className="flex items-center justify-between">
                        <div className="flex items-center space-x-2">
                            <div className="flex items-center space-x-2 mr-5">
                                <div className="text text-gray-600 mr-10">
                                    {viewedCount}/{assignment?.num_submissions || 0} manually reviewed
                                </div>
                                <button
                                    className="text-gray-600 text-l font-medium flex items-center"
                                    onClick={clickRight}
                                >
                                    <span>Back</span>
                                    <i className="fas fa-sort-up -rotate-90 text-2xl ml-3"></i>
                                </button>
                                <button
                                    className="text-gray-600 text-l font-medium flex items-center"
                                    onClick={clickLeft}
                                >
                                    <i className="fas fa-sort-up rotate-90 text-2xl mr-2"></i>
                                    <span>Next</span>
                                </button>
                            </div>

                            <div className="flex items-center space-x-4">
                                <button
                                    className="text-gray-600 text-md hover:bg-gray-200 hover:text-gray-800 font-medium flex items-center border border-black rounded-full p-2"
                                    onClick={findNextSubquestion}
                                >
                                    <span>Next Subquestion</span>
                                    <div className="ml-2 flex flex-row transform rotate-90">
                                        <i className="fas fa-play text-sm"></i>
                                    </div>
                                </button>
                                <button
                                    className="text-gray-600 text-l hover:bg-gray-200 hover:text-gray-800 font-medium flex items-center border border-black rounded-full p-2"
                                    onClick={findNextUnseen}
                                >
                                    <span>Next Unseen</span>
                                    <div className="ml-2 flex flex-row">
                                        <i className="fas fa-play text-sm"></i>
                                        <i className="fas fa-play text-sm"></i>
                                    </div>
                                </button>
                            </div>

                        </div>

                    </div>
                </div>
            </div>

            {/* main content */}
            <div className="flex h-[calc(100vh-9rem)] mt-24">
                {/* student work */}
                <div className="w-1/2 p-4">
                    {submitted &&
                        (submissionData.essay_text ? (
                            <div className="w-full h-full overflow-auto bg-white border border-gray-300 p-4 rounded-xl">
                                <pre className="whitespace-pre-wrap font-sans">{submissionData.essay_text}</pre>
                            </div>
                        ) : (
                            <div
                                className="ml-5 mr-5 w-1/2 p-4 h-[calc(100vh-10rem)] min-w-full border border-gray-300 rounded-xl"> {/* Added min-width */}
                                {submitted && (
                                    submissionData.essay_text ? (
                                        <div
                                            className="w-full h-full overflow-auto bg-white p-4">
                                            <pre
                                                className="whitespace-pre-wrap font-sans">{submissionData.essay_text}</pre>
                                        </div>
                                    ) : (
                                        <div className="w-full h-full relative flex-1">  {/* Added flex-1 */}
                                            <PDFView
                                                url={`${apiUrl}/api/pdf-file/${submissionData.file.filename}`}
                                                className="absolute inset-0 flex-1" /* Added flex-1 */
                                                initialPage={pageNumber}
                                            />
                                        </div>
                                    )
                                )}
                            </div>
                        ))}
                </div>

                <div className="w-1/2 overflow-y-auto p-8">
                    {assignment?.is_exam && (
                        <div className="mb-4">
                            <label className="block text-sm font-medium text-gray-700 mb-1">
                                Assigned to:
                            </label>
                            <StudentSearchDropdown
                                students={students}
                                currentStudentId={assignedStudentId}
                                currentStudentName={studentName}
                                onStudentSelect={(student) => {
                                    setNewStudentAssignment(student);
                                    setIsStudentAssignmentConfirmationOpen(true);
                                }}
                            />
                            {error && <p className="mt-2 text-sm text-red-600">{error}</p>}
                        </div>
                    )}
                    {groupStudents.length > 0 && (
                        <div>
                            <p>Group Members:</p>
                            <p>
                                {Array.isArray(groupStudents) && groupStudents.length > 0
                                    ? groupStudents.map((student, index) => (
                                        <span key={index}>
                                            {student[0]} ({student[1]}){index < groupStudents.length - 1 ? ', ' : ''}
                                        </span>
                                    ))
                                    : 'No students to display'}
                            </p>
                        </div>
                    )}
                    {examPaper.map((question, questionIndex) => {
                        return HasSubQuestion(question.id) ? (
                            <div key={questionIndex} className="mb-8">
                                {question.subQuestions.map((subQuestion, subQuestionIndex) => {
                                    return subQuestion.id == subquestion_id ? (
                                        <div
                                            key={subQuestionIndex}
                                            className={`mb-6 ml-4 ${regradeInProgress[subQuestion.id] ? 'opacity-50 cursor-not-allowed pointer-events-none' : ''}`}
                                        >
                                            {regradeInProgress[subQuestion.id] && (
                                                <div className="mt-2 mb-4 text-xl text-orange-300">
                                                    This subquestion is being regraded...
                                                </div>
                                            )}
                                            <h3 className="font-bold mb-2 pt-4">
                                                {questionIndex + 1}. {String.fromCharCode(97 + subQuestionIndex)})
                                            </h3>

                                            <div className="flex flex-col gap-2 mb-2 mt-3">
                                                <SortRubricItemsAndDisplay subQuestion={subQuestion}/>
                                            </div>
                                            {
                                                <div className="mt-2">
                                                    {isEditing(subQuestion.id) ? (
                                                        <>
                                                            <textarea
                                                                ref={el => (feedbackRefs.current[subQuestion.id] = el)}
                                                                value={editableFeedback[subQuestion.id]}
                                                                className="w-full p-2 border rounded"
                                                                rows="3"
                                                                onChange={e => handleFeedbackChange(subQuestion.id, e.target.value)}
                                                            />
                                                            <div className="flex justify-between items-center mt-2">
                                                                <button
                                                                    className="less-sexy-button w-40 h-9 hover:bg-gray-200"
                                                                    onClick={() => handleFeedbackSave(subQuestion.id)}
                                                                >
                                                                    Save
                                                                </button>
                                                                {subQuestion.questionType !== 'mcq' && (
                                                                    <button
                                                                        className="less-sexy-button w-40 h-9 hover:bg-gray-200 flex items-center justify-center"
                                                                        onClick={() => handleRegradeClick(subQuestion.id)}
                                                                    >
                                                                        <span className="mr-2 text-2xl"
                                                                              style={{fontSize: '1.5em'}}>
                                                                            ✨
                                                                        </span>
                                                                        <span>Edit Rubric</span>
                                                                    </button>
                                                                )}
                                                            </div>
                                                        </>
                                                    ) : (
                                                        <>
                                                            {renderFeedback(getFeedback(subQuestion.id))}
                                                            <div className="flex justify-between items-center mt-2">
                                                                <button
                                                                    className="less-sexy-button w-40 h-9 hover:bg-gray-200"
                                                                    onClick={() => handleFeedbackEdit(subQuestion.id)}
                                                                >
                                                                    Edit
                                                                </button>
                                                                {subQuestion.questionType !== 'mcq' && (
                                                                    <button
                                                                        className="less-sexy-button w-40 h-9 hover:bg-gray-200 flex items-center justify-center"
                                                                        onClick={() => handleRegradeClick(subQuestion.id)}
                                                                    >
                                                                        <span className="mr-2 text-2xl"
                                                                              style={{fontSize: '1.5em'}}>
                                                                            ✨
                                                                        </span>
                                                                        <span>Edit Rubric</span>
                                                                    </button>
                                                                )}
                                                            </div>
                                                        </>
                                                    )}
                                                </div>
                                            }

                                            {getRR(subQuestion.id) && (
                                                <div className="mt-4 bg-orange-100 p-4 rounded">
                                                    <h4 className="font-semibold mb-2">Regrade Request:</h4>
                                                    <p>{getRRText(subQuestion.id)}</p>
                                                    <button
                                                        className="less-sexy-button w-full h-9 hover:bg-gray-200 mt-2"
                                                        onClick={() => handleRegradeReq(subQuestion.id)}
                                                    >
                                                        Dismiss Regrade Request
                                                    </button>
                                                </div>
                                            )}
                                        </div>
                                    ) : null
                                })}
                            </div>
                        ) : null
                    })}
                    {assignment?.is_essay && (
                        <div className="w-full bg-white border border-gray-300 p-4 rounded-xl">
                            <style>{`
                    .feedback-content h2 {
                        font-size: 20px;
                        font-weight: var(--h2-font-weight);
                        margin: var(--h2-margin);
                    }
                    .feedback-content p {
                        margin: var(--p-margin);
                    }
                    .feedback-content ul {
                        list-style-type: disc;
                        padding: var(--ul-padding);
                        margin: var(--ul-margin);
                    }
                    .feedback-content li {
                        margin: var(--li-margin);
                    }
                `}</style>
                            {isEditingEssay ? (
                                <>
                                    <div className="">
                                        <textarea
                                            ref={feedbackRef}
                                            value={editableEssayFeedback}
                                            className="w-full p-2 border-none h-[500px]"
                                            rows="10"
                                            onChange={e => handleEssayFeedbackChange(e.target.value)}
                                        />
                                        <button
                                            className="less-sexy-button w-40 h-9 hover:bg-gray-200 mt-2"
                                            onClick={handleEssayFeedbackSave}
                                        >
                                            Save
                                        </button>
                                    </div>
                                </>
                            ) : (
                                <>
                                    <div className="flex flex-col justify-between feedback-content">
                                        <div
                                            dangerouslySetInnerHTML={{__html: submissionData?.file?.filename}}
                                            className="feedback-content"
                                            style={{
                                                '--h2-font-size': '1.5em',
                                                '--h2-font-weight': 'bold',
                                                '--h2-margin': '1em 0 0.5em',
                                                '--p-margin': '0 0 1em',
                                                '--ul-padding': '0 0 0 2em',
                                                '--ul-margin': '0 0 1em',
                                                '--li-margin': '0 0 0.5em',
                                                height: 'auto', // Adjust the height property
                                                overflow: 'visible' // Ensure overflow is visible
                                            }}
                                        />
                                        <button
                                            className="less-sexy-button w-40 h-9 hover:bg-gray-200 mt-2"
                                            onClick={handleEssayFeedbackEdit}
                                        >
                                            Edit
                                        </button>
                                    </div>
                                </>
                            )}
                        </div>
                    )}
                </div>
            </div>
            {showAllReviewedPopup && (
                <>
                    {/* Blurred background overlay */}
                    <div
                        className="fixed inset-0 bg-black/20 backdrop-blur-sm z-50"
                        style={{backdropFilter: 'blur(4px)'}}
                    />

                    {/* Popup */}
                    <div className="fixed inset-0 flex items-center justify-center z-50">
                        <div
                            className="bg-white rounded-xl shadow-lg p-6 border border-gray-200 transform transition-all animate-fade-in">
                            <div className="flex items-center">
                                <div className="bg-orange-100 rounded-full p-3 mr-4">
                                    <img
                                        src="/assets/checkmark.png"
                                        className="size-6"
                                        alt="Success"
                                    />
                                </div>
                                <p className="text-lg font-medium">
                                    All submissions for this subquestion reviewed. Taking you to the next subquestion.
                                </p>
                            </div>
                        </div>
                    </div>
                </>
            )}
            {showNextSubquestionPopup && (
                <>
                    {/* Blurred background overlay */}
                    <div
                        className="fixed inset-0 bg-black/20 backdrop-blur-sm z-50"
                        style={{backdropFilter: 'blur(4px)'}}
                    />

                    {/* Popup */}
                    <div className="fixed inset-0 flex items-center justify-center z-50">
                        <div
                            className="bg-white rounded-xl shadow-lg p-6 border border-gray-200 transform transition-all animate-fade-in">
                            <div className="flex items-center">
                                <div className="bg-orange-100 rounded-full p-3 mr-4">
                                    <img
                                        src="/assets/checkmark.png"
                                        className="size-6"
                                        alt="Success"
                                    />
                                </div>
                                <p className="text-lg font-medium">
                                    {nextSubQuestionId
                                        ? "Taking you to the next subquestion..."
                                        : "No more subquestions. Taking you to the assignment page..."}
                                </p>
                            </div>
                        </div>
                    </div>
                </>
            )}

            {isRegradeOpen && (
                <AIRegrade
                    subQuestion={examPaper
                        .flatMap(q => q.subQuestions)
                        .find(sq => sq.id === activeSubQuestionId)}
                    isEssay={assignment?.is_essay}
                    onClose={handleRegradeClose}
                    onSubmit={handleRegradeSubmit}
                />
            )}

            <ConfirmationModal
                isOpen={isConfirmationOpen}
                message="Are you sure you want to regrade this subquestion for all students with the new rubric and grading instructions?"
                onClose={() => setIsConfirmationOpen(false)}
                onConfirm={handleConfirmRegrade}
            >
                <p>This action cannot be undone.</p>
            </ConfirmationModal>

            <ConfirmationModal
                isOpen={isStudentAssignmentConfirmationOpen}
                message={`Assigning to ${newStudentAssignment?.name} (ID: ${newStudentAssignment?.id}) will unassign any submission that may be currently assigned to them.`}
                onClose={() => setIsStudentAssignmentConfirmationOpen(false)}
                onConfirm={handleConfirmStudentAssignment}
            >
                <p>Are you sure you want to proceed?</p>
            </ConfirmationModal>

            {isLoading && (
                <div className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center z-50">
                    <div className="bg-white p-6 rounded-xl shadow-lg">
                        <p>Regrading in progress...</p>
                        <p>Expected wait time: 30 seconds</p>
                    </div>
                </div>
            )}
        </div>
    );
};

export default TQuestionDetails;