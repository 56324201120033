import React, {useEffect, useState} from 'react';
import {Link} from 'react-router-dom';
import {apiUrl, gradeApi} from '../../api.js';
import {useAuth} from '../../auth/AuthContext';
import DueTimeDisplayTable from '../DueTimeDisplayTable.js';
import TeacherStudentUpload from './TeacherStudentUpload'; // Import the new component

function TQuestionGrade(props) {
    const [submissions, setSubmissions] = useState([]);
    const [assignment, setAssignment] = useState();
    const {user} = useAuth();
    const [errorMessage, setErrorMessage] = useState('');
    const [examPaper, setExamPaper] = useState([]);
    const [regrades, setRegrades] = useState([]);
    const [firstSubmission, setFirstSubmission] = useState(0);

    const numSubmissions = assignment?.num_submissions
    const hasSubmissions = numSubmissions > 0;

    const getSubmissions = async () => {
        if (!assignment) {
            return;
        }
        try {
            const response = await gradeApi.get({
                path: `/api/assignment/${assignment.id}/submissions/`,
                config: {
                    headers: {
                        Authorization: `Bearer ${user.session_token}`
                    }
                }
            });
            setFirstSubmission(response.data.submissions.map(sub => sub.id)[0]);
            console.log(submissions)
            console.log(hasSubmissions)
        } catch (error) {
            console.log(error);
            setErrorMessage('Failed to fetch submissions. Please try again.');
        }
    };

    const getRegrades = async () => {
        try {
            const response = await gradeApi.get({
                path: `/api/regrade-requests/`,
                config: {
                    headers: {
                        Authorization: `Bearer ${user.session_token}`
                    }
                }
            });
            const regradesForTeacher = response.data;
            console.log(regradesForTeacher);
            setRegrades(regradesForTeacher)
        } catch (error) {
            console.log(error);
        }
    };

    const hasRegrade = (submission) => {
        for (var i = 0; i < regrades.length; i++) {
            if (regrades[i].id === submission.id) {
                return true;
            }
        }
        return false;
    }

    const getQuestions = async () => {
        try {
            const response = await gradeApi.get({
                path: `/api/assignment/${assignment.id}/questions/`,
                config: {
                    headers: {
                        Authorization: `Bearer ${user.session_token}`
                    }
                }
            });
            const questionsFromDB = response.data;
            // console.log(questionsFromDB);
            setExamPaper(questionsFromDB.questions);
            console.log(questionsFromDB.questions);
        } catch (error) {
            console.log(error);
        }
    };

    useEffect(() => {
        getRegrades();
    }, []);

    useEffect(() => {
        getQuestions();
        getSubmissions();
    }, [assignment]);

    useEffect(() => {
        setSubmissions(props.submissions_in);
        setAssignment(props.assignment_in);
    }, [props.submissions_in, props.assignment_in]);

    if (!assignment || !submissions) {
        return <h1>loading...</h1>;
    }

    function process_string(input_) {
        // Check if input_ is an array
        if (!Array.isArray(input_)) {
            // console.error('Input is not an array');
            return '';
        }

        // Filter out any null or undefined values
        const filteredInput = input_.filter(item => item != null);

        // Join the array elements with ", " (comma and space)
        return filteredInput.join(', ');
    }

    return (
        <div id="HomeTeacher" className="flex flex-row">
            <div id="Main-Content-container" className="flex flex-col space-y-8 w-full">
                <div className="flex flex-col w-full">
                    <h2 className="text-xl font-bold mb-1">
                        Step {assignment ? (assignment.is_essay ? 2 : assignment.is_exam ? 5 : 3) : 3}: Review
                        grades
                    </h2>
                    <p className="text-sm mb-4">
                        After submission upload, suggested grades will be available within two minutes.
                    </p>
                </div>

                <div className="flex justify-start">
                    <div className="flex items-center bg-grey-700 rounded-full p-2 shadow-md mr-4">
                        <input
                            type="checkbox"
                            id="openStudentCheckBox"
                            checked={props.reviewMode === "Student"}
                            className="sr-only peer"
                            onClick={() => {
                                props.setReviewMode("Student")
                            }}
                        />
                        <label
                            htmlFor="openStudentCheckBox"
                            className="cursor-pointer w-5 h-5 bg-white border border-gray-300 rounded-3xl peer-checked:bg-orange-200 peer-checked:border-orange-300 flex items-center justify-center transition-colors duration-200 ease-in-out mr-2"
                        ></label>
                        <label htmlFor="openStudentCheckBox" className="text-sm font-medium text-black">
                            View by Student
                        </label>
                    </div>
                    <div className="flex items-center bg-grey-700 rounded-full p-2 shadow-md mr-4">
                        <input
                            type="checkbox"
                            id="openQuestionCheckBox"
                            checked={props.reviewMode === "Question"}
                            className="sr-only peer"
                            onClick={() => {
                                props.setReviewMode("Question")
                            }}
                        />
                        <label
                            htmlFor="openQuestionCheckBox"
                            className="cursor-pointer w-5 h-5 bg-white border border-gray-300 rounded-3xl peer-checked:bg-orange-200 peer-checked:border-orange-300 flex items-center justify-center transition-colors duration-200 ease-in-out mr-2"
                        ></label>
                        <label htmlFor="openQuestionCheckBox" className="text-sm font-medium text-black">
                            View by Subquestion
                        </label>
                    </div>
                </div>

                <div className="ml-4bg-white sexy-border p-6">
                    {errorMessage && (
                        <div className="bg-red-100 border border-red-400 text-red-700 px-4 py-3 rounded mb-4">
                            {errorMessage}
                        </div>
                    )}
                    <table className="w-full border-collapse">
                        <thead>
                        <tr className="border-b border-border">
                            <th className="py-4 text-left text-gray custom-16">Question</th>
                            {/* <th className="py-4 text-left text-gray custom-16">Status</th>
                <th className="py-4 text-left text-gray custom-16">Date Submitted</th>
                <th className="py-4 text-left text-gray custom-16">Grade</th> */}
                        </tr>
                        </thead>
                        {examPaper ? (
                            <tbody>
                            {examPaper.map((question, questionIndex) => (
                                <React.Fragment key={questionIndex}>
                                    <td className="py-4 text-left text-gray-500 custom-16-semi">
                                        Question {questionIndex + 1}: {question.description.length > 45
                                        ? question.description.substring(0, 42) + '...'
                                        : question.description}
                                    </td>

                                    {question.subQuestions && question.subQuestions.map((subQuestion, subQuestionIndex) => (
                                        <tr key={`${questionIndex}-${subQuestionIndex}`}
                                            className="border-b border-border">
                                            {hasSubmissions ? (
                                                <Link
                                                    to={`/tclasses/${assignment.course_id}/tassignments/${assignment.id}/tsubmissions/${firstSubmission}/tquestiondetails/${subQuestion.id}`}
                                                    className="hover:underline text-black relative group ml-4"
                                                >
                                                    <td className="py-4 text-left custom-16">
                                                        <div>
                                                            SubQuestion {questionIndex + 1}{String.fromCharCode(97 + subQuestionIndex)}: {
                                                            subQuestion.question.length > 45
                                                                ? subQuestion.question.substring(0, 42) + '...'
                                                                : subQuestion.question
                                                        }
                                                        </div>
                                                        <div className="text-sm text-gray-600 mt-1">
                                                            {subQuestion.viewed_count}/{numSubmissions} manually
                                                            reviewed
                                                        </div>
                                                    </td>
                                                </Link>
                                            ) : (
                                                <td className="py-4 text-left custom-16 ml-4 text-gray-500">
                                                    SubQuestion {questionIndex + 1}{String.fromCharCode(97 + subQuestionIndex)}: {subQuestion.question}
                                                </td>
                                            )}
                                        </tr>
                                    ))}
                                </React.Fragment>
                            ))}
                            </tbody>
                        ) : (
                            <tbody>
                            <tr>
                                <td colSpan="3" className="text-center py-4 text-gray-500">
                                    No submissions found
                                </td>
                            </tr>
                            </tbody>
                        )}
                    </table>
                </div>
            </div>
        </div>
    );
}

export default TQuestionGrade;
