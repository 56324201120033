import { useSortable } from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';
import { ErrorMessage, Field, FieldArray, useFormikContext } from 'formik';
import React, { useEffect, useState } from 'react';
import { gradeApi } from '../../../api.js';
import { useAuth } from '../../../auth/AuthContext';
import DragAndDropUpload from '../../DragDropQuestion.js';
import { getFieldName } from './utils.js';
import RichTextField from "./RichTextField";
import { Loader2 } from 'lucide-react';

export const TSubQuestionNew = ({
    question,
    subQuestion,
    isPublished,
    index,
    fieldName,
    disabledStyle,
    setError,
    deleteSubQuestion,
    viewedQuestions,
    setViewedQuestions,
    copiedRubricItems,
    onCopyRubricItems,
    onPasteRubricItems
}) => {
    const isSubQuestionFocused = viewedQuestions.get(question.id)?.includes(subQuestion.id);
    const { user } = useAuth();
    const { values, setFieldValue, submitForm } = useFormikContext();
    const [isLoading, setIsLoading] = useState(false);
    const [showAIRubricModal, setShowAIRubricModal] = useState(false);
    const [maxPoints, setMaxPoints] = useState('');
    const [aiRubricError, setAiRubricError] = useState('');
    // const [previewError, setPreviewError] = useState('');


    const { isDragging, setNodeRef, attributes, listeners, transform, transition } = useSortable({
        id: subQuestion.id,
        animateLayoutChanges: () => false
    });

    const [isAnswerUploadVisible, setIsAnswerUploadVisible] = useState(false);
    // const [previews, setPreviews] = useState({});

    const style = {
        transform: CSS.Translate.toString(transform),
        transition,
        opacity: isDragging ? 0.5 : 1
    };

    useEffect(() => {
        if (isDragging) {
            if (isSubQuestionFocused) {
                setViewedQuestions(prev => new Map([...prev, [question.id, []]]));
            }
        }
    }, [isDragging]);

    const handleImageUpload = async event => {
        const file = event.target.files[0];
        if (file) {
            const reader = new FileReader();
            reader.onloadend = async () => {
                const imageUrl = reader.result;
                setFieldValue(getFieldName(index, 'imageUrl', fieldName), imageUrl);
                setFieldValue(getFieldName(index, 'imageS3Key', fieldName), null);
                setFieldValue(getFieldName(index, 'imageSize', fieldName), 50);
            };
            reader.readAsDataURL(file);

            setTimeout(async () => {
                await submitForm();
            }, 500);
        }
    };

    const handleAnswerImageUpload = async event => {
        const file = event.target.files[0];
        if (file) {
            const reader = new FileReader();
            reader.onloadend = async () => {
                const answerImageUrl = reader.result;
                setFieldValue(getFieldName(index, 'answerImageUrl', fieldName), answerImageUrl);
                setFieldValue(getFieldName(index, 'answerImageS3Key', fieldName), null);
                setFieldValue(getFieldName(index, 'answerImageSize', fieldName), 50);
            };
            reader.readAsDataURL(file);

            setTimeout(async () => {
                await submitForm();
            }, 500);
        }
    };

    const handleAIRubricClick = () => {
        if (!subQuestion.solution) {
            setAiRubricError('Please enter the solution before generating an AI rubric.');
            return;
        }
        setShowAIRubricModal(true);
    };

    const generateAIRubric = async (questionIndex, subQuestionIndex, maxPoints) => {
        setIsLoading(true);
        console.log(questionIndex)
        try {
            const response = await gradeApi.post({
                path: '/api/assignment/ai-rubric/',
                body: {
                    question: values.examPaper[questionIndex].description,
                    subQuestion: values.examPaper[questionIndex].subQuestions[subQuestionIndex].question,
                    solution: values.examPaper[questionIndex].subQuestions[subQuestionIndex].solution,
                    maxPoints: maxPoints
                },
                config: {
                    headers: { Authorization: `Bearer ${user.session_token}` }
                }
            });

            console.log(response)

            const newRubricItems = response.data.rubricItems.map(item => ({
                descriptor: item.description,
                value: item.points.toString()
            }));

            console.log(newRubricItems)

            console.log(questionIndex)
            setFieldValue(`examPaper[${questionIndex}].subQuestions[${subQuestionIndex}].rubricItems`, newRubricItems);

            setTimeout(async () => {
                await submitForm();
            }, 500);

            console.log("changed field")
        } catch (error) {
            console.log(error)
            setError('Failed to generate AI rubric. Please try again.');
        } finally {
            setIsLoading(false);
        }
    };

    const handleGenerateAIRubric = () => {
        const numericMaxPoints = parseInt(maxPoints, 10);
        if (isNaN(numericMaxPoints) || numericMaxPoints <= 0) {
            setError('Please enter a valid positive number for maximum points.');
            return;
        }
        setShowAIRubricModal(false);
        generateAIRubric(question.id - 1, subQuestion.id - 1, numericMaxPoints);
    };


    const updateSubViewedQuestions = id => {
        const expandedQ = new Map(viewedQuestions);
        const selectedQuestionKeys = viewedQuestions.get(question.id);
        if (isSubQuestionFocused) {
            expandedQ.set(question.id, [...selectedQuestionKeys.filter(i => i !== id)]);
        } else {
            expandedQ.set(question.id, [...selectedQuestionKeys, id]);
        }

        return setViewedQuestions(expandedQ);
    };

    return (
        <div ref={setNodeRef} style={style} className="rounded-xl bg-white p-4 mb-4 w-full">
            <div className="flex items-center justify-between w-full">
                <div className="flex items-center space-x-4">
                    <div className={`size-4 flex items-center justify-center ${disabledStyle}`}>
                        <button
                            type="button"
                            disabled={isPublished || question.subQuestions?.length < 2}
                            onClick={async () => {
                                await deleteSubQuestion(index);
                                submitForm();
                            }}
                        >


                            <img src="/assets/trash.png" className="size-4" alt="Delete sub-question" />
                        </button>
                    </div>

                    <div
                        className="font-bold flex items-center text-base">Sub-question {String.fromCharCode(96 + subQuestion.id)}
                    </div>
                    <Field
                        as="select"
                        name={getFieldName(index, 'questionType', fieldName)}
                        disabled={isPublished}
                        className="border rounded-xl py-1"
                        onBlur={submitForm}
                    >
                        <option value="freeform">Freeform Question</option>
                        <option value="mcq">Multiple Choice Question</option>
                    </Field>
                </div>
                <div className="flex items-center space-x-4">
                    <div className="size-10 flex items-center justify-center">
                        <button
                            type="button"
                            {...(isPublished ? {} : { ...listeners, ...attributes })}
                            disabled={isPublished}
                        >
                            <img src="/assets/list.png" className="size-10" />
                        </button>
                    </div>
                    <div
                        className={`size-10 flex items-center justify-center ${disabledStyle}`}
                        style={{ marginLeft: 0 }}
                    >
                        <button type="button" onClick={() => updateSubViewedQuestions(subQuestion.id)}>
                            <img
                                src={
                                    isSubQuestionFocused
                                        ? '/assets/arrow-up-white.png'
                                        : '/assets/arrow-down-white.png'
                                }
                                className="size-10"
                            />
                        </button>
                    </div>
                </div>
            </div>


            {showAIRubricModal && (
                <div className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center z-50">
                    <div className="bg-white p-6 rounded-xl shadow-lg max-w-sm w-full">
                        <h3 className="text-lg font-semibold mb-4">AI Rubric</h3>
                        <p className="mb-4">Enter question weight (maximum number of points for this question):</p>
                        <input
                            type="number"
                            value={maxPoints}
                            className="border rounded px-2 py-1 mb-4 w-full"
                            onChange={(e) => setMaxPoints(e.target.value)}
                        />
                        <div className="flex justify-end space-x-2 mt-4">
                            <button
                                className="less-sexy-button"
                                onClick={() => setShowAIRubricModal(false)}
                            >
                                Cancel
                            </button>
                            <button
                                className="sexy-button"
                                onClick={handleGenerateAIRubric}
                            >
                                Generate
                            </button>
                        </div>
                    </div>
                </div>
            )}

            {/* {isLoading && (
                <div
                    className="fixed inset-0 bg-gray-600 bg-opacity-50 backdrop-filter backdrop-blur-sm flex items-center justify-center z-50">
                    <div className="bg-white p-6 rounded-lg shadow-xl">
                        <p className="text-lg font-semibold">Generating AI Rubric...</p>
                    </div>
                </div>
            )} */}

            {isSubQuestionFocused && (
                <>
                    <div className="flex flex-col md:flex-row justify-between items-start mt-4">
                        <div className="w-full">
                            <Field
                                name={getFieldName(index, 'question', fieldName)}
                                component={isPublished ? 'textarea' : RichTextField}
                                placeholder="Content"
                                disabled={isPublished}
                                className={`sexy-border rounded-lg mb-4 focus:ring-0 focus:sexy-border focus:rounded-lg w-full h-36 ${disabledStyle}`}
                                onBlur={submitForm}
                            />
                            <div className="mb-2 text-sm">Optional: Upload an Image or Drawing</div>
                            {!subQuestion.imageS3Key && (
                                <div className="w-full">
                                    <DragAndDropUpload
                                        handleImageUpload={handleImageUpload}
                                        isPublished={isPublished}
                                    />
                                </div>
                            )}
                            {subQuestion.imageS3Key && (
                                <div className="relative mb-4 mt-2 flex items-center">
                                    <img
                                        src={`${process.env.REACT_APP_S3_AWS_IMAGES_BUCKET_URL}/${subQuestion.imageS3Key}`}
                                        alt="Sub-question"
                                        className="w-20 h-20 object-cover"
                                    />
                                    <button
                                        type="button"
                                        disabled={isPublished}
                                        onClick={() => {
                                            setFieldValue(getFieldName(index, 'imageUrl', fieldName), null);
                                            setFieldValue(getFieldName(index, 'imageS3Key', fieldName), null);

                                            setTimeout(async () => {
                                                await submitForm();
                                            }, 500);
                                        }}
                                    >
                                        <img
                                            src="/assets/trash.png"
                                            className="size-4 ml-2"
                                            alt="Delete sub-question"
                                        />
                                    </button>
                                </div>
                            )}
                            {subQuestion.imageS3Key && (
                                <div className="flex items-center mb-4">
                                    <div className="mr-3 text-sm">Image size:</div>
                                    <div className={`input-entry-box font-normal px-2 w-16 ${disabledStyle}`}>
                                        <Field
                                            name={getFieldName(index, 'imageSize', fieldName)}
                                            disabled={isPublished}
                                            type="number"
                                            min="1"
                                            max="100"
                                            className="actual-input text-black"
                                            onBlur={submitForm}
                                        />
                                    </div>
                                    <div className="ml-2">%</div>
                                </div>
                            )}
                            <div className="flex flex-col w-full mb-2 mt-8">
                                <button
                                    type="button"
                                    className="flex items-center text-sm"
                                    onClick={() => setIsAnswerUploadVisible(!isAnswerUploadVisible)}
                                >
                                    <img
                                        src="/assets/back_arrow.png"
                                        className={`size-5 transform ${isAnswerUploadVisible ? 'rotate-90' : '-rotate-90'} mr-2`}
                                        alt="Answer box image upload"
                                    />
                                    Optional: Upload an Answer Box Image
                                </button>
                            </div>
                            {isAnswerUploadVisible && (
                                <div className="mt-1 w-full bg-white rounded-lg">
                                    {/*<h4 className="text-lg font-semibold mb-3">Answer Box Image Upload</h4>*/}
                                    <div className="mt-1">
                                        <DragAndDropUpload
                                            handleImageUpload={handleAnswerImageUpload}
                                            isPublished={isPublished}
                                        />
                                    </div>
                                    {subQuestion.answerImageS3Key && (
                                        <div className="flex items-center mt-4 p-3 bg-white rounded-md shadow-sm">
                                            <img
                                                src={`${process.env.REACT_APP_S3_AWS_IMAGES_BUCKET_URL}/${subQuestion.answerImageS3Key}`}
                                                alt="Sub-question Answer image"
                                                className="w-20 h-20 object-cover rounded mr-3"
                                            />
                                            <button
                                                disabled={isPublished}
                                                className="p-1"
                                                onClick={() => {
                                                    setFieldValue(getFieldName(index, 'answerImageUrl', fieldName), null);
                                                    setFieldValue(getFieldName(index, 'answerImageS3Key', fieldName), null);

                                                    setTimeout(async () => {
                                                        await submitForm();
                                                    }, 500);
                                                }}
                                            >
                                                <img src="/assets/trash.png" className="size-4"
                                                    alt="Delete answer image" />
                                            </button>
                                        </div>
                                    )}
                                    {subQuestion.answerImageS3Key && (
                                        <div className="mt-4 p-3 bg-white rounded-md shadow-sm">
                                            <div className="mb-2 text-sm font-medium">Answer box image size:</div>
                                            <div className="flex items-center">
                                                <div className="input-entry-box font-normal px-2 w-20 mr-2">
                                                    <Field
                                                        name={getFieldName(index, 'answerImageSize', fieldName)}
                                                        disabled={isPublished}
                                                        type="number"
                                                        min="1"
                                                        max="100"
                                                        className="actual-input text-black w-full"
                                                        onBlur={submitForm}
                                                    />
                                                </div>
                                                <span>%</span>
                                            </div>
                                        </div>
                                    )}
                                </div>
                            )}
                            {/*<div className={`mb-4 mt-2 ${disabledStyle}`}>*/}
                            {/*    /!*<button*!/*/}
                            {/*    /!*    type="button"*!/*/}
                            {/*    /!*    disabled={isPublished}*!/*/}
                            {/*    /!*    className="less-sexy-button h-9 px-4 flex items-center justify-center mb-2 hover:bg-gray-200"*!/*/}
                            {/*    /!*    onClick={() => handlePreviewClick(question.id, subQuestion.id)}*!/*/}
                            {/*    /!*>*!/*/}
                            {/*    /!*    Preview*!/*/}
                            {/*    /!*</button>*!/*/}
                            {/*    {previewError && (*/}
                            {/*        <div className="text-red-500 text-sm mt-2">*/}
                            {/*            {previewError}*/}
                            {/*        </div>*/}
                            {/*    )}*/}
                            {/*    {previews[`${question.id}-${subQuestion.id}`] && (*/}
                            {/*        <div className="mt-2">*/}
                            {/*            <img*/}
                            {/*                src={previews[`${question.id}-${subQuestion.id}`].url}*/}
                            {/*                alt="LaTeX Preview"*/}
                            {/*                style={{*/}
                            {/*                    width: previews[`${question.id}-${subQuestion.id}`].width,*/}
                            {/*                    height: previews[`${question.id}-${subQuestion.id}`].height,*/}
                            {/*                    maxWidth: '100%',*/}
                            {/*                    objectFit: 'contain'*/}
                            {/*                }}*/}
                            {/*            />*/}
                            {/*        </div>*/}
                            {/*    )}*/}
                            {/*</div>*/}
                        </div>

                        <div className="flex flex-col w-full md:w-[450px] md:ml-8">
                            {subQuestion.questionType === 'freeform' && (
                                <div className="flex justify-between items-start mb-4">
                                    <div className="flex flex-col">
                                        <div className="text-sm font-medium mb-2">Answer box size (%)</div>
                                        <div className={`input-entry-box relative font-normal px-2 ${disabledStyle}`}>
                                            <Field
                                                name={getFieldName(index, 'questionSizing', fieldName)}
                                                disabled={isPublished}
                                                as="input"
                                                type="number"
                                                className={`actual-input text-black ${disabledStyle}`}
                                                onBlur={submitForm}
                                            />
                                            <span className="text-red-800 text-xs absolute left-0 bottom-[-20px]">
                                                <ErrorMessage name={getFieldName(index, 'questionSizing', fieldName)} />
                                            </span>
                                        </div>
                                    </div>
                                </div>

                            )}
                            <div className="flex flex-col space-y-2">
                                {subQuestion.questionType === 'freeform' ? (
                                    <div className="flex flex-col">
                                        <div className="flex items-center gap-2">
                                            <div className="text-sm font-medium mb-2">Rubric Items</div>
                                            <div className="flex justify-end mb-2">
                                                {isLoading && (
                                                    <div className="flex items-center mr-3">
                                                        <Loader2 className="h-4 w-4 animate-spin text-gray-500" />
                                                        <span
                                                            className="ml-2 text-sm text-gray-500">Generating...</span>
                                                    </div>
                                                )}
                                                <button
                                                    type="button"
                                                    disabled={isPublished || isLoading}
                                                    className={`mr-2 px-2 py-1 rounded-3xl border-[1px] border-black text-smallish font-medium transition-all duration-300 desktop:hover:-translate-y-[0.5px] desktop:hover:shadow bg-white hover:bg-gray-300 text-black ${isLoading ? 'opacity-50 cursor-not-allowed' : ''}`}
                                                    onClick={() => onCopyRubricItems(subQuestion.rubricItems)}
                                                >
                                                    Copy
                                                </button>
                                                <button
                                                    type="button"
                                                    disabled={isPublished || !copiedRubricItems || isLoading}
                                                    className={`px-2 py-1 rounded-3xl border-[1px] border-black text-smallish font-medium transition-all duration-300 desktop:hover:-translate-y-[0.5px] desktop:hover:shadow bg-orange-200 hover:bg-orange-300 text-black ${isLoading ? 'opacity-50 cursor-not-allowed' : ''}`}
                                                    onClick={() => onPasteRubricItems(question.id - 1, subQuestion.id - 1)}
                                                >
                                                    Paste
                                                </button>
                                                <button
                                                    type="button"
                                                    disabled={isPublished || isLoading}
                                                    className={`ml-2 p-1 bg-white rounded-full opacity-60 border-2 ${isLoading ? 'opacity-50 cursor-not-allowed' : ''}`}
                                                    onClick={handleAIRubricClick}
                                                >
                                                    <img src="/assets/magic-wand.png" alt="AI Rubric"
                                                        className="w-6 h-6" />
                                                </button>
                                            </div>
                                        </div>
                                        {aiRubricError &&
                                            <div className="text-red-500 text-sm mt-2 mb-2">{aiRubricError}</div>}
                                        <FieldArray name={getFieldName(index, 'rubricItems', fieldName)}>
                                            {({ remove, push }) => {
                                                const rubricFieldName = getFieldName(index, 'rubricItems', fieldName);
                                                return (
                                                    <div className="flex flex-col space-y-2">
                                                        {subQuestion.rubricItems.map((rubricItem, rubricIndex) => (
                                                            <div key={rubricIndex} className="flex items-start mb-2">
                                                                <div
                                                                    className={`size-4 flex-shrink-0 flex items-center justify-center mr-3 ${disabledStyle}`}
                                                                >
                                                                    <button
                                                                        type="button"
                                                                        disabled={isPublished}
                                                                        onClick={() => remove(rubricIndex)}
                                                                    >
                                                                        <img
                                                                            src="/assets/trash.png"
                                                                            className="size-4"
                                                                            alt="Delete rubric item"
                                                                        />
                                                                    </button>
                                                                </div>
                                                                <div className="flex-1 flex flex-col">
                                                                    <div className={`w-full ${disabledStyle}`}>
                                                                        <textarea
                                                                            name={getFieldName(rubricIndex, 'descriptor', rubricFieldName)}
                                                                            placeholder="Description"
                                                                            disabled={isPublished || isLoading}
                                                                            className={`w-full max-h-20 overflow-y-auto resize-none border border-gray-300 rounded-md px-2 py-1 focus:border-black focus:ring-0 text-black ${disabledStyle} ${isLoading ? 'opacity-50 cursor-not-allowed' : ''}`}
                                                                            value={rubricItem.descriptor}
                                                                            onChange={(e) => {
                                                                                setFieldValue(
                                                                                    getFieldName(rubricIndex, 'descriptor', rubricFieldName),
                                                                                    e.target.value
                                                                                );
                                                                            }}
                                                                            onBlur={submitForm}
                                                                            onInput={(e) => {
                                                                                e.target.style.height = 'auto';
                                                                                e.target.style.height = `${e.target.scrollHeight}px`;
                                                                            }}
                                                                        />
                                                                        <span className="text-red-800 text-xs">
                                                                            <ErrorMessage
                                                                                name={getFieldName(
                                                                                    rubricIndex,
                                                                                    'descriptor',
                                                                                    rubricFieldName
                                                                                )}
                                                                            />
                                                                        </span>
                                                                    </div>
                                                                </div>
                                                                <div className={`ml-2 w-16 ${disabledStyle}`}>
                                                                    <Field
                                                                        name={getFieldName(rubricIndex, 'value', rubricFieldName)}
                                                                        type="number"
                                                                        placeholder="Pts"
                                                                        min="0"
                                                                        disabled={isPublished || isLoading}
                                                                        className={`w-full px-2 py-1 text-black box-border appearance-none ${disabledStyle} border border-gray-300 rounded-md focus:border-black focus:ring-0 ${isLoading ? 'opacity-50 cursor-not-allowed' : ''}`}
                                                                        onBlur={submitForm}
                                                                    />
                                                                    <span className="text-red-800 text-xs">
                                                                        <ErrorMessage
                                                                            name={getFieldName(rubricIndex, 'value', rubricFieldName)}
                                                                        />
                                                                    </span>
                                                                </div>
                                                            </div>
                                                        ))}
                                                        <div className={`flex items-center mt-4 ml-8 ${disabledStyle}`}>
                                                            <button
                                                                disabled={isPublished}
                                                                onClick={() => {
                                                                    push({ descriptor: '', value: '' });
                                                                }}
                                                            >
                                                                <div
                                                                    className={`size-4 flex items-center justify-center ${disabledStyle}`}
                                                                >
                                                                    <img
                                                                        src="/assets/add-circle.png"
                                                                        className="size-4"
                                                                        alt="Add rubric item"
                                                                    />
                                                                </div>
                                                            </button>
                                                            <div className={`ml-5 text-sm ${disabledStyle}`}>Add Rubric
                                                                Item
                                                            </div>
                                                        </div>
                                                    </div>
                                                );
                                            }}
                                        </FieldArray>
                                    </div>
                                ) : (
                                    <div className="flex flex-col">
                                        <div className="text-sm font-medium mb-2">Question Weight</div>
                                        <div
                                            className={`input-entry-box font-normal px-2 w-16 relative ${disabledStyle}`}>
                                            <Field
                                                name={getFieldName(index, 'weight', fieldName)}
                                                type="number"
                                                disabled={isPublished}
                                                min="1"
                                                max="99"
                                                className={`actual-input text-black w-12 ${disabledStyle}`} // Set width here
                                                onBlur={submitForm}
                                            />
                                            <span className="text-red-800 text-xs absolute bottom-[-20px] left-0 w-max">
                                                <ErrorMessage name={getFieldName(index, 'weight', fieldName)} />
                                            </span>
                                        </div>
                                        <div className="text-sm font-medium mt-4 mb-2">Multiple Choice Options</div>
                                        <FieldArray name={getFieldName(index, 'mcqOptions', fieldName)}>
                                            {({ remove, push }) => {
                                                const optionFieldName = getFieldName(index, 'mcqOptions', fieldName);
                                                return (
                                                    <div>
                                                        {subQuestion.mcqOptions.map((option, optionIndex) => (
                                                            <div key={optionIndex}
                                                                className="flex items-center mb-8 relative">
                                                                <div
                                                                    className="mr-2">{String.fromCharCode(65 + optionIndex)}</div>
                                                                <Field
                                                                    name={getFieldName(optionIndex, 'text', optionFieldName)}
                                                                    type="text"
                                                                    placeholder={`Option ${String.fromCharCode(65 + optionIndex)}`}
                                                                    disabled={isPublished}
                                                                    className={`sexy-border rounded px-1 py-0.5 mr-2 w-40 ${disabledStyle}`} // Adjust width here
                                                                    onBlur={submitForm}
                                                                />
                                                                <span
                                                                    className="text-red-800 text-xs absolute bottom-[-35px]">
                                                                    <ErrorMessage name={getFieldName(optionIndex, 'text', optionFieldName)} />
                                                                </span>
                                                                <div className="relative inline-block mr-2">
                                                                    <Field
                                                                        name={getFieldName(optionIndex, 'isCorrect', optionFieldName)}
                                                                        id={`checkbox-${question.id}-${subQuestion.id}-${optionIndex}`}
                                                                        type="checkbox"
                                                                        disabled={isPublished}
                                                                        className="sr-only peer"
                                                                        onChange={(e) => {
                                                                            const isChecked = e.target.checked;
                                                                            subQuestion.mcqOptions.forEach((_, idx) => {
                                                                                setFieldValue(getFieldName(idx, 'isCorrect', optionFieldName), false);
                                                                            });
                                                                            if (isChecked) {
                                                                                setFieldValue(getFieldName(optionIndex, 'isCorrect', optionFieldName), true);
                                                                            }
                                                                            submitForm();
                                                                        }}
                                                                    />
                                                                    <label
                                                                        htmlFor={`checkbox-${question.id}-${subQuestion.id}-${optionIndex}`}
                                                                        className={`${isPublished
                                                                            ? 'cursor-not-allowed opacity-50'
                                                                            : 'cursor-pointer'
                                                                            } w-5 h-5 bg-white border border-gray-300 rounded-3xl peer-checked:bg-orange-200 peer-checked:border-orange-300 flex items-center justify-center transition-colors duration-200 ease-in-out`}
                                                                    ></label>
                                                                    <span className="text-red-800 text-xs">
                                                                        <ErrorMessage name={getFieldName(optionIndex, 'isCorrect', optionFieldName)} />
                                                                    </span>
                                                                </div>
                                                                <button
                                                                    type="button"
                                                                    disabled={isPublished}
                                                                    onClick={() => {
                                                                        const updatedOptions = subQuestion.mcqOptions.filter((_, i) => i !== optionIndex);
                                                                        const renamedOptions = updatedOptions.map((opt, idx) => ({
                                                                            ...opt,
                                                                            option: String.fromCharCode(65 + idx)
                                                                        }));
                                                                        setFieldValue(optionFieldName, renamedOptions);
                                                                        submitForm();
                                                                    }}
                                                                >
                                                                    <img src="/assets/trash.png" className="size-4"
                                                                        alt="Delete option" />
                                                                </button>
                                                            </div>
                                                        ))}
                                                        <button
                                                            type="button"
                                                            disabled={isPublished}
                                                            className={`less-sexy-button w-40 h-9 mt-2 ${disabledStyle}`}
                                                            onClick={() => {
                                                                push({
                                                                    option: String.fromCharCode(65 + subQuestion.mcqOptions.length),
                                                                    text: '',
                                                                    isCorrect: subQuestion.mcqOptions.length === 0
                                                                });
                                                            }}
                                                        >
                                                            Add Option
                                                        </button>
                                                    </div>
                                                );
                                            }}
                                        </FieldArray>
                                    </div>
                                )}
                            </div>
                        </div>

                    </div>
                    {subQuestion.questionType === 'freeform' && (
                        <div className="w-full mt-4">
                            <div className="mb-2 text-sm font-medium ${disabledStyle}">
                                Solution (and Grading Instructions)
                            </div>
                            <Field
                                name={getFieldName(index, 'solution', fieldName)}
                                as="textarea"
                                placeholder="Solution"
                                disabled={isPublished}
                                className={`sexy-border rounded-lg mb-4 focus:ring-0 focus:sexy-border focus:rounded-lg w-full h-36 ${disabledStyle}`}
                                onBlur={submitForm}
                            />
                            <span className="text-red-800 text-xs">
                                <ErrorMessage name={getFieldName(index, 'solution', fieldName)} />
                            </span>
                        </div>
                    )}
                </>
            )}
        </div>
    );
};

