import {Info} from 'lucide-react';
import React, {useEffect, useState} from 'react';
import {gradeApi} from '../../api.js';
import {useAuth} from '../../auth/AuthContext';
import ConfirmationModal from '../ConfirmationModal';
import DragAndDropUpload from '../DragDropCSV';

const AddDropBig = props => {
    const [students, setStudents] = useState([]);
    const {user} = useAuth();
    const [studentEmail, setStudentEmail] = useState('');
    const [error, setError] = useState('');
    const [isConfirmationModalOpen, setIsConfirmationModalOpen] = useState(false);
    const [selectedStudentId, setSelectedStudentId] = useState(null);
    const [csv, setCsv] = useState(null);
    const [emailFocused, setEmailFocused] = useState(false);
    const [isUploading, setIsUploading] = useState(false);
    const [isRosterImportOpen, setIsRosterImportOpen] = useState(false);
    const [hoveredStudent, setHoveredStudent] = useState(null);
    const [isMatchKeyEnabled, setIsMatchKeyEnabled] = useState(false);
    const handleFileChange = file => {
        if (file) {
            setCsv(file);
            setError(''); // Clear any previous errors
        } else {
            setError('No file selected or invalid file type.');
        }
    };

    const addMatchKeyStudents = async (emails, firstNames, lastNames, matchKeys) => {
        const addStudentPromises = emails.map(async (email, index) => {
            try {
                await gradeApi.post({
                    path: `/api/courses/${props.class_id}/matchkey-add/`,
                    body: {
                        email: email.trim(),
                        first_name: firstNames[index],
                        last_name: lastNames[index],
                        match_key: matchKeys[index]
                    },
                    config: {
                        headers: {
                            Authorization: `Bearer ${user.session_token}`
                        }
                    }
                });
                return {email, success: true};
            } catch (error) {
                console.error(`Error adding student with email ${email}:`, error);
                return {email, success: false};
            }
        });

        const results = await Promise.all(addStudentPromises);

        const successfulAdds = results.filter(result => result.success);
        const failedAdds = results.filter(result => !result.success);

        if (successfulAdds.length > 0) {
            console.log(`Successfully added ${successfulAdds.length} student(s).`);
            getStudents(); // Refresh the student list
        }

        if (failedAdds.length > 0) {
            setError(
                `Failed to add ${failedAdds.length} student(s): ${failedAdds.map(result => result.email).join(', ')}`
            );
        }
    };

    const handleUpload = async () => {
        if (!csv) {
            setError('Please select a CSV file first.');
            return;
        }

        setIsUploading(true);
        setError('');

        const formData = new FormData();
        formData.append('file', csv);

        try {
            if (!isMatchKeyEnabled) {
                const response = await gradeApi.post({
                    path: '/api/upload-canvas-csv/',
                    body: formData,
                    config: {
                        headers: {
                            'Content-Type': 'multipart/form-data'
                        }
                    }
                });

                const emailList = response.data.emails;

                await addStudents(emailList);
            } else {
                const response = await gradeApi.post({
                    path: '/api/upload-matchkey-csv/',
                    body: formData,
                    config: {
                        headers: {
                            'Content-Type': 'multipart/form-data'
                        }
                    }
                });


                const {emails, first_names, last_names, match_keys} = response.data;
                await addMatchKeyStudents(emails, first_names, last_names, match_keys);
            }
        } catch (error) {
            console.error('Error uploading file:', error);
            setError('Failed to process the CSV file. Please try again.');
        } finally {
            setIsUploading(false);
        }
    };


    const addStudents = async emails => {
        const addStudentPromises = emails.map(async email => {
            try {
                await gradeApi.post({
                    path: `/api/courses/${props.class_id}/add/`,
                    body: {email: email.trim()},
                    config: {
                        headers: {
                            Authorization: `Bearer ${user.session_token}`
                        }
                    }
                });
                return {email, success: true};
            } catch (error) {
                console.error(`Error adding student with email ${email}:`, error);
                return {email, success: false};
            }
        });

        const results = await Promise.all(addStudentPromises);

        const successfulAdds = results.filter(result => result.success);
        const failedAdds = results.filter(result => !result.success);

        if (successfulAdds.length > 0) {
            console.log(`Successfully added ${successfulAdds.length} student(s).`);
            getStudents(); // Refresh the student list
        }

        if (failedAdds.length > 0) {
            setError(
                `Failed to add ${failedAdds.length} student(s): ${failedAdds.map(result => result.email).join(', ')}`
            );
        }
    };

    const getStudents = async () => {
        try {
            const response = await gradeApi.get({
                path: `/api/courses/${props.class_id}/students/`,
                config: {
                    headers: {
                        Authorization: `Bearer ${user.session_token}`
                    }
                }
            });
            const users = response.data.users;
            const userInfo = users.map(user => ({
                name: user.first_name + ' ' + user.last_name,
                id: user.id,
                match_key: user.match_key,
                is_null_user: user.is_null_user,
                email: user.email
            }));
            setStudents(userInfo);
        } catch (error) {
            console.error('Error fetching students:', error);
            setError('Failed to fetch students. Please try again.');
        }
    };

    const truncateEmail = email => {
        if (email.length > 20) {
            return email.slice(0, 20) + '...';
        }
        return email;
    };

    const handleRemove = async id => {
        setSelectedStudentId(id);
        setIsConfirmationModalOpen(true);
    };

    const confirmRemoveStudent = async () => {
        try {
            await gradeApi.post({
                path: `/api/courses/${props.class_id}/remove/`,
                body: {
                    user_id: selectedStudentId,
                    type: 'student'
                },
                config: {
                    headers: {
                        Authorization: `Bearer ${user.session_token}`
                    }
                }
            });
            const updatedStudents = students.filter(student => student.id !== selectedStudentId);
            setStudents(updatedStudents);
            setIsConfirmationModalOpen(false);
        } catch (error) {
            console.error('Error removing student:', error);
            setError('Failed to remove student. Please try again.');
        }
    };

    const handleSubmit = async e => {
        e.preventDefault();
        if (studentEmail.trim() === '') {
            setError('Please enter at least one email address.');
            return;
        }

        const emailArray = studentEmail
            .split(',')
            .map(email => email.trim())
            .filter(email => email !== '');
        await addStudents(emailArray);
        setStudentEmail(''); // Clear the input field
    };

    useEffect(() => {
        getStudents();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const toggleRosterImport = () => {
        setIsRosterImportOpen(!isRosterImportOpen);
    };

    return (
        <div className="add-drop-container h-screen flex flex-col">
            <div className="sticky top-0 z-10 p-6 pb-2">
                <form className="mb-4" onSubmit={handleSubmit}>
                    <div
                        className={`flex items-center input-entry-box bg-white font-normal ${error !== '' ? 'border-red-500' : ''}`}
                        onFocus={() => {
                            setEmailFocused(true);
                            setError('');
                        }}
                        onBlur={() => setEmailFocused(false)}
                    >
                        <div className="size-9 mr-0.5 flex items-center justify-center">
                            <img
                                src={error === '' ? '/assets/authentication/email.png' : '/assets/authentication/red-email.png'}
                                className={emailFocused || error !== '' ? 'size-5' : 'size-5 opacity-30'}
                                alt={error === "" ? "Email Icon" : "Problem with Email"}
                            />
                        </div>
                        <input
                            type="text"
                            value={studentEmail}
                            className={`actual-input mr-2.5 ${error !== '' ? 'text-red-500' : ''}`}
                            placeholder="Student Email(s)"
                            onChange={e => setStudentEmail(e.target.value)}
                        />
                    </div>

                    {/* CSV Upload Section - Always Visible */}
                    <div className="mt-4 space-y-4">
                        <div className="flex items-center text-xs text-gray-600">
                            <span>Instructions</span>
                            <a
                                href="https://athelp.sfsu.edu/hc/en-us/articles/14014800586387-Download-a-list-of-student-email-addresses-in-Canvas"
                                target="_blank"
                                rel="noopener noreferrer"
                                className="ml-1">
                                <Info size={14}/>
                            </a>
                        </div>
                        <DragAndDropUpload onFileChange={handleFileChange}/>
                        <div className="flex items-center justify-between">
                            <div className="flex items-center space-x-2">
                                <label className="relative inline-flex items-center cursor-pointer">
                                    <input
                                        type="checkbox"
                                        className="sr-only peer"
                                        checked={isMatchKeyEnabled}
                                        onChange={() => setIsMatchKeyEnabled(!isMatchKeyEnabled)}
                                    />
                                    <div className={`
                                        relative w-11 h-6 bg-gray-200 rounded-full peer 
                                        peer-checked:bg-orange-200 
                                        peer-focus:ring-orange-300 
                                        after:content-[''] 
                                        after:absolute 
                                        after:top-0.5 
                                        after:left-[2px] 
                                        after:bg-white 
                                        after:border-gray-300 
                                        after:border 
                                        after:rounded-full 
                                        after:h-5 
                                        after:w-5 
                                        after:transition-all 
                                        peer-checked:after:translate-x-full
                                    `}></div>
                                </label>
                                <span className="text-sm text-gray-700">Match Key Upload</span>
                            </div>
                            <div className="flex space-x-4">
                                <button
                                    disabled={isUploading}
                                    className={`px-9 py-2 rounded-3xl border-[1px] border-black text-smallish font-medium transition-all duration-300 desktop:hover:-translate-y-[0.5px] desktop:hover:shadow bg-orange-200 hover:bg-orange-300 text-black ${isUploading ? 'opacity-50 cursor-not-allowed' : ''}`}
                                    type="button"
                                    onClick={handleUpload}
                                >
                                    {isUploading ? 'Uploading...' : 'Upload CSV'}
                                </button>
                                <button
                                    type="submit"
                                    className="sexy-button transition-all duration-300 desktop:hover:-translate-y-0.5 desktop:hover:shadow h-9 w-auto px-6 py-2 flex items-center justify-center whitespace-nowrap"
                                >
                                    Add Students
                                </button>
                            </div>
                        </div>
                    </div>
                </form>

                {error && <p className="text-red-500 mb-4">{error}</p>}
            </div>

            {/* New Table View */}
            <div className="flex-1 px-6 overflow-hidden">
                <div className="bg-white rounded-lg shadow h-full">
                    <div className="overflow-auto h-full">
                        <table className="min-w-full divide-y divide-gray-200 mb-20">
                            <thead className="bg-gray-50">
                            <tr>
                                <th scope="col"
                                    className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                                    Name
                                </th>
                                <th scope="col"
                                    className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                                    Email
                                </th>
                                <th scope="col"
                                    className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                                    Match Key
                                </th>
                                <th scope="col"
                                    className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                                    Status
                                </th>
                                <th scope="col"
                                    className="px-6 py-3 text-right text-xs font-medium text-gray-500 uppercase tracking-wider">
                                    Actions
                                </th>
                            </tr>
                            </thead>
                            <tbody className="bg-white divide-y divide-gray-200">
                            {students.map((student) => (
                                <tr key={student.id} className="hover:bg-gray-50">
                                    <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-900">
                                        {student.name ? student.name : student.email}
                                    </td>
                                    <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-900">
                                        {student.email}
                                    </td>
                                    <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                                        {student.match_key}
                                    </td>
                                    <td className="px-6 py-4 whitespace-nowrap">
                                        {student.is_null_user ? (
                                            <span
                                                className="px-2 inline-flex text-xs leading-5 font-semibold rounded-full bg-yellow-100 text-yellow-800">
                                                No account
                                            </span>
                                        ) : (
                                            <span
                                                className="px-2 inline-flex text-xs leading-5 font-semibold rounded-full bg-green-100 text-green-800">
                                                Account created
                                            </span>
                                        )}
                                    </td>
                                    <td className="px-6 py-4 whitespace-nowrap text-right text-sm font-medium">
                                        <button
                                            className="text-red-600 hover:text-red-900 focus:outline-none"
                                            onClick={() => handleRemove(student.id)}
                                        >
                                            <img
                                                src="/assets/trash.png"
                                                alt="Remove"
                                                className="w-5 h-5 z-5 opacity-70 hover:opacity-100 inline"
                                            />
                                        </button>
                                    </td>
                                </tr>
                            ))}
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>

            <ConfirmationModal
                isOpen={isConfirmationModalOpen}
                message="Are you sure you want to remove this student? This action cannot be undone."
                onClose={() => setIsConfirmationModalOpen(false)}
                onConfirm={confirmRemoveStudent}
            />
        </div>
    );
};

export default AddDropBig;