import Cookies from 'js-cookie';
import { isEmpty } from 'lodash';
import React from 'react';
import { Navigate, Outlet } from 'react-router-dom';
import { Loading } from '../components/Loading';
import { useAuth } from './AuthContext';

const ProtectedRoute = () => {
  const { user, isLoading } = useAuth();
  const sessionToken = Cookies.get('session_token');
  const userId = Cookies.get('user_id');

  return sessionToken && userId ? (
    isEmpty(user) || isLoading ? (
      <Loading />
    ) : (
      <Outlet />
    )
  ) : (
    <Navigate to="/login" />
  );
};

export default ProtectedRoute;
