// RichTextField.js
import React, {useState} from 'react';
import {ChevronLeft} from 'lucide-react';

const RichTextField = ({field, form, ...props}) => {
    const [isFormatPanelOpen, setIsFormatPanelOpen] = useState(false);

    const formatButtons = [
        {label: <strong>B</strong>, latex: '\\textbf{ ', closing: ' }'},
        {label: <em>I</em>, latex: '\\textit{', closing: ' }'},
        {label: <u>U</u>, latex: '\\underline{ ', closing: ' }'},
        {label: 'x₁', latex: '$_{ ', closing: ' }$'},
        {label: '\\n', latex: '\\\\ ', closing: ''},
        {label: 'frac', latex: '$\\frac{ ', closing: ' }{  }$'},
        {label: '√', latex: '$\\sqrt{ ', closing: ' }$'},
        {label: '∫', latex: '$\\int_{ ', closing: ' }^{  }$'},
        {label: '→', latex: '$\\rightarrow$ ', closing: ''},
    ];

    const insertFormatting = (latex, closing) => {
        const textarea = document.getElementsByName(field.name)[0];
        const start = textarea.selectionStart;
        const end = textarea.selectionEnd;
        const text = field.value;

        const newText = text.substring(0, start) + latex + text.substring(start, end) + closing + text.substring(end);
        form.setFieldValue(field.name, newText);

        setTimeout(() => {
            textarea.focus();
            textarea.setSelectionRange(start + latex.length, start + latex.length);
        }, 0);
    };

    return (
        <div className="relative">
            <textarea
                {...field}
                {...props}
                className={props.className}
            />
            <div
                className={`absolute bottom-2 right-2 flex items-center bg-white rounded-md shadow-sm border overflow-hidden transition-all duration-500 ${
                    isFormatPanelOpen ? 'w-auto' : 'w-8'
                }`}
            >
                {/* Toggle button */}
                <button
                    type="button"
                    className="w-8 h-8 flex items-center justify-center hover:bg-gray-100 rounded text-sm shrink-0"
                    onClick={() => setIsFormatPanelOpen(!isFormatPanelOpen)}
                >
                    <ChevronLeft className={`w-4 h-4 stroke-[1.5] transform transition-transform duration-500 ${
                        isFormatPanelOpen ? 'rotate-180' : ''
                    }`}/>
                </button>

                {/* Format buttons container with transition */}
                <div className={`flex gap-1 p-1 transition-all duration-500 ${
                    isFormatPanelOpen
                        ? 'opacity-100 translate-x-0'
                        : 'opacity-0 -translate-x-full absolute'
                }`}>
                    {formatButtons.map((button, index) => (
                        <button
                            key={index}
                            type="button"
                            className="w-8 h-8 flex items-center justify-center hover:bg-gray-100 rounded text-sm"
                            onClick={() => insertFormatting(button.latex, button.closing)}
                        >
                            {button.label}
                        </button>
                    ))}
                </div>
            </div>
        </div>
    );
};

export default RichTextField;