import React, {useCallback, useEffect, useRef, useState} from 'react';
import {useParams} from 'react-router-dom';
import {gradeApi} from '../../api.js';
import {useAuth} from '../../auth/AuthContext';

import BigStepper from '../../components/Stepper.js';
import BigStepperLine from '../../components/StepperLine.js';
import AnswerUpload from '../../components/Teacher/AnswerUpload';
import ExamUpload from '../../components/Teacher/ExamUpload';
import RenderBatch from '../../components/Teacher/RenderBatch';
import TConstructor from '../../components/Teacher/TConstructor';
import TGrade from '../../components/Teacher/TGrade';
import TopBar from '../../components/TopBar';
import ParsePDF from '../../components/Teacher/TParsePDF'; // New import for PdfUpload component
import TQuestionGrade from '../../components/Teacher/TQuestionGrade.js';
import SideBar from '../../components/SideBar';


const HomePage = () => {
    const [essayCriteria, setEssayCriteria] = useState([]);
    const [essayInstructions, setEssayInstructions] = useState('');
    const [showRubric, setShowRubric] = useState(false);
    const [assignment, setAssignment] = useState();
    const [submissions, setSubmissions] = useState();
    const [updateTrigger, setUpdateTrigger] = useState(false);
    const [pullQuestions, setPullQuestions] = useState(false);
    const [solRecent, setSolRecent] = useState(false);
    const [essayGradingInstructions, setEssayGradingInstructions] = useState('');
    const [edited, setEdited] = useState(false);
    const [topErrorMessage, setTopErrorMessage] = useState('');
    const [reviewMode, setReviewMode] = useState("Student");

    const {user} = useAuth();
    let {assignment_id} = useParams();
    const [section, setSection] = useState("create");
    const tGradeRef = useRef(null);
    const [showScroll, setShowScroll] = useState(false);

    const scrollToTGrade = () => {
        tGradeRef.current?.scrollIntoView({behavior: 'smooth'});
    };

    const handleIntersection = useCallback(entries => {
        const entry = entries[0];
        if (entry.isIntersecting) {
            setShowScroll(false);
        } else {
            setShowScroll(true);
        }
    }, []);

    useEffect(() => {
        // Only set up the observer if this is an essay assignment
        if (!assignment?.is_essay) {
            return
        }
        ;

        const observer = new IntersectionObserver(handleIntersection, {
            threshold: 0.1
        });

        const currentRef = tGradeRef.current;

        if (!currentRef) {
            const mutationObserver = new MutationObserver(mutations => {
                if (tGradeRef.current) {
                    observer.observe(tGradeRef.current);
                    mutationObserver.disconnect();
                }
            });

            mutationObserver.observe(document.body, {
                childList: true,
                subtree: true
            });

            return () => {
                mutationObserver.disconnect();
                observer.disconnect();
            };
        } else {
            observer.observe(currentRef);
        }

        return () => {
            if (currentRef) {
                observer.unobserve(currentRef);
            }
            observer.disconnect();
        };
    }, [handleIntersection, assignment?.is_essay]);


    const handleSetReviewMode = (mode) => {
        setReviewMode(mode);
        console.log("Review mode set to:", mode);
    };

    const getSubmissions = async () => {
        try {
            const response = await gradeApi.get({
                path: `/api/assignment/${assignment_id}/submissions/`,
                config: {
                    headers: {
                        Authorization: `Bearer ${user.session_token}`
                    }
                }
            });
            setAssignment(response.data.assignment);
            setSubmissions(response.data.submissions);
            if (response.data.assignment.is_assignment_published && !response.data.assignment.is_essay) {
                setSection("review");
            }
            // console.log(response.data.submissions);
            // console.log(response.data.assignment);
        } catch (error) {
            console.log('Failed to fetch submissions. Please try again.');
        }
    };
    useEffect(() => {
        getSubmissions();
    }, [updateTrigger]);


    return assignment ? (
        assignment.is_essay ? (
            // Original essay layout with sidebar
            <div className="HomePageHeader">
                <div id="Main-container" className="flex flex-row">
                    <div id="Left-Sidebar" className="fixed">
                        <SideBar activePage="Home"/>
                    </div>
                    <div id="Right-content" className="flex ml-side-bar-size pr-[30px] w-full">
                        <TopBar
                            assignment_in={assignment}
                            submissions_in={submissions}
                            setUpdateTrigger={setUpdateTrigger}
                            essayCriteria={essayCriteria}
                            essayInstructions={essayInstructions}
                            setPullQuestions={setPullQuestions}
                            edited={edited}
                            essayGradingInstructions={essayGradingInstructions}
                            showRubric={showRubric}
                            topErrorMessage={topErrorMessage}
                            setTopErrorMessage={setTopErrorMessage}
                        />
                        <div className={`flex flex-col mb-32 w-full ml-[30px] ${topErrorMessage ? 'mt-44' : 'mt-36'}`}>
                            <div className="grid grid-cols-[84px_auto]">
                                <BigStepper stepNumber={0} top={5}/>
                                <ParsePDF
                                    assignment_id={assignment_id}
                                    setUpdateTrigger={setUpdateTrigger}
                                    isPublished={assignment.is_assignment_published}
                                    setPullQuestions={setPullQuestions}
                                    setEdited={setEdited}
                                    setSolRecent={setSolRecent}
                                    isEssay={assignment.is_essay}
                                />
                            </div>
                            <BigStepperLine/>
                            <div className="grid grid-cols-[84px_auto]">
                                <BigStepper stepNumber={1} top={5}/>
                                <TConstructor
                                    assignment_id={assignment_id}
                                    updateTrigger={updateTrigger}
                                    setUpdateTrigger={setUpdateTrigger}
                                    pullQuestions={pullQuestions}
                                    setPullQuestions={setPullQuestions}
                                    is_essay={assignment.is_essay}
                                    is_published={assignment.is_assignment_published}
                                    is_exam={assignment.is_exam}
                                    criteria={essayCriteria}
                                    setCriteria={setEssayCriteria}
                                    instructions={essayInstructions}
                                    setInstructions={setEssayInstructions}
                                    setEdited={setEdited}
                                    edited={edited}
                                    showRubric={showRubric}
                                    setShowRubric={setShowRubric}
                                    setSolRecent={setSolRecent}
                                    solRecent={solRecent}
                                    essayGradingInstructions={essayGradingInstructions}
                                    setEssayGradingInstructions={setEssayGradingInstructions}
                                />
                            </div>
                            <BigStepperLine/>
                            <div ref={tGradeRef} className="grid grid-cols-[84px_auto]">
                                <BigStepper stepNumber={2} top={0} ht={'[50px]'}/>
                                <TGrade
                                    assignment_in={assignment}
                                    submissions_in={submissions}
                                    setUpdateTrigger={setUpdateTrigger}
                                    reviewMode="Student"
                                    setReviewMode={handleSetReviewMode}
                                />
                            </div>
                        </div>
                        {showScroll && (
                            <button
                                className="fixed h-[40px] w-[40px] right-[3%] bottom-[5%] z-40
                                rounded-full border-[1px] border-black bg-white"
                                onClick={scrollToTGrade}
                            >
                                <i className="fas fa-chevron-down m-auto"/>
                            </button>
                        )}
                    </div>
                </div>
            </div>
        ) : (
            <>
                {section === "create" ? (
                    <div className="HomePageHeader">
                        <div id="Main-container" className="flex flex-row">
                            <div id="Right-content" className="flex w-full">
                                <TopBar
                                    assignment_in={assignment}
                                    submissions_in={submissions}
                                    setUpdateTrigger={setUpdateTrigger}
                                    essayCriteria={essayCriteria}
                                    essayInstructions={essayInstructions}
                                    setPullQuestions={setPullQuestions}
                                    edited={edited}
                                    essayGradingInstructions={essayGradingInstructions}
                                    showRubric={showRubric}
                                    topErrorMessage={topErrorMessage}
                                    setTopErrorMessage={setTopErrorMessage}
                                    section={section}
                                    setSection={setSection}
                                />
                                <div
                                    className={`flex flex-col mb-32 w-[55%] ml-[15px] mr-0 ${topErrorMessage ? 'mt-44' : 'mt-36'}`}>
                                    <>
                                        <div className="grid grid-cols-[84px_auto]">
                                            <BigStepper stepNumber={0} top={5}/>
                                            <ParsePDF
                                                assignment_id={assignment_id}
                                                setUpdateTrigger={setUpdateTrigger}
                                                isPublished={assignment.is_assignment_published}
                                                setPullQuestions={setPullQuestions}
                                                setEdited={setEdited}
                                                setSolRecent={setSolRecent}
                                                isEssay={assignment.is_essay}
                                            />
                                        </div>
                                        <BigStepperLine/>
                                    </>
                                    <div className="grid grid-cols-[84px_auto]">
                                        <BigStepper stepNumber={1} top={5}/>
                                        <TConstructor
                                            assignment_id={assignment_id}
                                            updateTrigger={updateTrigger}
                                            setUpdateTrigger={setUpdateTrigger}
                                            pullQuestions={pullQuestions}
                                            setPullQuestions={setPullQuestions}
                                            is_essay={assignment.is_essay}
                                            is_published={assignment.is_assignment_published}
                                            is_exam={assignment.is_exam}
                                            criteria={essayCriteria}
                                            setCriteria={setEssayCriteria}
                                            instructions={essayInstructions}
                                            setInstructions={setEssayInstructions}
                                            setEdited={setEdited}
                                            edited={edited}
                                            showRubric={showRubric}
                                            setShowRubric={setShowRubric}
                                            setSolRecent={setSolRecent}
                                            solRecent={solRecent}
                                            essayGradingInstructions={essayGradingInstructions}
                                            setEssayGradingInstructions={setEssayGradingInstructions}
                                        />
                                    </div>
                                    <BigStepperLine/>
                                    {!assignment.is_essay && (
                                        <div className="grid grid-cols-[84px_auto] gap-4">
                                            <BigStepper stepNumber={2} top={0}/>
                                            <AnswerUpload
                                                assignment_id={assignment_id}
                                                setUpdateTrigger={setUpdateTrigger}
                                                isPublished={assignment.is_assignment_published}
                                                setPullQuestions={setPullQuestions}
                                                setEdited={setEdited}
                                                setSolRecent={setSolRecent}
                                            />
                                        </div>
                                    )}
                                </div>
                            </div>
                        </div>
                    </div>
                ) : (
                    <div className="HomePageHeader">
                        <div id="Main-container" className="flex flex-row">
                            <div id="Left-Sidebar" className="fixed">
                                <SideBar activePage="Home"/>
                            </div>
                            <div id="Right-content" className="flex ml-side-bar-size pr-[30px] w-full">
                                <TopBar
                                    assignment_in={assignment}
                                    submissions_in={submissions}
                                    setUpdateTrigger={setUpdateTrigger}
                                    essayCriteria={essayCriteria}
                                    essayInstructions={essayInstructions}
                                    setPullQuestions={setPullQuestions}
                                    edited={edited}
                                    essayGradingInstructions={essayGradingInstructions}
                                    showRubric={showRubric}
                                    topErrorMessage={topErrorMessage}
                                    setTopErrorMessage={setTopErrorMessage}
                                    section={section}
                                    setSection={setSection}
                                />

                                <div
                                    className={`flex flex-col mb-32 w-full ml-[30px] ${topErrorMessage ? 'mt-44' : 'mt-36'}`}>
                                    {assignment.is_exam && (
                                        <>
                                            <div className="grid grid-cols-[84px_auto]">
                                                <BigStepper stepNumber={3} top={0}/>
                                                <RenderBatch assignment_id={assignment_id}
                                                             updateTrigger={updateTrigger}/>
                                            </div>
                                            <BigStepperLine/>
                                            <div className="grid grid-cols-[84px_auto]">
                                                <BigStepper stepNumber={4} top={0}/>
                                                <ExamUpload assignment_id={assignment_id}
                                                            setUpdateTrigger={setUpdateTrigger}/>
                                            </div>
                                            <BigStepperLine/>
                                        </>
                                    )}

                                    <div ref={tGradeRef} className="grid grid-cols-[84px_auto]">
                                        <BigStepper
                                            stepNumber={assignment.is_exam ? 5 : 3}
                                            top={0}
                                            ht={'[50px]'}
                                        />
                                        {reviewMode === "Question" ? (
                                            <TQuestionGrade
                                                assignment_in={assignment}
                                                submissions_in={submissions}
                                                setUpdateTrigger={setUpdateTrigger}
                                                reviewMode={reviewMode}
                                                setReviewMode={handleSetReviewMode}
                                            />
                                        ) : (
                                            <TGrade
                                                assignment_in={assignment}
                                                submissions_in={submissions}
                                                setUpdateTrigger={setUpdateTrigger}
                                                reviewMode={reviewMode}
                                                setReviewMode={handleSetReviewMode}
                                            />
                                        )}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                )}
            </>
        )
    ) : null;

}

export default HomePage;