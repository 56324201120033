// StudentSearchDropdown.jsx
import React, {useState, useEffect, useRef} from 'react';

const StudentSearchDropdown = ({
                                   students,
                                   currentStudentId,
                                   currentStudentName,
                                   onStudentSelect
                               }) => {
    const [isOpen, setIsOpen] = useState(false);
    const [searchTerm, setSearchTerm] = useState('');
    const [filteredStudents, setFilteredStudents] = useState([]);
    const dropdownRef = useRef(null);

    useEffect(() => {
        const handleClickOutside = (event) => {
            if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
                setIsOpen(false);
            }
        };

        document.addEventListener('mousedown', handleClickOutside);
        return () => document.removeEventListener('mousedown', handleClickOutside);
    }, []);

    useEffect(() => {
        if (searchTerm === '') {
            setFilteredStudents(students);
            return;
        }

        const searchTermLower = searchTerm.toLowerCase();
        const filtered = students.filter(student => {
            const fullName = student.name.toLowerCase();
            const id = student.id.toString();
            return fullName.includes(searchTermLower) || id.includes(searchTermLower);
        });
        setFilteredStudents(filtered);
    }, [searchTerm, students]);

    const highlightMatch = (text, search) => {
        if (!search) {
            return text;
        }
        const parts = text.split(new RegExp(`(${search})`, 'gi'));
        return parts.map((part, index) =>
            part.toLowerCase() === search.toLowerCase() ?
                <span key={index} className="bg-orange-200">{part}</span> : part
        );
    };

    return (
        <div ref={dropdownRef} className="relative w-full">
            <div
                className="w-full border border-gray-300 rounded-lg p-3 cursor-pointer flex justify-between items-center
                           bg-white hover:border-gray-400 transition-colors duration-200"
                onClick={() => setIsOpen(!isOpen)}
            >
                <span className="text-gray-700">{currentStudentName || 'Select student'}</span>
                <i className={`fas fa-chevron-${isOpen ? 'up' : 'down'} text-gray-400`}/>
            </div>

            {isOpen && (
                <div
                    className="absolute w-full mt-2 bg-white border border-gray-200 rounded-lg shadow-lg z-50 overflow-hidden">
                    <div className="p-2 border-b border-gray-100">
                        <input
                            type="text"
                            className="w-full p-2 rounded-md border border-gray-200 focus:outline-none
                                     focus:border-orange-200 focus:ring-1 focus:ring-orange-300
                                     placeholder-gray-500 text-gray-700"
                            placeholder="Search by name or ID..."
                            value={searchTerm}
                            autoFocus
                            style={{
                                WebkitTapHighlightColor: 'transparent',
                                WebkitAppearance: 'none',
                                MozAppearance: 'none',
                                appearance: 'none'
                            }}
                            onChange={(e) => setSearchTerm(e.target.value)}
                            onClick={(e) => e.stopPropagation()}
                        />
                    </div>

                    <div className="max-h-[240px] overflow-y-auto">
                        {filteredStudents.map(student => (
                            <div
                                key={student.id}
                                className="px-4 py-3 hover:bg-gray-50 cursor-pointer transition-colors duration-150
                                         border-b border-gray-100 last:border-b-0"
                                onClick={() => {
                                    onStudentSelect(student);
                                    setIsOpen(false);
                                    setSearchTerm('');
                                }}
                            >
                                <div className="flex justify-between items-center">
                                    <span className="text-gray-700 font-medium">
                                        {highlightMatch(student.name, searchTerm)}
                                    </span>
                                    <span className="text-gray-500 text-sm">
                                        ID: {highlightMatch(student.id.toString(), searchTerm)}
                                    </span>
                                </div>
                            </div>
                        ))}
                        {filteredStudents.length === 0 && (
                            <div className="px-4 py-3 text-gray-500 text-center">
                                No matching students found
                            </div>
                        )}
                    </div>
                </div>
            )}
        </div>
    );
};

export default StudentSearchDropdown;