import React from 'react';
import { useNavigate } from 'react-router-dom';

const NotFound = () => {
  const navigate = useNavigate();
  const handleClick = () => {
    navigate('/');
  };

  return (
    <div className="max-w-[800px] mx-auto p-6 mt-4">
      <h1 className="custom-title-sm text-black mb-4">Page Not Found!</h1>
      <button
        className={`px-4 py-3 rounded custom-functional transition-all duration-300 w-full text-black border border-border`}
        onClick={handleClick}
      >
        Back to Main Site
      </button>
    </div>
  );
};

export default NotFound;
