import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { gradeApi } from '../../api.js';
import { useAuth } from '../../auth/AuthContext';
import DueTimeDisplay from '../DueTimeDisplay.js';
import { ViewSelectIcon } from '../ViewSelect';

function SAssignments(props) {
  const [className, setClassName] = useState('');
  const [assignments, setAssignments] = useState([]);
  const [error, setError] = useState('');
  const { user } = useAuth();
  const [cardView, setCardView] = useState(true);

  const getAssignments = async () => {
    try {
      const response = await gradeApi.get({
        path: `/api/courses/${props.class_id}/student-assignments/`,
        config: {
          headers: {
            Authorization: `Bearer ${user.session_token}`
          }
        }
      });
      setAssignments(response.data.assignments);
      setClassName(response.data.class_name);
    } catch (error) {
      setError('Failed to fetch assignments. Please try again.');
      console.error('Error fetching assignments:', error);
    }
  };

  useEffect(() => {
    getAssignments();
  }, []);

  function toggleCardView() {
    setCardView(!cardView);
  }

  const AssignmentCard = ({
    id,
    title,
    due_date,
    due_time,
    timezone,
    submitted,
    is_exam,
    is_essay,
    is_grades_published
  }) => {
    return (
      <div className="flex flex-col justify-between p-4 h-36 bg-white sexy-border relative transition-all duration-300 desktop:hover:-translate-y-0.5 desktop:hover:shadow overflow-hidden">
        <Link to={`/sclasses/${props.class_id}/sassignments/${id}/sdetails`}>
          <div className="text-base font-medium text-black line-clamp-2">{title}</div>
        </Link>
        <div className="flex justify-between items-center mt-1">
          <div className="flex">
            <div className="text-gray-900 text-smallish truncate mr-1">Due:</div>
            <DueTimeDisplay dueTime={due_time} dueDate={due_date} timezone={timezone} />
          </div>
          <div className="text-gray-900 text-smallish truncate">
            {is_essay ? 'Essay' : is_exam ? 'Teacher Submission' : 'Student Submission'}
          </div>
        </div>
        <div className="flex justify-between items-center space-x-4">
          <div className={`font-semibold text-sm ${submitted ? 'text-black' : 'text-orange-400'}`}>
            {submitted ? 'Submitted' : 'Not Submitted'}
          </div>
        </div>
        <div
          className={`absolute bottom-0 right-0 w-8 h-8 rounded-tl-full ${is_grades_published ? 'bg-orange-200' : 'bg-transparent'}`}
        ></div>
      </div>
    );
  };

  const AssignmentList = ({ assignments }) => {
    return (
      <div className="container mx-auto mt-6">
        <div className="grid grid-cols-1 md:grid-cols-2 gap-4 min-w-20">
          {assignments && assignments.length > 0 ? (
            assignments.map(value => (
              <AssignmentCard
                key={value.id}
                id={value.id}
                title={value.title}
                due_date={value.due_date}
                due_time={value.due_time}
                timezone={value.timezone}
                submitted={value.submitted}
                is_exam={value.is_exam}
                is_essay={value.is_essay}
                is_grades_published={value.is_grades_published}
              />
            ))
          ) : (
            <p></p>
          )}
        </div>
      </div>
    );
  };

  const AssignmentTable = ({ assignments }) => {
    return (
      <div className="min-w-full">
        <div className="grid grid-cols-4 gap-4 mb-6 p-2 font-bold border-b border-black">
          <div className="classes-column-header">Assignment</div>
          <div className="classes-column-header">Due</div>
          <div className="classes-column-header">Type</div>
          <div className="classes-column-header">Status</div>
        </div>
        {assignments && assignments.length > 0 ? (
          assignments.map(value => (
            <div
              key={value.id}
              className="hover:border rounded-full py-0.5 px-3 transition-all duration-100 desktop:hover:shadow-sm"
            >
              <div className="grid grid-cols-4 align-middle">
                <div className="classes-table-content">
                  <Link to={`/sclasses/${props.class_id}/sassignments/${value.id}/sdetails`}>
                    {value.title}
                  </Link>
                </div>
                <div className="classes-table-content">
                  <DueTimeDisplay
                    dueTime={value.due_time}
                    dueDate={value.due_date}
                    timezone={value.timezone}
                  />
                </div>
                <div className="classes-table-content">
                  {value.is_essay
                    ? 'Essay'
                    : value.is_exam
                      ? 'Teacher Submission'
                      : 'Student Submission'}
                </div>
                <div className="classes-table-content">
                  {value.submitted ? 'Submitted' : 'Not Submitted'}
                </div>
              </div>
            </div>
          ))
        ) : (
          <div className="text-center">No assignments available</div>
        )}
      </div>
    );
  };

  const changeView = ({ assignments }) => {
    return cardView ? (
      <AssignmentList assignments={assignments} />
    ) : (
      <AssignmentTable assignments={assignments} />
    );
  };

  return (
    <div id="HomeStudent" className="flex flex-row p-8 justify-start">
      <div id="Main-Content-container" className="flex flex-col space-y-4 w-full">
        <div id="Overview" className="flex flex-row space-x-full w-full justify-between">
          <div className="flex flex-row items-center justify-center">
            <div>
              <ViewSelectIcon cardView={cardView} toggleCardView={toggleCardView} />
            </div>
            <Link to="/sclasses">
              <span className="text-black text-xl font-medium">My Classes</span>
            </Link>
            <Link to="/sclasses">
              <div className="size-6 flex items-center justify-center">
                <button>
                  <img src="/assets/back_arrow.png" className="size-6" alt="Back" />
                </button>
              </div>
            </Link>
            <h1 className="text-black text-xl font-medium ml-4">{className}</h1>
          </div>
        </div>
        <div>
          {error && <div className="text-red-500">{error}</div>}
          {changeView({ assignments })}
        </div>
      </div>
    </div>
  );
}

export default SAssignments;
