import React from 'react';

const Modal = ({
    isModalOpen,
    setIsModalOpen,
    handleSubmit,
    assignmentName,
    setAssignmentName,
    format,
    setFormat,
    dueDate,
    setDueDate,
    dueTime,
    timezone,
    setDueTime,
    studentSubmitted,
    setStudentSubmitted,
    errorMessage,
    setErrorMessage,
    dateFocused,
    setDateFocused,
    timeFocused,
    setTimeFocused,
    modalRef,
    setIsGroupSubmission,
    isGroupSubmission
}) => {
    return (
        isModalOpen && (
            <div
                className="fixed inset-0 bg-black bg-opacity-50 overflow-y-auto h-full w-full z-50 flex justify-center items-center"
                id="my-modal"
            >
                <div
                    ref={modalRef}
                    className="sexy-border relative mx-auto p-6 bg-white w-full max-w-[400px]"
                >
                    <div className="space-y-4">
                        <div className="flex flex-row justify-between">
                            <h2 className="custom-title-sm text-black font-bold">
                                Create New Assignment
                            </h2>
                            <button
                                type="button"
                                className="desktop:hover:opacity-50 transition-all duration-300"
                                onClick={() => setIsModalOpen(false)}
                            >
                                <img
                                    src="/assets/X.png"
                                    alt="Exit"
                                    className="w-[20px] h-[20px]"
                                />
                            </button>
                        </div>
                        <form onSubmit={handleSubmit}>
                            <div className="space-y-3">
                                <div className="flex flex-col mb-5">
                                    <div
                                        className={`flex items-center input-entry-box font-normal bg-white ${errorMessage !== "" ? "border-red-500" : ""}`}
                                        onFocus={() => {
                                            setErrorMessage("");
                                        }}>
                                        <input
                                            type="text"
                                            value={assignmentName}
                                            className={`actual-input ml-2.5 mr-2.5 ${errorMessage !== "" ? "text-red-500" : ""}`}
                                            placeholder="Assignment Name"
                                            onChange={(e) => {
                                                setAssignmentName(e.target.value);
                                                if (e.target.value.length > 70) {
                                                    setErrorMessage("Assignment name must not exceed 70 characters.");
                                                } else {
                                                    setErrorMessage("");
                                                }
                                            }}
                                        />
                                    </div>
                                    <div className="flex flex-col mt-3">
                                        <div className="flex items-center input-entry-box font-normal bg-white">
                                            <select
                                                value={format}
                                                className="ml-2.5 actual-input text-black placeholder-black"
                                                onChange={(e) => {
                                                    setFormat(e.target.value);
                                                    if (e.target.value === "essay") {
                                                        setStudentSubmitted("student");
                                                    }
                                                }}
                                            >
                                                <option value="" disabled selected>Select Format</option>
                                                <option value="problem_set">Problem Set</option>
                                                <option value="essay">Essay</option>
                                            </select>
                                        </div>
                                    </div>
                                    {format !== "essay" && (
                                        <div className="flex items-center input-entry-box font-normal bg-white mt-3"
                                            onFocus={() => {
                                                setErrorMessage("");
                                            }}>
                                            <select
                                                value={studentSubmitted}
                                                className="ml-2.5 actual-input text-black placeholder-black"
                                                onChange={(e) => setStudentSubmitted(e.target.value)}
                                            >
                                                <option value="" disabled selected>Define Submission</option>
                                                <option value="student">Student</option>
                                                <option value="teacher">Teacher</option>
                                            </select>
                                        </div>
                                    )}

                                    {studentSubmitted == "student" && (
                                        <div
                                            className={`relative flex items-center mt-3 font-normal bg-white input-entry-box ${errorMessage !== "" ? "border-red-500" : ""}`}
                                            onFocus={() => {
                                                setDateFocused(true);
                                                setErrorMessage("");
                                            }}
                                            onBlur={() => setDateFocused(false)}>
                                            <div
                                                className={`flex items-center w-full  ${errorMessage !== "" ? "border-red-500" : ""}`}>
                                                {!dateFocused && dueDate === "" && (
                                                    <div
                                                        className="absolute ml-2.5 pointer-events-none text-gray-400 bg-white w-36"
                                                        style={{ userSelect: "none" }}
                                                    >
                                                        Due Date
                                                    </div>
                                                )}
                                                <input
                                                    type="date"
                                                    value={dueDate}
                                                    className={`actual-input ml-2.5 mr-2.5 w-full ${errorMessage !== "" ? "text-red-500" : ""}`}
                                                    onChange={(e) => setDueDate(e.target.value)}
                                                    onFocus={() => setErrorMessage("")}
                                                />
                                            </div>
                                            <div
                                                className="size-6 flex items-center justify-center pointer-events-none absolute right-0 bg-white mr-2">
                                                <img
                                                    src={errorMessage === "" ? "/assets/calendar.png" : "/assets/red-calendar.png"}
                                                    className={dateFocused || errorMessage !== "" ? "size-5" : "size-5 opacity-30"}
                                                    alt={errorMessage === "" ? "Assignment Due Date Icon" : "Error with Assignment Due Date"}
                                                />
                                            </div>
                                        </div>)}
                                    {studentSubmitted == "student" && (

                                        <div
                                            className={`relative flex items-center mt-3 font-normal bg-white input-entry-box ${errorMessage !== "" ? "border-red-500" : ""}`}
                                        >
                                            <label className="w-full cursor-pointer">
                                                <div
                                                    className={`flex items-center w-full ${errorMessage !== "" ? "border-red-500" : ""}`}
                                                    onClick={() => {
                                                        setTimeFocused(true);
                                                        setErrorMessage("");
                                                    }}
                                                >
                                                    {!timeFocused && dueTime === "" && (
                                                        <div
                                                            className="absolute ml-2.5 pointer-events-none text-gray-400 bg-white w-36"
                                                            style={{ userSelect: "none" }}
                                                        >
                                                            Due Time
                                                        </div>
                                                    )}
                                                    <input
                                                        type="time"
                                                        value={dueTime}
                                                        className={`actual-input ml-2.5 mr-2.5 w-full ${errorMessage !== "" ? "text-red-500" : ""}`}
                                                        onChange={(e) => setDueTime(e.target.value)}
                                                        onFocus={() => {
                                                            setTimeFocused(true);
                                                            setErrorMessage("");
                                                        }}
                                                        onBlur={() => setTimeFocused(false)}
                                                    />
                                                    <div className="flex items-center absolute right-0 bg-white mr-2">
                                                        <span className="text-sm text-gray-500 mr-2">{timezone}</span>
                                                        <div
                                                            className="size-6 flex items-center justify-center pointer-events-none">
                                                            <img
                                                                src={errorMessage === "" ? "/assets/clock.png" : "/assets/red-clock.png"}
                                                                className={timeFocused || errorMessage !== "" ? "size-5" : "size-5 opacity-30"}
                                                                alt="Clock icon"
                                                            />
                                                        </div>
                                                    </div>
                                                </div>
                                            </label>
                                        </div>)}
                                </div>


                                {(studentSubmitted === "student" || format === "essay") && (
                                    <div className="flex justify-center">
                                        <div className="flex items-center">
                                            <button
                                                role="switch"
                                                aria-checked={isGroupSubmission}
                                                className={`
        relative inline-flex h-6 w-11 items-center rounded-full transition-colors duration-200 ease-in-out mr-2
        ${isGroupSubmission
                                                        ? 'bg-orange-200 border-orange-300'
                                                        : 'bg-gray-200 hover:bg-gray-300'
                                                    }
      `}
                                                onClick={() => setIsGroupSubmission(!isGroupSubmission)}
                                            >
                                                <span
                                                    className={`
          inline-block h-4 w-4 transform rounded-full bg-white transition-transform duration-200 ease-in-out
          ${isGroupSubmission ? 'translate-x-6' : 'translate-x-1'}
        `}
                                                />
                                            </button>
                                            <label className="text-sm font-medium text-black">
                                                Allow Group Submission
                                            </label>
                                        </div>
                                    </div>
                                )}

                                {errorMessage && (
                                    <div className="text-red-500 text-sm my-2">
                                        {errorMessage}
                                    </div>
                                )}
                            </div>
                            <div className="flex justify-end space-x-2 mt-8">
                                <button
                                    type="submit"
                                    disabled={!assignmentName || (!dueDate || !dueTime) && studentSubmitted == "student" || !format || !studentSubmitted}
                                    className={`sexy-button w-full ${assignmentName &&
                                        (studentSubmitted !== "student" || (dueDate && dueTime)) &&
                                        format &&
                                        studentSubmitted
                                        ? ""
                                        : "cursor-not-allowed"
                                        }`}
                                >
                                    Create Assignment
                                </button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        )
    );
};

export default Modal;