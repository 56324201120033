// OddPagesModal.jsx
import React from "react";
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faExclamationTriangle} from '@fortawesome/free-solid-svg-icons';

function OddPagesModal({isOpen, onClose, numPages, onConfirm, onReject}) {
    if (!isOpen) {
        return null;
    }

    return (
        <div
            className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center z-50">
            <div className="relative mx-auto p-6 bg-white w-full max-w-[400px] rounded-2xl shadow-lg">
                <div className="space-y-4">
                    {/* Header */}
                    <div className="flex items-center justify-between">
                        <div className="flex items-center gap-2">
                            <FontAwesomeIcon
                                icon={faExclamationTriangle}
                                className="text-orange-500 text-xl"
                            />
                            <h2 className="text-lg font-semibold">Important Notice</h2>
                        </div>
                        <button
                            className="hover:opacity-70"
                            onClick={onClose}
                        >
                            <img src="/assets/X.png" alt="Close" className="w-4 h-4"/>
                        </button>
                    </div>

                    {/* Content */}
                    <p className="text-gray-700 text-sm">
                        We noticed this assignment has an odd number of pages ({numPages}).
                        Does the PDF you are uploading include trailing blank pages? When you scan double sided,
                        some pages may be left blank. Is every {numPages + 1}th page in your PDF blank?
                    </p>

                    {/* Buttons */}
                    <div className="flex flex-col gap-2">
                        <button
                            className="px-4 py-2 rounded-3xl border-[1px] border-black text-smallish font-medium transition-all duration-300 desktop:hover:-translate-y-[0.5px] desktop:hover:shadow bg-orange-200 hover:bg-orange-300 text-black"
                            onClick={(e) => onConfirm(e)}
                        >
                            Yes, discard every {numPages + 1}th page
                        </button>
                        <button
                            className="mt-2 px-4 py-2 rounded-3xl border-[1px] border-black text-smallish font-medium transition-all duration-300 desktop:hover:-translate-y-[0.5px] desktop:hover:shadow bg-white hover:bg-gray-300 text-black"
                            onClick={(e) => onReject(e)}
                        >
                            No, there are no blank pages in my PDF
                        </button>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default OddPagesModal;