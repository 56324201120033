import React, {useEffect, useState} from 'react';
import {Link} from 'react-router-dom';
import {apiUrl, gradeApi} from '../../api.js';
import {useAuth} from '../../auth/AuthContext';
import DueTimeDisplayTable from '../DueTimeDisplayTable.js';
import TeacherStudentUpload from './TeacherStudentUpload'; // Import the new component

function TGrade(props) {
    const [submissions, setSubmissions] = useState([]);
    const [assignment, setAssignment] = useState();
    const [loading, setLoading] = useState(false);
    const {user} = useAuth();
    const [errorMessage, setErrorMessage] = useState('');
    const [file, setFile] = useState(null);
    const [submitted, setSubmitted] = useState(false);
    const [showUploadModal, setShowUploadModal] = useState(false);
    const [selectedStudentId, setSelectedStudentId] = useState(null);
    const [regrades, setRegrades] = useState([]);


    const getRegrades = async () => {
        try {
            const response = await gradeApi.get({
                path: `/api/regrade-requests/`,
                config: {
                    headers: {
                        Authorization: `Bearer ${user.session_token}`
                    }
                }
            });
            const regradesForTeacher = response.data;
            console.log(regradesForTeacher);
            setRegrades(regradesForTeacher)
        } catch (error) {
            console.log(error);
        }
    };

    const hasRegrade = (submission) => {
        for (var i = 0; i < regrades.length; i++) {
            if (regrades[i].id === submission.id) {
                return true;
            }
        }
        return false;
    }

    useEffect(() => {
        getRegrades()
    }, []);

    useEffect(() => {
        setSubmissions(props.submissions_in);
        setAssignment(props.assignment_in);
    }, [props.submissions_in, props.assignment_in]);

    if (!assignment || !submissions) {
        return <h1>loading...</h1>;
    }

    const handleUploadClick = studentId => {
        setSelectedStudentId(studentId);
        setShowUploadModal(true);
    };

    const handleCloseModal = () => {
        setShowUploadModal(false);
        setSelectedStudentId(null);
    };

    function process_string(input_) {
        // Check if input_ is an array
        if (!Array.isArray(input_)) {
            // console.error('Input is not an array');
            return '';
        }

        // Filter out any null or undefined values
        const filteredInput = input_.filter(item => item != null);

        // Join the array elements with ", " (comma and space)
        return filteredInput.join(', ');
    }

    return (
        <div id="HomeTeacher" className="flex flex-row">
            <div id="Main-Content-container" className="flex flex-col space-y-8 w-full">
                <div className="flex flex-col w-full">
                    <h2 className="text-xl font-bold mb-1">
                        Step {assignment ? (assignment.is_essay ? 2 : assignment.is_exam ? 5 : 3) : 3}: Review
                        grades
                    </h2>
                    <p className="text-sm mb-4">
                        After submission upload, suggested grades will be available within two minutes.
                    </p>
                </div>

                {!assignment.is_essay &&
                    <div className="flex justify-start">
                        <div className="flex items-center bg-grey-700 rounded-full p-2 shadow-md mr-4">
                            <input
                                type="checkbox"
                                id="openStudentCheckBox"
                                checked={props.reviewMode === "Student"}
                                className="sr-only peer"
                                onClick={() => {
                                    props.setReviewMode("Student")
                                }}
                            />
                            <label
                                htmlFor="openStudentCheckBox"
                                className="cursor-pointer w-5 h-5 bg-white border border-gray-300 rounded-3xl peer-checked:bg-orange-200 peer-checked:border-orange-300 flex items-center justify-center transition-colors duration-200 ease-in-out mr-2"
                            ></label>
                            <label htmlFor="openStudentCheckBox" className="text-sm font-medium text-black">
                                View by Student
                            </label>
                        </div>
                        <div className="flex items-center bg-grey-700 rounded-full p-2 shadow-md mr-4">
                            <input
                                type="checkbox"
                                id="openQuestionCheckBox"
                                checked={props.reviewMode === "Question"}
                                className="sr-only peer"
                                onClick={() => {
                                    props.setReviewMode("Question")
                                }}
                            />
                            <label
                                htmlFor="openQuestionCheckBox"
                                className="cursor-pointer w-5 h-5 bg-white border border-gray-300 rounded-3xl peer-checked:bg-orange-200 peer-checked:border-orange-300 flex items-center justify-center transition-colors duration-200 ease-in-out mr-2"
                            ></label>
                            <label htmlFor="openQuestionCheckBox" className="text-sm font-medium text-black">
                                View by Subquestion
                            </label>
                        </div>
                    </div>
                }

                <div className="ml-4bg-white sexy-border p-6">
                    {errorMessage && (
                        <div className="bg-red-100 border border-red-400 text-red-700 px-4 py-3 rounded mb-4">
                            {errorMessage}
                        </div>
                    )}
                    <table className="w-full border-collapse">
                        <thead>
                        <tr className="border-b border-border">
                            <th className="py-4 text-left text-gray custom-16">Student</th>
                            <th className="py-4 text-left text-gray custom-16">Status</th>
                            <th className="py-4 text-left text-gray custom-16">Date Submitted</th>
                            <th className="py-4 text-left text-gray custom-16">Grade</th>
                        </tr>
                        </thead>
                        {submissions && submissions.length > 0 ? (
                            <tbody>
                            {submissions.map(value => (
                                <tr key={value.id} className="border-b border-border">
                                    <td className="text-left text-gray-500 custom-16-semi py-4">
                                        <div className="flex items-center">
                                            {value.is_reviewed && (
                                                <div className="w-2 h-2 bg-orange-400 rounded-full mr-2"></div>
                                            )}

                                            {hasRegrade(value) &&
                                                <div
                                                    className={`size-9 flex items-center justify-center rounded-full transition-all duration-300`}
                                                >
                                                    <img src="/assets/sidebar/quote-request.png" alt="Regrade Request"
                                                         className="size-5"/>
                                                </div>
                                            }

                                            {value.status !== 'Not submitted' && value.status !== 'Grading' ? (
                                                <Link
                                                    to={`/tclasses/${assignment.course_id}/tassignments/${assignment.id}/tsubmissions/${value.id}/tdetails`}
                                                    className="hover:underline text-black relative group"
                                                >
                                                    {value.user.is_verified || value.user.email.endsWith('@placeholder.com')
                                                        ? `${value.user.first_name} ${value.user.last_name}`
                                                        : value.user.email}
                                                    {assignment.is_group_task ? (
                                                        <div
                                                            className="absolute left-full top-1/2 transform -translate-y-1/2 ml-2 bg-gray-800 text-white text-xs rounded py-1 px-2 opacity-0 group-hover:opacity-100 transition-opacity duration-300 whitespace-nowrap max-w-xs overflow-hidden text-ellipsis">
                                                            Group: {process_string(value.team_members)}
                                                        </div>
                                                    ) : (
                                                        <div></div>
                                                    )}
                                                </Link>
                                            ) : (
                                                <span>
                            {value.user.is_verified || value.user.email.endsWith('@placeholder.com')
                                ? `${value.user.first_name} ${value.user.last_name}`
                                : value.user.email}
                                                    <div
                                                        className="absolute left-full top-1/2 transform -translate-y-1/2 ml-2 bg-gray-800 text-white text-xs rounded py-1 px-2 opacity-0 group-hover:opacity-100 transition-opacity duration-300 whitespace-nowrap max-w-xs overflow-hidden text-ellipsis">
                              Group: {process_string(value.team_members)}
                            </div>
                          </span>
                                            )}
                                        </div>
                                    </td>
                                    <td className="text-left text-black custom-16-med py-4">{value.status}</td>
                                    <td className="text-left text-black custom-16-med py-4">
                                        {value.id !== null && value.id !== undefined ? (
                                            <DueTimeDisplayTable
                                                datetimeString={value.submission_date}
                                                timezone={assignment.timezone}
                                            />
                                        ) : (
                                            <span className="text-gray-500">N/A</span>
                                        )}
                                    </td>
                                    <td className="text-left text-black custom-16-med py-4">
                                        {value.grade !== null && value.grade !== undefined ? (
                                            `${value.grade}/${assignment.max_score}`
                                        ) : (
                                            <span className="text-gray-500">N/A</span>
                                        )}
                                    </td>
                                    {!assignment.is_essay &&
                                        !assignment.is_group_task &&
                                        assignment.is_assignment_published && (
                                            <td className="text-left text-black custom-16-med py-4">
                                                <button
                                                    className="px-4 py-2 rounded-3xl border-[1px] border-black text-smallish font-medium transition-all duration-300 desktop:hover:-translate-y-[0.5px] desktop:hover:shadow bg-orange-200 hover:bg-orange-300 text-black"
                                                    onClick={() => handleUploadClick(value.user.id)}
                                                >
                                                    Upload
                                                </button>
                                            </td>
                                        )}
                                </tr>
                            ))}
                            </tbody>
                        ) : (
                            <tbody>
                            <tr>
                                <td colSpan="3" className="text-center py-4 text-gray-500">
                                    No submissions found
                                </td>
                            </tr>
                            </tbody>
                        )}
                    </table>
                    {showUploadModal && (
                        <TeacherStudentUpload
                            assignmentId={assignment.id}
                            studentId={selectedStudentId}
                            setUpdateTrigger={props.setUpdateTrigger}
                            onClose={handleCloseModal}
                        />
                    )}
                </div>
            </div>
        </div>
    );
}

export default TGrade;
