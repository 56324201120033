import React from 'react';
import {useParams} from 'react-router-dom';
import SideBar from '../../components/SideBar';
import AddDrop from '../../components/Teacher/AddDrop';
import TAssignments from '../../components/Teacher/TAssignments';

const HomePage = () => {
    let {class_id} = useParams();

    return (
        <div id="Main-container" className="flex flex-row w-full min-h-screen">
            <div
                id="Left-Sidebar"
                className="fixed left-0 top-0 h-screen w-side-bar-size overflow-y-auto"
            >
                <SideBar activePage="Home"/>
            </div>
            <div className="ml-side-bar-size mr-side-bar-size flex-1">
                <div id="Right-content" className="w-full overflow-y-auto">
                    <TAssignments class_id={class_id}/>
                </div>
            </div>
            <div
                id="Student addition/Deletion"
                className="fixed right-0 top-0 h-screen w-side-bar-size bg-gray-50 overflow-y-auto"
            >
                <AddDrop class_id={class_id}/>
            </div>
        </div>
    );
};

export default HomePage;