import React, { useRef, useState } from 'react';
import { gradeApi } from '../../api.js';
import { useAuth } from '../../auth/AuthContext';
import DragAndDropUpload from '../DragDropAnswer.js';

const AnswerUpload = ({
    assignment_id,
    setUpdateTrigger,
    isPublished,
    setPullQuestions,
    setEdited,
    setSolRecent
}) => {
    const [file, setFile] = useState(null);
    const [submitted, setSubmitted] = useState(false);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);
    const [inputKey, setInputKey] = useState(Date.now());
    const fileInputRef = useRef(null);
    const { user } = useAuth();
    const disabledStyle = isPublished ? '!opacity-50 !cursor-not-allowed pointer-events-none' : '';
    const [isPolling, setIsPolling] = useState(false);
    const [uploadSuccess, setUploadSuccess] = useState(false);

    const sanitizeFilename = filename => {
        return filename.replace(/[^a-zA-Z0-9._-]/g, '');
    };

    const resetForm = () => {
        setFile(null);
        setSubmitted(false);
        setError(null);
        setLoading(false);
        setInputKey(Date.now());
    };

    const handleFileChange = selectedFile => {
        if (selectedFile) {
            const sanitizedName = sanitizeFilename(selectedFile.name);
            const newFile = new File([selectedFile], sanitizedName, { type: selectedFile.type });
            setFile(newFile);
            // console.log('File selected:', newFile);
        } else {
            setFile(null);
        }
    };

    const checkExtractSolutionsStatus = async () => {
        try {
            const response = await gradeApi.get({
                path: `/api/assignment/${assignment_id}/extract-solutions-status/`,
                config: {
                    headers: {
                        Authorization: `Bearer ${user.session_token}`
                    }
                }
            });
            // console.log('Extract solutions status response:', response);

            // The is_extract_solutions_done is nested inside the data object
            const isDone = response.data.is_extract_solutions_done;

            // We don't have is_extract_solutions_in_progress in the response,
            // so we'll assume it's in progress if it's not done
            const isInProgress = !isDone;

            return { isDone, isInProgress };
        } catch (error) {
            console.error('Error checking extract solutions status:', error);
            return { isDone: false, isInProgress: false };
        }
    };

    const startPolling = () => {
        setIsPolling(true);
        const pollInterval = setInterval(async () => {
            const { isDone, isInProgress } = await checkExtractSolutionsStatus();
            if (isDone) {
                clearInterval(pollInterval);
                setIsPolling(false);
                setSubmitted(true);
                setPullQuestions(true);
                setEdited(false);
                setLoading(false);
                // console.log('solutions-extract');
                setUpdateTrigger(prev => !prev);
                setUploadSuccess(true); // Set the new state variable
                resetForm();
            } else if (!isInProgress) {
                clearInterval(pollInterval);
                setIsPolling(false);
                setLoading(false);
                setError('Solution extraction process stopped unexpectedly. Please try again.');
            }
        }, 3000);
    };

    const handleSubmit = async e => {
        e.preventDefault();
        setLoading(true);
        setError(null);
        setSolRecent(true);
        if (!file) {
            setError('Please select a file to upload.');
            setLoading(false);
            return;
        }

        const formData = new FormData();
        formData.append('file_data', file);
        formData.append('user_id', user.user.id);
        formData.append('is_answer_key', true);

        try {
            const response = await gradeApi.post({
                path: `/api/assignment/${assignment_id}/submissions/`,
                body: formData,
                config: {
                    headers: {
                        Authorization: `Bearer ${user.session_token}`,
                        'Content-Type': 'multipart/form-data'
                    }
                }
            });

            if (response.status === 200) {
                const fileUrl = response.data.file.url;

                await gradeApi.get({
                    path: `/api/assignment/${assignment_id}/solutions-extract/`,
                    config: {
                        headers: {
                            Authorization: `Bearer ${user.session_token}`
                        },
                        params: { url: fileUrl }
                    }
                });
                startPolling();
            }
        } catch (error) {
            console.log(error)
            console.log(error.response?.data?.error)
            setError(error.response?.data?.error);
        } finally {
            setLoading(false);
        }
    };

    const handleCancel = () => {
        setIsPolling(false);
        setLoading(false);
        resetForm();
    };

    return (
        <div className={`flex flex-col w-full ${disabledStyle}`}>
            <h2 className="text-xl font-bold mb-1">Step 2 (optional): Upload assignment answer key</h2>
            <p className="text-sm mb-2">
                Download the rendered assignment, fill in solutions and upload it here.
            </p>
            <div className="bg-yellow-50 border-l-4 border-yellow-400 p-4 mb-6">
                <div className="flex">
                    <div className="flex-shrink-0">
                        <img src="/assets/tclasses/warning.png" alt="Warning" className="h-5 w-5 text-yellow-400" />
                    </div>
                    <div className="ml-3">
                        <p className="text-sm text-yellow-700 font-semibold">
                            Uploading a solutions PDF will overwrite all solutions you manually input. Make sure all
                            solutions get extracted correctly.
                        </p>
                    </div>
                </div>
            </div>
            <div>
                <DragAndDropUpload isPublished={isPublished} onFileChange={handleFileChange} />
                <div className="flex items-center space-x-4 mt-4">
                    <button
                        type="submit"
                        disabled={!file || loading || isPublished || isPolling}
                        className={`px-10 py-2 rounded-3xl border border-black text-smallish font-medium transition-all duration-300 desktop:hover:-translate-y-[0.5px] desktop:hover:shadow relative ${loading || isPolling
                            ? 'bg-gray-300 text-gray-500 cursor-not-allowed'
                            : 'sexy-button w-[170px] ${disabledStyle}'
                            }`}
                        onClick={handleSubmit}
                    >
                        {loading || isPolling ? (
                            <>
                                <span className="opacity-0">{submitted ? 'Resubmit' : 'Submit'}</span>
                                <div className="absolute inset-0 flex items-center justify-center">
                                    <div className="animate-spin rounded-full h-6 w-6 border-b-2 border-gray-900"></div>
                                </div>
                            </>
                        ) : submitted ? (
                            'Resubmit'
                        ) : (
                            'Submit'
                        )}
                    </button>
                    {(loading || isPolling) && (
                        <button
                            className="px-6 py-2 rounded-3xl border border-black text-smallish font-medium transition-all duration-300 desktop:hover:-translate-y-[0.5px] desktop:hover:shadow bg-red-200 hover:bg-red-300 text-black"
                            onClick={handleCancel}
                        >
                            Cancel
                        </button>
                    )}
                </div>
            </div>
            {error && <p className="text-red-500 mt-4">{error}</p>}
            {(loading || isPolling) && (
                <p className="text-orange-300 mt-4">Extracting solutions, please stay on the page...</p>
            )}
            {uploadSuccess && !isPolling && (
                <p className="text-orange-300 mt-4 font-semibold">
                    Answer key upload successful. Please make sure the solutions were extracted correctly.
                </p>
            )}
        </div>
    );
};

export default AnswerUpload;
