import { configureStore } from '@reduxjs/toolkit';
import tpsetSlice from './tpsetSlice';
import userSlice from './userSlice';

const store = configureStore({
  reducer: {
    userReducer: userSlice,
    tpsetReducer: tpsetSlice
  },
  middleware: getDefaultMiddleware => getDefaultMiddleware().concat(),
  devTools: process.env.NODE_ENV !== 'production'
});

export default store;
