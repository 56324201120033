import React from 'react';
import SideBar from '../../components/SideBar';
import ADashboard from '../../components/Admin/ADashboard';

const HomePage = () => {
    return (
        <div className="HomePageHeader w-full min-h-screen">
            <div id="Main-container" className="flex flex-row w-full">
                <div
                    id="Left-Sidebar"
                    className="sticky top-0 h-screen overflow-y-auto w-side-bar-size flex-shrink-0"
                >
                    <SideBar activePage="Home"/>
                </div>
                <div id="Right-content" className="flex-grow overflow-x-hidden overflow-y-auto">
                    <ADashboard/>
                </div>
            </div>
        </div>
    );
};

export default HomePage;
