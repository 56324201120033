import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { gradeApi } from '../../api.js';
import { useAuth } from '../../auth/AuthContext';

function SClasses() {
  const [classes, setClasses] = useState([]);
  const [error, setError] = useState('');
  const [isJoinClassVisible, setIsJoinClassVisible] = useState(false);
  const [classCode, setClassCode] = useState('');
  const { user } = useAuth();

  const getClasses = async () => {
    try {
      const response = await gradeApi.get({
        path: `/api/users/${user.user.id}/courses-lite/`,
        config: {
          headers: {
            Authorization: `Bearer ${user.session_token}`
          }
        }
      });
      setClasses(response.data.courses);
    } catch (error) {
      setError('Failed to fetch classes. Please try again.');
      console.error('Error fetching classes:', error);
    }
  };

  useEffect(() => {
    getClasses();
  }, []);

  const handleToggleJoinClass = () => {
    setIsJoinClassVisible(!isJoinClassVisible);
  };

  // #v4p5mw
  const handleJoinClass = async () => {
    try {
      await gradeApi.post({
        path: `/api/courses/join/${user.user.id}/`,
        body: { code: classCode },
        config: {
          headers: {
            Authorization: `Bearer ${user.session_token}`
          }
        }
      });
      setClassCode('');
      setIsJoinClassVisible(false);
      getClasses();
    } catch (error) {
      if (error.response) {
        // console.log(error.response);
        switch (error.response.status) {
          case 503:
            setError('Course not found');
            break;
          case 504:
            setError('This class is not open for enrollment');
            break;
          case 505:
            setError('You are already enrolled in this class');
            break;
          default:
            setError('Failed to join class. Please try again.');
        }
      } else {
        setError('An error occurred. Please try again.');
      }
      console.error('Error joining class:', error);
    }
  };

  const ClassCard = ({ id, name, num_students, instructor }) => {
    return (
      <div className="flex flex-col justify-between p-4 h-32 bg-white sexy-border relative transition-all duration-300 desktop:hover:-translate-y-0.5 desktop:hover:shadow">
        <Link to={`/sclasses/${id}/sassignments`} className="flex flex-col items-start text-left">
          <div className="text-base font-medium text-black line-clamp-2">{name}</div>
          <div className="text-gray-900 mt-1 text-sm truncate">{instructor}</div>
        </Link>
        <div className="flex justify-between items-center space-x-4">
          <div className="flex items-center space-x-1 truncate">
            <div className="text-black font-semibold text-sm">{num_students}</div>
            <div className="text-gray-900 text-xs">students</div>
          </div>
        </div>
      </div>
    );
  };

  const ClassList = ({ classes }) => {
    return (
      <div className="container mx-auto mt-6">
        <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-4 min-w-20">
          {classes && classes.length > 0 ? (
            classes.map(classItem => (
              <ClassCard
                key={classItem.id}
                id={classItem.id}
                name={classItem.name}
                instructor={`${classItem.instructors[0].first_name} ${classItem.instructors[0].last_name}`}
                num_students={classItem.num_students}
              />
            ))
          ) : (
            <p>No classes available</p>
          )}
        </div>
      </div>
    );
  };

  return (
    <div id="HomeStudent" className="flex flex-col p-8 justify-start">
      <div id="Overview" className="flex flex-row w-full justify-between items-center mb-4">
        <div className="flex flex-row items-center">
          <h1 className="text-black text-xl font-medium">My Classes</h1>
        </div>
        <div className="flex items-center justify-end w-[600px]">
          <div
            className={`flex items-center transition-all duration-300 ${isJoinClassVisible ? 'opacity-100 w-auto mr-2' : 'opacity-0 w-0 overflow-hidden'}`}
          >
            <input
              type="text"
              placeholder="Class Code"
              value={classCode}
              className="input-entry-box bg-white p-2 rounded-md border border-gray-300 flex-grow mr-2"
              onChange={e => setClassCode(e.target.value)}
            />
            <button
              className="sexy-button border-[1px] border-black bg-orange-200 hover:bg-orange-300 text-black transition-all duration-300 desktop:hover:-translate-y-0.5 desktop:hover:shadow h-9 w-36 px-4 py-2 flex items-center justify-center"
              onClick={handleJoinClass}
            >
              Join Class
            </button>
          </div>
          <button
            className="sexy-button transition-all duration-300 desktop:hover:-translate-y-0.5 desktop:hover:shadow h-9 w-36 px-4 py-2 flex items-center justify-center"
            onClick={handleToggleJoinClass}
          >
            {isJoinClassVisible ? 'Cancel' : 'Join Class'}
          </button>
        </div>
      </div>
      <div id="Main-Content-container" className="flex flex-col space-y-4 w-full">
        {error && <div className="text-red-500">{error}</div>}
        <ClassList classes={classes} />
      </div>
    </div>
  );
}

export default SClasses;
