import {DndContext, rectIntersection} from '@dnd-kit/core';
import {restrictToVerticalAxis} from '@dnd-kit/modifiers';
import {arrayMove} from '@dnd-kit/sortable';
import {Field, Form, Formik} from 'formik';
import {default as React, useCallback, useEffect, useRef, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {PDFView} from '../PDFRendered.js';

import {isEmpty, isEqual} from 'lodash';
import {apiUrl} from '../../../api.js';
import {useAuth} from '../../../auth/AuthContext.js';
import {
    loadDraftData,
    loadQuestions,
    updateDraftData,
    uploadQuestions as uploadQuestionsAction
} from '../../../store/tpsetSlice.js';
import {TQuestionColumnNew} from './TQuestionColumnNew.js';
import {updateIds} from './utils.js';

import 'katex/dist/katex.min.css';
import * as Yup from 'yup';
import {
    defaultFeedbackInstructions,
    defaultGradingInstructions,
    defaultInstructions
} from './constants.js';
import {ArrowDown, FileQuestion} from "lucide-react";

function TPsetNew({
                      assignment_id,
                      setUpdateTrigger,
                      isPublished,
                      pullQuestions,
                      setPullQuestions,
                      setEdited,
                      isExam,
                      edited,
                      setSolRecent,
                      solRecent
                  }) {
    const {user} = useAuth();
    const dispatch = useDispatch();
    const [isGradingInstructionsVisible, setIsGradingInstructionsVisible] = useState(false);
    const [isFeedbackInstructionsVisible, setIsFeedbackInstructionsVisible] = useState(false);
    const [iframeKey, setIframeKey] = useState(Date.now());
    const [showSavedPopup, setShowSavedPopup] = useState(false);
    const [showErrorMessagePopup, setShowErrorMessagePopup] = useState(false);
    const [lastSaved, setLastSaved] = useState(null);
    const [error, setError] = useState('');
    const [viewedQuestions, setViewedQuestions] = useState(new Map());
    const [copiedRubricItems, setCopiedRubricItems] = useState(null);
    const generateRubricsRef = useRef(null);
    const [currentPage, setCurrentPage] = useState(() => {
        // Try to get stored page from localStorage, default to 1
        return parseInt(localStorage.getItem(`pdf-page-${assignment_id}`) || '1');
    });

    const {
        data: draftData,
        isLoading: isDraftDataLoading,
        errorMessage
    } = useSelector(state => state?.tpsetReducer?.draftData);

    const disabledStyle = isPublished || isDraftDataLoading ? '!opacity-65' : '';

    useEffect(() => {
        dispatch(loadDraftData({assignment_id, token: user.session_token}));
    }, []);

    useEffect(() => {
        if (errorMessage) {
            setShowErrorMessagePopup(true);
            setTimeout(() => setShowErrorMessagePopup(false), 2000);
        }
    }, [errorMessage]);

    useEffect(() => {
        if (!draftData.edited) {
            if (!solRecent) {
                setEdited(true);
            }
            setSolRecent(false);
        }
    }, [draftData.examPaper]);

    useEffect(() => {
        if (pullQuestions) {
            dispatch(loadQuestions({assignment_id, token: user.session_token}));
            setPullQuestions(false);
            setEdited(false);
        }
    }, [pullQuestions]);

    const TPsetSchema = Yup.object().shape({
        examPaper: Yup.array()
            .of(
                Yup.object().shape({
                    description: Yup.string().required('This field is required!'),
                    subQuestions: Yup.array()
                        .of(
                            Yup.object().shape({
                                questionSizing: Yup.string().required('This field is required!'),
                                weight: Yup.number()
                                    .min(1, 'Each MCQ subquestion must have a weight of at least 1.')
                                    .required('This field is required!'),
                                mcqOptions: Yup.array().when('questionType', {
                                    is: val => val === 'mcq',
                                    then: () =>
                                        Yup.array()
                                            .of(
                                                Yup.object().shape({
                                                    text: Yup.string()
                                                        .required('Each MCQ option must have text.'),
                                                    isCorrect: Yup.boolean()
                                                        .required('Each MCQ option must specify if it is correct.')
                                                })
                                            )
                                            .required('MCQ options are required')
                                            .min(2, 'Minimum of 2 MCQ options')
                                            .test(
                                                'one-correct-answer',
                                                'Each MCQ must have exactly one correct answer.',
                                                options => {
                                                    if (!options) {
                                                        return false
                                                    }
                                                    ;
                                                    const correctCount = options.filter(option => option.isCorrect).length;
                                                    return correctCount === 1;
                                                }
                                            )
                                }),
                                rubricItems: Yup.array().when('questionType', {
                                    is: val => val === 'freeform',
                                    then: () =>
                                        Yup.array()
                                            .of(
                                                Yup.object().shape({
                                                    value: Yup.string().required('Must have a point value.'),
                                                    descriptor: Yup.string().required('Must have a descriptor value.')
                                                })
                                            )
                                            .min(1, 'Minimum of 1 Rubric item')
                                            .required('Must have Rubric item')
                                })
                            })
                        )
                        .min(1, 'Minimum of 1 sub question')
                        .required('Must have sub question')
                })
            )
            .min(1, 'Minimum of 1 question')
            .required('Must have question')
    });

    const handleChange = async ({fieldName, value}) => {
        if (!isEqual(draftData[fieldName], value)) {
            console.log(value)
            const result = await dispatch(
                updateDraftData({
                    token: user.session_token,
                    assignment_id,
                    dataToSave: {
                        [fieldName]: value
                    }
                })
            );

            if (!result.error) {
                setLastSaved(new Date());
                setShowSavedPopup(true);
                setTimeout(() => setShowSavedPopup(false), 2000);
                setEdited(true);
            }
        }
    };

    const updateViewedQuestions = id => {
        const expandedQ = new Map(viewedQuestions);
        if (viewedQuestions.has(id)) {
            expandedQ.delete(id);
        } else {
            expandedQ.set(id, []);
        }

        return setViewedQuestions(expandedQ);
    };

    const getTaskPos = id => draftData.examPaper.findIndex(question => question.id === id);

    const handleDragEnd = event => {
        const originalExamPaper = draftData.examPaper;
        const {active, over} = event;

        if (!active || !over || !active.id || !over.id || active.id === over.id) {
            return;
        }

        const getNewExamPaper = examPaper => {
            const originalPos = getTaskPos(active.id);
            const newPos = getTaskPos(over.id);

            if (originalPos === -1 || newPos === -1) {
                console.error('Could not find position for active or over:', {
                    active,
                    over,
                    originalPos,
                    newPos
                });
                return examPaper;
            }

            const updatedPaper = arrayMove(examPaper, originalPos, newPos);
            return updateIds(updatedPaper);
        };

        const newExamPaper = getNewExamPaper(originalExamPaper);
        handleChange({value: newExamPaper, fieldName: 'examPaper'});
    };

    const handleHideFirstPageChange = (e) => {
        handleChange({
            fieldName: 'hideFirstPage',
            value: e.target.checked
        });
    };

    const getInitialValues = useCallback(() => {
        if (!isEmpty(draftData)) {
            return {
                ...draftData,
                instructions: draftData.instructions || defaultInstructions,
                gradingInstructions: draftData.gradingInstructions || defaultGradingInstructions,
                feedbackInstructions: draftData.feedbackInstructions || defaultFeedbackInstructions,
                showPoints: draftData.showPoints || false,
                hideFirstPage: draftData.hideFirstPage || false  // Add this line
            };
        }

        return {
            examPaper: [
                {
                    id: 1,
                    description: '',
                    subQuestions: []
                }
            ],
            instructions: defaultInstructions,
            created: false,
            edited: false,
            pdfUrl: '',
            teacherPdfUrl: '',
            gradingInstructions: defaultGradingInstructions,
            feedbackInstructions: defaultFeedbackInstructions,
            showPoints: false,
            hideFirstPage: false  // Add this line
        };
    }, [isDraftDataLoading, draftData]);

    const handleShowPointsChange = (e) => {
        handleChange({
            fieldName: 'showPoints',
            value: e.target.checked
        });
    };

    const uploadQuestions = async () => {
        const payload = {
            exam_paper: draftData.examPaper.map(question => ({
                ...question,
                subQuestions: question.subQuestions.map(subQuestion => ({
                    ...subQuestion,
                    mcqOptions: subQuestion.questionType === 'mcq' ? subQuestion.mcqOptions : undefined,
                    rubricItems:
                        subQuestion.questionType === 'mcq'
                            ? [
                                {descriptor: 'Correct Answer', value: subQuestion.weight.toString()},
                                {descriptor: 'Incorrect Answer', value: '0'}
                            ]
                            : subQuestion.rubricItems,
                    ...(subQuestion.questionType === 'mcq' && {mcqOptions: subQuestion.mcqOptions})
                }))
            })),
            instructions: draftData.instructions,
            model_instructions: draftData.gradingInstructions,
            feedback_instructions: draftData.feedbackInstructions,
            show_points: draftData.showPoints,
            hide_first_page: draftData.hideFirstPage,
            page: currentPage
        };

        const response = await dispatch(
            uploadQuestionsAction({assignment_id, token: user.session_token, body: payload})
        );

        if (response.type === 'tpset/uploadQuestions/fulfilled') {
            setEdited(false);
            setSolRecent(true);
            setUpdateTrigger(prev => !prev);
            setIframeKey(Date.now());
            setError('');
        } else {
            const errorMessage = response.payload || response.error?.message || 'An error occurred. Please try again.';
            setError(errorMessage);
        }
    };


    const handleCopyRubricItems = useCallback((rubricItems) => {
        console.log('rubricItems', rubricItems);
        setCopiedRubricItems(rubricItems);
    }, []);

    const scrollToGenerateRubrics = () => {
        if (generateRubricsRef.current) {
            const elementRect = generateRubricsRef.current.getBoundingClientRect();
            const absoluteElementTop = elementRect.top + window.pageYOffset;
            const middle = absoluteElementTop - window.innerHeight + elementRect.height + 20;
            window.scrollTo({top: middle, behavior: 'smooth'});
        }
    };

    const handlePasteRubricItems = useCallback((values, setFieldValue) => (questionIndex, subQuestionIndex) => {
        console.log(copiedRubricItems)
        console.log(values.examPaper)
        if (copiedRubricItems) {
            const newExamPaper = values.examPaper.map((question, qIndex) => {
                console.log(qIndex, questionIndex)
                if (questionIndex === -1 ? qIndex === 0 : qIndex === questionIndex) {
                    return {
                        ...question,
                        subQuestions: question.subQuestions.map((subQuestion, sqIndex) => {
                            console.log(sqIndex, subQuestionIndex)
                            if (sqIndex === subQuestionIndex) {
                                return {
                                    ...subQuestion,
                                    rubricItems: [...copiedRubricItems]
                                };
                            }
                            return subQuestion;
                        })
                    };
                }
                return question;
            });
            console.log('newExamPaper', newExamPaper);

            setFieldValue('examPaper', newExamPaper);

            handleChange({fieldName: 'examPaper', value: newExamPaper});
        }
    }, [copiedRubricItems]);


    return (
        <div className={`flex-grow container`}>
            <div className="flex lg:space-x-4">
                {/* Left side content - full width */}
                <div className="lg:w-full pr-4">
                    <div className={`flex-grow container ${disabledStyle}`}>
                        <Formik
                            enableReinitialize
                            initialValues={getInitialValues()}
                            validationSchema={TPsetSchema}
                            validateOnChange={true}
                            validateOnBlur={true}
                            initialTouched={{
                                examPaper: draftData.examPaper?.map(question => ({
                                    description: true,
                                    subQuestions: question.subQuestions?.map(() => ({
                                        questionSizing: true,
                                        weight: true
                                    }))
                                }))
                            }}
                            validateOnMount={true}
                            onSubmit={async (values, {setSubmitting}) => {
                                setSubmitting(true);
                                await handleChange({fieldName: 'examPaper', value: values.examPaper});
                                setSubmitting(false);
                            }}
                        >
                            {({values, setFieldValue, isValid, dirty}) => (
                                <Form>
                                    <div className="relative">
                                        <div className="flex flex-col w-full mb-5">
                                            <h2 className="text-xl font-bold mb-1">Step 1: Build Assignment</h2>
                                            <p className="text-sm mb-4">
                                                Enter the questions, preferably in LaTeX, and, optionally, edit rubrics.
                                                <br/> You can enter solutions manually, or upload a GradeWiz solutions
                                                PDF
                                                later.
                                            </p>
                                            <p className="text-sm">Exam Instructions:</p>
                                        </div>
                                        <div className={`sexy-border rounded-xl mb-4 w-full text-sm h-64 `}>
                                            <div className="w-full h-full flex flex-col scroll-auto">
                                                <Field
                                                    name="instructions"
                                                    as="textarea"
                                                    disabled={isPublished}
                                                    className={`actual-input p-4 ${disabledStyle}`}
                                                    onBlur={e =>
                                                        handleChange({
                                                            fieldName: 'instructions',
                                                            value: e.target.value
                                                        })
                                                    }
                                                />
                                            </div>
                                        </div>
                                        <div className="flex flex-col w-full mb-5 mt-8">
                                            <button
                                                type="button"
                                                className="flex items-center text-sm mb-2"
                                                onClick={() => setIsGradingInstructionsVisible(!isGradingInstructionsVisible)}
                                            >
                                                <img
                                                    src="/assets/back_arrow.png"
                                                    className={`size-5 opacity-60 transform ${isGradingInstructionsVisible ? 'rotate-90' : '-rotate-90'} mr-2`}
                                                    alt="Toggle grading instructions"
                                                />
                                                Grading Instructions (optional)
                                            </button>
                                        </div>
                                        {isGradingInstructionsVisible && (
                                            <div className={`sexy-border rounded-xl mb-4 w-full text-sm h-40 `}>
                                                <div className="w-full h-full flex flex-col scroll-auto">
                                                    <Field
                                                        name="gradingInstructions"
                                                        as="textarea"
                                                        disabled={isPublished}
                                                        className={`actual-input p-4 ${disabledStyle} text-xs text-gray-500`}
                                                        onBlur={e =>
                                                            handleChange({
                                                                fieldName: 'gradingInstructions',
                                                                value: e.target.value
                                                            })
                                                        }
                                                    />
                                                </div>
                                            </div>
                                        )}
                                        <div className="flex flex-col w-full mb-5 mt-5">
                                            <button
                                                type="button"
                                                className="flex items-center text-sm mb-2"
                                                onClick={() => setIsFeedbackInstructionsVisible(!isFeedbackInstructionsVisible)}
                                            >
                                                <img
                                                    src="/assets/back_arrow.png"
                                                    className={`size-5 opacity-60 transform ${isFeedbackInstructionsVisible ? 'rotate-90' : '-rotate-90'} mr-2`}
                                                    alt="Toggle feedback instructions"
                                                />
                                                Feedback Instructions (optional)
                                            </button>
                                        </div>
                                        {isFeedbackInstructionsVisible && (
                                            <div className={`sexy-border rounded-xl mb-4 w-full text-sm h-40 `}>
                                                <div className="w-full h-full flex flex-col scroll-auto">
                                                    <Field
                                                        name="feedbackInstructions"
                                                        as="textarea"
                                                        disabled={isPublished}
                                                        className={`actual-input p-4 ${disabledStyle} text-xs text-gray-500`}
                                                        onBlur={e =>
                                                            handleChange({
                                                                fieldName: 'feedbackInstructions',
                                                                value: e.target.value
                                                            })
                                                        }
                                                    />
                                                </div>
                                            </div>
                                        )}
                                        {showSavedPopup && (
                                            <div
                                                className="fixed bottom-4 right-4 bg-green-500 text-white px-4 py-2 rounded">
                                                Progress saved
                                            </div>
                                        )}
                                        {showErrorMessagePopup && (
                                            <div
                                                className="fixed bottom-4 right-4 bg-red-500 text-white px-4 py-2 rounded">
                                                {errorMessage}
                                            </div>
                                        )}
                                        <div className="flex-col w-full h-full mt-2 mb-4 pr-[25px]">
                                            {lastSaved && (
                                                <div className="text-sm text-gray-500 mb-2">
                                                    Last saved: {lastSaved.toLocaleTimeString()}
                                                </div>
                                            )}

                                            <div className="flex justify-between items-center w-full">
                                                <div className="flex items-center justify-start space-x-4">
                                                    <div className="flex items-center">
                                                        <div className="relative inline-block mr-2">
                                                            <input
                                                                type="checkbox"
                                                                id="showPointsCheckbox"
                                                                checked={values.showPoints}
                                                                disabled={isPublished}
                                                                className="sr-only peer"
                                                                onChange={handleShowPointsChange}
                                                            />
                                                            <label
                                                                htmlFor="showPointsCheckbox"
                                                                className={`${isPublished ? 'cursor-not-allowed opacity-50' : 'cursor-pointer'} 
                        relative inline-flex h-6 w-11 items-center rounded-full transition-colors duration-200 ease-in-out
                        ${values.showPoints ? 'bg-orange-200 border-orange-300' : 'bg-gray-200 hover:bg-gray-300'}`}
                                                            >
                    <span className={`
                        inline-block h-4 w-4 transform rounded-full bg-white transition-transform duration-200 ease-in-out
                        ${values.showPoints ? 'translate-x-6' : 'translate-x-1'}
                    `}/>
                                                            </label>
                                                        </div>
                                                        <label htmlFor="showPointsCheckbox"
                                                               className="text-sm font-medium text-gray-700">
                                                            Make Weights Visible
                                                        </label>
                                                    </div>

                                                    <div className="flex items-center">
                                                        <div className="relative inline-block mr-2">
                                                            <input
                                                                type="checkbox"
                                                                id="hideFirstPageCheckbox"
                                                                checked={values.hideFirstPage}
                                                                disabled={isPublished}
                                                                className="sr-only peer"
                                                                onChange={handleHideFirstPageChange}
                                                            />
                                                            <label
                                                                htmlFor="hideFirstPageCheckbox"
                                                                className={`${isPublished ? 'cursor-not-allowed opacity-50' : 'cursor-pointer'} 
                        relative inline-flex h-6 w-11 items-center rounded-full transition-colors duration-200 ease-in-out
                        ${values.hideFirstPage ? 'bg-orange-200 border-orange-300' : 'bg-gray-200 hover:bg-gray-300'}`}
                                                            >
                    <span className={`
                        inline-block h-4 w-4 transform rounded-full bg-white transition-transform duration-200 ease-in-out
                        ${values.hideFirstPage ? 'translate-x-6' : 'translate-x-1'}
                    `}/>
                                                            </label>
                                                        </div>
                                                        <label htmlFor="hideFirstPageCheckbox"
                                                               className="text-sm font-medium text-gray-700">
                                                            Hide Title Page
                                                        </label>
                                                    </div>
                                                </div>

                                                <div>
                                                    {!!viewedQuestions.size ? (
                                                        <button
                                                            type="button"
                                                            className="ml-2"
                                                            onClick={() => {
                                                                setViewedQuestions(prev => new Map(prev.clear()));
                                                            }}
                                                        >
                                                            <img src="/assets/hide.png" className="size-6"
                                                                 alt="View all"/>
                                                        </button>
                                                    ) : (
                                                        <button
                                                            type="button"
                                                            className="ml-2"
                                                            onClick={() => {
                                                                const allIds = new Map();
                                                                values.examPaper.map(question => allIds.set(question.id, []));
                                                                setViewedQuestions(allIds);
                                                            }}
                                                        >
                                                            <img src="/assets/view.png" className="size-6"
                                                                 alt="View all"/>
                                                        </button>
                                                    )}
                                                </div>
                                            </div>
                                        </div>
                                        <DndContext
                                            collisionDetection={rectIntersection}
                                            modifiers={[restrictToVerticalAxis]}
                                            onDragEnd={handleDragEnd}
                                        >
                                            <TQuestionColumnNew
                                                fieldName={'examPaper'}
                                                examPaper={values.examPaper}
                                                isPublished={isPublished}
                                                disabledStyle={disabledStyle}
                                                isCreated={values.created}
                                                isDraftDataLoading={isDraftDataLoading}
                                                viewedQuestions={viewedQuestions}
                                                setViewedQuestions={setViewedQuestions}
                                                updateViewedQuestions={updateViewedQuestions}
                                                setError={setError}
                                                error={error}
                                                uploadQuestions={uploadQuestions}
                                                handleChange={handleChange}
                                                copiedRubricItems={copiedRubricItems}
                                                isValid={isValid}
                                                assignmentId={assignment_id}
                                                generateRubricsRef={generateRubricsRef}
                                                onCopyRubricItems={handleCopyRubricItems}
                                                onPasteRubricItems={handlePasteRubricItems(values, setFieldValue)}
                                            />
                                        </DndContext>

                                    </div>
                                    {values.teacherPdfUrl ? (
                                        <div
                                            className={'lg:w-2/5 border-2 rounded-xl lg:fixed overflow-auto overflow-x-hidden lg:right-0 mt-5 lg:top-[72px] lg:bottom-[64px] mr-10'}
                                        >
                                            {isExam && (
                                                <div
                                                    className="absolute bottom-0 left-0 right-0 bg-yellow-50/80 border-l-4 border-yellow-400 p-2 z-10">
                                                    <div className="flex items-start">
                                                        <div className="flex-shrink-0">
                                                            <img
                                                                src="/assets/tclasses/warning.png"
                                                                alt="Warning"
                                                                className="h-4 w-4 text-yellow-400"
                                                            />
                                                        </div>
                                                        <div className="ml-2">
                                                            <p className="text-xs text-yellow-700 font-semibold">
                                                                Do not print and give this PDF to students.
                                                            </p>
                                                            <p className="text-xs text-yellow-700">
                                                                This is an answer key copy. Publish the assignment to
                                                                access the student PDF.
                                                            </p>
                                                        </div>
                                                    </div>
                                                </div>
                                            )}
                                            <div className="flex justify-end w-full mr-5 h-full overflow-x-hidden">
                                                <PDFView
                                                    key={iframeKey}
                                                    url={`${apiUrl}/api/pdf-file/${values.teacherPdfUrl}`}
                                                    initialPage={parseInt(localStorage.getItem(`pdf-page-${assignment_id}`) || '1')}
                                                    className="h-[calc(100vh-136px)] overflow-x-hidden" // 64px top bar + 72px bottom bar
                                                    onPageChange={(pageNum) => {
                                                        localStorage.setItem(`pdf-page-${assignment_id}`, pageNum.toString());
                                                        setCurrentPage(pageNum);
                                                    }}
                                                />
                                            </div>
                                        </div>
                                    ) : (
                                        <div
                                            className={'w-full lg:w-2/5 lg:fixed lg:right-0 mt-5 lg:top-[72px] lg:bottom-[64px] lg:overflow-auto mr-10'}>
                                            <div
                                                className="w-full h-full flex flex-col items-center justify-center bg-gray-100 rounded-xl">
                                                <div className="flex flex-col items-center">
                                                    <FileQuestion
                                                        className="w-24 h-24 mb-4 text-gray-400 stroke-[1.5]"/>
                                                    <p className="text-xl font-semibold text-gray-700">Please generate
                                                        PDF to start</p>
                                                </div>
                                            </div>
                                        </div>
                                    )}
                                </Form>
                            )}
                        </Formik>
                    </div>
                </div>
            </div>
        </div>

    );
}

export default TPsetNew;