import React, {useEffect, useRef, useState} from 'react';
import {Link} from 'react-router-dom';
import {gradeApi} from '../../api.js';
import {useAuth} from '../../auth/AuthContext';
import ConfirmationModal from '../ConfirmationModal';
import DueTimeDisplay from '../DueTimeDisplay.js';
import CloneModal from './TCloneModal';
import Modal from './TModal';
import MoreButton from './TMore';
import {Settings} from 'lucide-react';

function TAssignments(props) {
    const [assignmentName, setAssignmentName] = useState('');
    const [dueDate, setDueDate] = useState('');
    const [dueTime, setDueTime] = useState('');
    const [timezone, setTimezone] = useState('');
    const [description, setDescription] = useState('');
    const [file, setFile] = useState();
    const [studentSubmitted, setStudentSubmitted] = useState('');
    const [format, setFormat] = useState(''); // New state for essay/problem set
    const [className, setClassName] = useState('');
    const [assignments, setAssignments] = useState([]);
    const {user} = useAuth();
    const [isCloneModalOpen, setIsCloneModalOpen] = useState(false);
    const [cloneAssignmentId, setCloneAssignmentId] = useState(null);
    const [cloneAssignmentName, setCloneAssignmentName] = useState('');
    const [originalCloneName, setOriginalCloneName] = useState('');
    const [cloneDueDate, setCloneDueDate] = useState('');
    const [cloneDueTime, setCloneDueTime] = useState('');
    const [errorMessage, setErrorMessage] = useState('');
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [isConfirmationModalOpen, setIsConfirmationModalOpen] = useState(false);
    const [selectedAssignmentId, setSelectedAssignmentId] = useState(null);
    const [cloneSubmissionType, setCloneSubmissionType] = useState('');
    const [classOpen, setClassOpen] = useState(false);
    const [code, setCode] = useState('');
    const [cloneClassId, setCloneClassId] = useState(props.class_id);
    const [userClasses, setUserClasses] = useState([]);
    const [isGroupSubmission, setIsGroupSubmission] = useState(false);


    const handleRenameSubmit = async (assignmentId, newName) => {
        // console.log('Renaming assignment:', assignmentId, 'to:', newName);

        if (newName.trim() === '') {
            setErrorMessage('Assignment name cannot be empty.');
            return;
        }

        if (newName.length > 70) {
            setErrorMessage('Assignment name must not exceed 70 characters.');
            return;
        }

        try {
            await gradeApi.patch({
                path: `/api/assignment/${assignmentId}/rename/`,
                body: {title: newName},
                config: {
                    headers: {
                        Authorization: `Bearer ${user.session_token}`
                    }
                }
            });
            getAssignments(); // Refresh the assignments list
            setErrorMessage(''); // Clear any existing error messages
        } catch (error) {
            setErrorMessage(
                error.response?.data?.error || 'An error occurred while renaming the assignment.'
            );
        }
    };
    const getUserClasses = async () => {
        try {
            const response = await gradeApi.get({
                path: `/api/users/${user.user.id}/courses-lite/`,
                config: {
                    headers: {
                        Authorization: `Bearer ${user.session_token}`
                    }
                }
            });
            setUserClasses(response.data.courses);
            // console.log(response);
        } catch (error) {
            console.error('Error fetching user classes:', error);
        }
    };

    const handleOutsideClick = (e, ref) => {
        if (ref.current && !ref.current.contains(e.target)) {
            setIsModalOpen(false);
        }
    };


    const confirmCloneAssignment = async () => {
        try {
            // console.log("Starting clone confirmation process");
            const originalAssignment = assignments.find(a => a.id === cloneAssignmentId);
            // console.log("Original assignment to clone:", originalAssignment);

            if (cloneAssignmentName.length > 70) {
                // console.log("Assignment name too long:", cloneAssignmentName.length);
                setErrorMessage('Assignment name must not exceed 70 characters.');
                return;
            }

            // console.log("Preparing form data for clone request");
            const formData = new FormData();
            formData.append('title', cloneAssignmentName);

            // Fix: Properly handle date and time based on submission type
            if (cloneSubmissionType === 'teacher') {
                // For teacher submissions, use a far future date
                formData.append('due_date', '9999-09-20');
                formData.append('due_time', '16:13');
            } else {
                // For student submissions, validate and format the date/time
                if (!cloneDueDate || !cloneDueTime) {
                    setErrorMessage('Please set both due date and time for student submissions');
                    return;
                }
                // Ensure date and time are properly formatted
                formData.append('due_date', cloneDueDate.trim());
                formData.append('due_time', cloneDueTime.trim());
            }

            formData.append('timezone', timezone);
            formData.append('is_exam', cloneSubmissionType === 'teacher');
            formData.append('is_essay', originalAssignment.is_essay);
            formData.append('is_group_task', isGroupSubmission);

            // console.log("Sending clone request to API");
            const response = await gradeApi.post({
                path: `/api/courses/${cloneClassId}/assignment/`,
                body: formData,
                config: {
                    headers: {
                        Authorization: `Bearer ${user.session_token}`
                    }
                }
            });
            // console.log("Clone API response:", response.data);

            const draftResponse = await gradeApi.get({
                path: `/api/assignment/${cloneAssignmentId}/${originalAssignment.is_essay ? 'essay' : 'problem-set'}/load-draft/`,
                config: {headers: {Authorization: `Bearer ${user.session_token}`}}
            });
            console.log("Draft data response:", draftResponse.data);

            if (draftResponse.data) {

                if (originalAssignment.is_essay) {
                    const {
                        criteria,
                        instructions,
                        editableInstructions,
                        created,
                        subUrl,
                        gradingInstructions,
                        showRubric
                    } = JSON.parse(draftResponse.data.draft_data)
                    // console.log("Saving essay draft data to new assignment");
                    const essaySaveResponse = await gradeApi.post({
                        path: `/api/assignment/${response.data.id}/essay/save-draft/`,
                        body: {
                            criteria: criteria,
                            instructions: instructions,
                            editableInstructions: instructions,
                            gradingInstructions: gradingInstructions,
                            showRubric: showRubric
                        },
                        config: {headers: {Authorization: `Bearer ${user.session_token}`}}
                    });
                    console.log("Essay save response:", essaySaveResponse);
                } else {
                    // console.log("Saving problem set draft data to new assignment");
                    const problemSetSaveResponse = await gradeApi.post({
                        path: `/api/assignment/${response.data.id}/problem-set/save-draft/`,
                        body: {
                            examPaper: draftResponse.data.examPaper,
                            instructions: draftResponse.data.instructions,
                            editableInstructions: draftResponse.data.instructions,
                            gradingInstructions: draftResponse.data.gradingInstructions,
                            feedbackInstructions:
                                draftResponse.data.feedbackInstructions ||
                                'Keep your feedback concise and constructive.'
                        },
                        config: {headers: {Authorization: `Bearer ${user.session_token}`}}
                    });
                    // console.log("Problem set save response:", problemSetSaveResponse);
                }
            } else {
                console.log("No draft data found in the original assignment");
            }

            // console.log("Refreshing assignments list");
            getAssignments();
            setIsCloneModalOpen(false);
            // console.log("Clone process completed successfully");
            //
            // console.log("Refreshing assignments list");
            getAssignments();
            setIsCloneModalOpen(false);
            // console.log("Clone process completed successfully");

        } catch (error) {
            console.error("Error during cloning process:", error);
            console.log("Error response:", error.response);
            setErrorMessage(error.response?.data?.error || 'An error occurred. Please try again.');
        }
    };

    // Update handleCloneAssignment to properly initialize date/time
    const handleCloneAssignment = assignmentId => {
        // console.log('Cloning assignment with ID:', assignmentId);
        const assignment = assignments.find(a => a.id === assignmentId);
        // console.log('Original assignment:', assignment);

        setCloneAssignmentId(assignmentId);
        const truncatedTitle = assignment.title.length > 20
            ? assignment.title.substring(0, 20) + '...'
            : assignment.title;

        setCloneAssignmentName(`Copy of ${assignment.title}`);
        setOriginalCloneName(truncatedTitle);

        // Initialize with empty strings for student submissions
        setCloneDueDate('');
        setCloneDueTime('');

        // Set submission type
        const submissionType = assignment.is_essay
            ? ''
            : assignment.is_exam
                ? 'teacher'
                : 'student';
        setCloneSubmissionType(submissionType);

        setCloneClassId(props.class_id);
        setErrorMessage('');
        setIsCloneModalOpen(true);
    };
    // create a ref for the modal
    const modalRef = useRef();

    // dismissal tracking
    useEffect(() => {
        const handleClickOutside = event => {
            handleOutsideClick(event, modalRef);
        };

        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, [modalRef]);

    const getAssignments = async () => {
        try {
            const response = await gradeApi.get({
                path: `/api/courses/${props.class_id}/teacher-assignments-lite/`,
                config: {
                    headers: {
                        Authorization: `Bearer ${user.session_token}`
                    }
                }
            });
            // console.log(response);
            setAssignments(response.data.assignments);
            setClassName(response.data.class_name);
            setClassOpen(response.data.class_open);
            setCode(response.data.class_code);
            // console.log(response.data.class_open);
            setTimezone(response.data.timezone);
        } catch (error) {
            setErrorMessage(error.response.data.error || 'An error occurred. Please try again.');
        }
    };

    useEffect(() => {
        getAssignments();
        getUserClasses();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const handleSubmit = async e => {
        e.preventDefault();

        // console.log(assignmentName.length)
        // Check assignment name length
        if (assignmentName.length > 70) {
            setErrorMessage('Assignment name must not exceed 70 characters.');
            return;
        }

        // // Check for duplicate assignment name
        // const assignmentExists = assignments.some((a) => a.title === assignmentName);
        // if (assignmentExists) {
        //     setErrorMessage("Assignment with this name already exists.");
        //     return;
        // }

        // Check if due date is in the future
        // const currentDate = new Date();
        // const selectedDueDate = new Date(dueDate);
        // if (selectedDueDate <= currentDate) {
        //     setErrorMessage("Due date must be greater than the current date.");
        //     return;
        // }  

        const formData = new FormData();
        formData.append('file_data', file);
        formData.append('title', assignmentName);
        if (studentSubmitted == "student") {
            formData.append('due_date', dueDate);
            formData.append('due_time', dueTime);
        } else {
            formData.append('due_date', "9999-9-20");
            formData.append('due_time', "16:13");
        }
        formData.append('due_date', dueDate);
        formData.append('due_time', dueTime);
        formData.append('is_exam', studentSubmitted === 'teacher');
        formData.append('is_group_task', isGroupSubmission);
        formData.append('is_essay', format === 'essay'); // Add this line

        try {
            const response = await gradeApi.post({
                path: `/api/courses/${props.class_id}/assignment/`,
                body: formData,
                config: {
                    headers: {
                        Authorization: `Bearer ${user.session_token}`
                    }
                }
            });
            // console.log(response);
            getAssignments();
            setIsModalOpen(false);
        } catch (error) {
            // console.log(error);
            setErrorMessage(error.response?.data?.error || 'An error occurred. Please try again.');
        }


    };

    const handleDeleteAssignment = assignmentId => {
        setSelectedAssignmentId(assignmentId);
        setIsConfirmationModalOpen(true);
    };

    const confirmDeleteAssignment = async () => {
        try {
            await gradeApi.delete({
                path: `/api/courses/${props.class_id}/assignment/${selectedAssignmentId}/`,
                headers: {
                    Authorization: `Bearer ${user.session_token}`
                }
            });
            getAssignments();
            setIsConfirmationModalOpen(false);
        } catch (error) {
            setErrorMessage(error.response.data.error);
        }
    };

    const actuallyChangeClassOpen = async value => {
        // console.log(value);
        try {
            await gradeApi.post({
                path: `/api/courses/${props.class_id}/open/`,
                body: {openValue: value},
                config: {
                    headers: {
                        Authorization: `Bearer ${user.session_token}`
                    }
                }
            });
        } catch (error) {
            setErrorMessage(error);
        }
    };

    const handleChangeClassOpen = event => {
        const newValue = event.target.checked;
        setClassOpen(newValue);
        actuallyChangeClassOpen(newValue);
    };
    const AssignmentCard = ({
                                id,
                                title,
                                num_submissions,
                                num_students,
                                due_date,
                                due_time,
                                timezone,
                                is_exam,
                                is_essay,
                                is_group_task,
                                is_assignment_published,
                                is_grades_published,
                                handleRenameSubmit,
                                setErrorMessage
                            }) => {
        const [myBool, setmyBool] = useState(true);
        const [isEditing, setIsEditing] = useState(false);
        const [newName, setNewName] = useState(title);

        function toggleBool() {
            setmyBool(!myBool);
        }

        const handleRenameClick = () => {
            setIsEditing(true);
            setNewName(title);
        };

        const handleSaveClick = async () => {
            // console.log('Saving new name:', newName);
            try {
                await handleRenameSubmit(id, newName);
                setIsEditing(false);
            } catch (error) {
                setErrorMessage(error.message);
            }
        };

        const handleCancelClick = () => {
            setIsEditing(false);
            setNewName(title);
        };

        const getSubmissionTypeText = () => {
            let text = is_essay ? 'Essay' : is_exam ? 'Teacher Submission' : 'Student Submission';
            if (is_group_task) {
                text += ' (Group)';
            }
            return text;
        };

        const getPublicationStatusClass = () => {
            if (is_grades_published) {
                return 'bg-orange-200';
            }
            if (is_assignment_published) {
                return 'bg-gray-300';
            }
            return 'bg-transparent';
        };

        return (
            <div
                className="flex flex-col justify-between p-4 h-40 bg-white sexy-border relative transition-all duration-300 desktop:hover:-translate-y-0.5 desktop:hover:shadow overflow-hidden">
                {isEditing ? (
                    <div className="flex items-center">
                        <input
                            type="text"
                            value={newName}
                            className="text-base font-medium text-black w-full mr-2 p-1 border rounded focus:outline-none focus:ring-2 focus:ring-orange-200 focus:border-orange-200"
                            autoFocus
                            onChange={e => setNewName(e.target.value)}
                        />
                        <button
                            className="rounded-3xl border-[1px] border-black transition-all duration-300 desktop:hover:-translate-y-[0.5px] desktop:hover:shadow bg-orange-200 hover:bg-orange-300 text-black px-2 py-1 mr-2 text-sm"
                            onClick={handleSaveClick}
                        >
                            Save
                        </button>
                        <button
                            className="rounded-3xl border-[1px] border-black transition-all duration-300 desktop:hover:-translate-y-[0.5px] desktop:hover:shadow bg-white-200 hover:bg-gray-300 text-black px-2 py-1 mr-2 text-sm"
                            onClick={handleCancelClick}
                        >
                            Cancel
                        </button>
                    </div>
                ) : (
                    <Link to={`/tclasses/${props.class_id}/tassignments/${id}/tsubmissions`}>
                        <div className="text-base font-medium text-black line-clamp-2">{title}</div>
                    </Link>
                )}

                <div className={`flex flex-col ${!is_exam ? 'space-y-1' : 'space-y-0'}`}>
                    <div className="flex">
                        <Link
                            className="flex"
                            to={`/tclasses/${props.class_id}/tassignments/${id}/tsubmissions`}
                        >
                            {!is_exam ?
                                <div>
                                    <div className="text-gray-900 text-smallish truncate mr-1">Due:</div>
                                    <DueTimeDisplay dueTime={due_time} dueDate={due_date} timezone={timezone}/>
                                </div> :
                                <div>
                                    <div className="text-gray-900 text-smallish truncate mr-1">Due:</div>
                                    <div className="text-gray-900 text-smallish truncate mr-1">No due date</div>
                                </div>
                            }
                        </Link>
                    </div>
                    <div className={`text-gray-900 text-smallish truncate ${is_exam ? 'mt-1' : ''}`}>
                        {getSubmissionTypeText()}
                    </div>
                </div>

                <div className="flex justify-between items-center space-x-4">
                    <div className="flex items-center space-x-1 truncate">
                        <div className="flex items-center space-x-1 truncate">
                            {is_group_task ? (
                                <div className="text-black font-semibold text-sm">{num_submissions} </div>
                            ) : (
                                <div className="text-black font-semibold text-sm">
                                    {num_submissions}/{num_students}
                                </div>
                            )}
                            {is_group_task ? (
                                <div className="text-gray-900 text-xs">groups submitted</div>
                            ) : (
                                <div className="text-gray-900 text-xs">submitted</div>
                            )}
                        </div>
                    </div>
                    <div>
                        <MoreButton
                            myBool={myBool}
                            toggleBool={toggleBool}
                            id={id}
                            handleCloneAssignment={handleCloneAssignment}
                            handleDeleteAssignment={handleDeleteAssignment}
                            handleRenameAssignment={handleRenameClick}
                        />
                    </div>
                </div>

                <div
                    className={`absolute bottom-0 right-0 w-8 h-8 rounded-tl-full ${getPublicationStatusClass()}`}
                ></div>
            </div>
        );
    };

    const AssignmentList = ({assignments}) => {
        return (
            <div className="container mx-auto mt-6 relative pb-16">
                {' '}
                {/* Added relative and pb-16 */}
                <div className="grid grid-cols-1 md:grid-cols-2 gap-4 min-w-20">
                    {assignments && assignments.length > 0 ? (
                        assignments.map(value => (
                            <AssignmentCard
                                key={value.id}
                                id={value.id}
                                title={value.title}
                                num_submissions={value.num_submissions}
                                num_students={value.course.num_students}
                                due_date={value.due_date}
                                due_time={value.due_time}
                                timezone={value.timezone}
                                is_exam={value.is_exam}
                                is_essay={value.is_essay}
                                is_group_task={value.is_group_task}
                                is_assignment_published={value.is_assignment_published}
                                is_grades_published={value.is_grades_published}
                                setErrorMessage={setErrorMessage}
                                handleRenameSubmit={handleRenameSubmit}
                            />
                        ))
                    ) : (
                        <p></p>
                    )}
                </div>
            </div>
        );
    };

    const AssignmentRow = ({assignmentInfo}) => {
        const [myBool, setmyBool] = React.useState(true);

        function toggleBool() {
            setmyBool(!myBool);
        }

        return (
            <div className="hover:border rounded-full py-0.5 px-3 transition-all duration-100 desktop:hover:shadow-sm">
                <div className="grid grid-cols-5 align-middle">
                    <div className="classes-table-content">
                        <Link
                            to={`/tclasses/${props.class_id}/tassignments/${assignmentInfo.id}/tsubmissions`}
                            className="classes-table-content"
                        >
                            {assignmentInfo.title}
                        </Link>
                    </div>
                    <div className="classes-table-content">
                        {assignmentInfo.is_essay ? 'Essay' : assignmentInfo.is_exam ? 'Teacher' : 'Student'}
                    </div>
                    <div className="classes-table-content">
                        {assignmentInfo.is_exam ? 'Teacher' : 'Student'}
                    </div>
                    <DueTimeDisplay
                        dueTime={assignmentInfo.due_time}
                        dueDate={assignmentInfo.due_date}
                        timezone={assignmentInfo.timezone}
                    />
                    <div className="classes-table-content text-right">
                        {assignmentInfo.num_submissions}/{assignmentInfo.course.num_students}
                    </div>
                    <div className="classes-table-content text-right flex justify-end p-2">
                        <MoreButton myBool={myBool} toggleBool={toggleBool} id={assignmentInfo.id}/>
                    </div>
                </div>
            </div>
        );
    };

    // ClassTable component
    const AssignmentTable = ({assignments}) => {
        return (
            <div className="min-w-full">
                <div className="grid grid-cols-5 gap-4 mb-6 p-2 font-bold border-b border-black">
                    <div className="classes-column-header">Assignment</div>
                    <div className="classes-column-header">Submission Type</div>
                    <div className="classes-column-header">Due Date</div>
                    <div className="classes-column-header text-right">Submissions</div>
                    <div className="classes-column-header text-right">Actions</div>
                </div>
                {assignments ? (
                    <div>
                        {assignments.map(value, i => (
                            <AssignmentRow key={`${i} + ${Math.random()}`} assignmentInfo={value}/>
                        ))}
                    </div>
                ) : (
                    <div className="text-center">No Assignments available</div>
                )}
            </div>
        );
    };

    const [cardView, setCardView] = useState(true); // Changed to camelCase to follow convention

    // Corrected typo in function name
    function toggleCardView() {
        setCardView(!cardView);
    }

    // Your changeView function remains unchanged
    const changeView = ({assignments}) => {
        return cardView ? (
            <AssignmentList assignments={assignments}/>
        ) : (
            <AssignmentTable assignments={assignments}/>
        );
    };

    const [dateFocused, setDateFocused] = useState(false);
    const [timeFocused, setTimeFocused] = useState(false);
    return (
        <div id="HomeTeacher" className="flex flex-row p-8 justify-start h-screen">
            <div id="Main-Content-container" className="flex flex-col w-full relative">
                {/* Sticky header - now matching content width */}
                <div className="fixed top-8 bg-white z-50"
                     style={{width: 'calc(56%)'}}>
                    <div id="Overview" className="flex flex-col space-y-2">
                        <div className="flex flex-row justify-between items-center">
                            <div className="flex flex-row items-center">
                                <Link to="/tclasses">
                                    <span className="text-black text-xl font-medium">My Classes</span>
                                </Link>
                                <Link to="/tclasses">
                                    <div className="size-6 flex items-center justify-center">
                                        <button>
                                            <img src="/assets/back_arrow.png" className="size-6" alt="Back"/>
                                        </button>
                                    </div>
                                </Link>
                                <h1 className="text-black text-xl font-medium ml-4">{className ? className : ''}</h1>
                            </div>
                            <div className="flex items-center space-x-4">
                                <button
                                    className="p-2 rounded-full hover:bg-gray-100 transition-colors duration-200"
                                    onClick={() => {/* Add your settings handler here */
                                    }}
                                >
                                    <Link to={`/csettings/${props.class_id}`}>
                                        <Settings className="w-5 h-5 text-gray-600"/>
                                    </Link>
                                </button>
                                <button
                                    className="sexy-button transition-all duration-300 desktop:hover:-translate-y-0.5 desktop:hover:shadow h-9 w-36 px-4 py-2 flex items-center justify-center"
                                    onClick={() => setIsModalOpen(true)}
                                >
                                    New Assignment
                                </button>
                            </div>
                        </div>
                        <div className="flex justify-end">
                            <div className="flex items-center bg-grey-700 rounded-full p-2 shadow-md">
                                <input
                                    type="checkbox"
                                    id="openClassCheckBox"
                                    checked={classOpen}
                                    className="sr-only peer"
                                    onChange={handleChangeClassOpen}
                                />
                                <label
                                    htmlFor="openClassCheckBox"
                                    className={`
                  relative inline-flex h-6 w-11 items-center rounded-full transition-colors duration-200 ease-in-out mr-2 cursor-pointer
                  ${classOpen ? 'bg-orange-200 border-orange-300' : 'bg-gray-200 hover:bg-gray-300'}
                `}
                                >
                                    <span
                                        className={`
                    inline-block h-4 w-4 transform rounded-full bg-white transition-transform duration-200 ease-in-out
                    ${classOpen ? 'translate-x-6' : 'translate-x-1'}
                  `}
                                    />
                                </label>
                                <label htmlFor="openClassCheckBox" className="text-sm font-medium text-black">
                                    Join by Class Code ({code})
                                </label>
                            </div>
                        </div>
                    </div>
                </div>

                {/* Scrollable content */}
                <div className="mt-28 overflow-y-auto hide-scrollbar">
                    {changeView({assignments})}
                </div>

                {isCloneModalOpen && (
                    <CloneModal
                        isOpen={isCloneModalOpen}
                        assignmentName={cloneAssignmentName}
                        setAssignmentName={setCloneAssignmentName}
                        dueDate={cloneDueDate}
                        dueTime={cloneDueTime}
                        setDueTime={setCloneDueTime}
                        timezone={timezone}
                        setDueDate={setCloneDueDate}
                        errorMessage={errorMessage}
                        setErrorMessage={setErrorMessage}
                        originalCloneName={originalCloneName}
                        submissionType={cloneSubmissionType}
                        setSubmissionType={setCloneSubmissionType}
                        isEssay={assignments.find(a => a.id === cloneAssignmentId)?.is_essay}
                        cloneClassId={cloneClassId}
                        setCloneClassId={setCloneClassId}
                        userClasses={userClasses}
                        isGroupSubmission={isGroupSubmission}
                        setIsGroupSubmission={setIsGroupSubmission}
                        onClose={() => setIsCloneModalOpen(false)}
                        onConfirm={confirmCloneAssignment}
                    />
                )}
                {isModalOpen && (
                    <Modal
                        isModalOpen={isModalOpen}
                        setIsModalOpen={setIsModalOpen}
                        handleSubmit={handleSubmit}
                        assignmentName={assignmentName}
                        setAssignmentName={setAssignmentName}
                        format={format}
                        setFormat={setFormat}
                        dueDate={dueDate}
                        setDueDate={setDueDate}
                        dueTime={dueTime}
                        timezone={timezone}
                        setDueTime={setDueTime}
                        studentSubmitted={studentSubmitted}
                        setStudentSubmitted={setStudentSubmitted}
                        errorMessage={errorMessage}
                        setErrorMessage={setErrorMessage}
                        dateFocused={dateFocused}
                        setDateFocused={setDateFocused}
                        timeFocused={timeFocused}
                        setTimeFocused={setTimeFocused}
                        modalRef={modalRef}
                        isGroupSubmission={isGroupSubmission}
                        setIsGroupSubmission={setIsGroupSubmission}
                    />
                )}
                <ConfirmationModal
                    isOpen={isConfirmationModalOpen}
                    message="Are you sure you want to delete this assignment? This action cannot be undone."
                    onClose={() => setIsConfirmationModalOpen(false)}
                    onConfirm={confirmDeleteAssignment}
                />
            </div>
        </div>
    )
        ;
}

export default TAssignments;
