import React, { useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { gradeApi } from '../api.js';

const Verify = () => {
    const [error, setError] = useState({});
    const [isVerified, setIsVerified] = useState(false);
    let { verification_token } = useParams();
    const navigate = useNavigate();

    const GradeWizHeader = () => {
        return (
            <div className="">
                <div className="flex items-center space-x-2 pb-3">
                    <div className="size-12 flex items-center justify-center">
                        <img src="/assets/logo_icon.png" alt="GradeWiz Icon" className="size-12" />
                    </div>
                    <span className="text-minilg font-medium text-gray-950">GradeWiz</span>
                </div>
            </div>
        );
    };

    const verifyEmail = () => {
        try {
            gradeApi
                .get({
                    path: `/api/verify-email/${verification_token}/`
                })
                .then(message => {
                    setError(message.data);
                    setIsVerified(true);
                    // console.log(message.data);
                });
        } catch (error) {
            console.log('Error in Server');
            setError({ error: 'An error occurred while verifying your email.' });
        }
    };

    const handleVerify = () => {
        verifyEmail();
    };

    const handleLogin = () => {
        navigate('/login');
    };

    return (
        <div className="h-screen bg-backgray pt-14 flex flex-col justify-between">
            <div className="flex items-center justify-center w-[325px] mx-auto p-6 sexy-border h-[385px]">
                <div
                    className="absolute w-[350px] mx-auto p-6 sexy-border bg-white mt-6 flex flex-col justify-between h-[380px]">
                    <GradeWizHeader />
                    <div className="flex flex-col items-center">
                        <h1 className="custom-title-sm text-black mb-5 font-bold">Verify Email</h1>
                        <h2 className="custom-text-xl text-black mb-4">
                            {isVerified
                                ? 'message' in error
                                    ? error.message
                                    : error.error
                                : 'Thanks for signing up! Click the button below to verify your email.'}
                        </h2>
                        {!isVerified && (
                            <button
                                className="mt-6 px-4 py-4 rounded-3xl border-[1px] border-black text-lg font-medium transition-all duration-300 desktop:hover:-translate-y-[0.5px] desktop:hover:shadow bg-orange-200 hover:bg-orange-300 text-black w-full"
                                onClick={handleVerify}
                            >
                                Verify Email
                            </button>
                        )}
                        <button
                            className="mt-6 px-4 py-4 rounded-3xl border-[1px] border-black text-lg font-medium transition-all duration-300 desktop:hover:-translate-y-[0.5px] desktop:hover:shadow bg-white hover:bg-gray-100 text-black w-full"
                            onClick={handleLogin}
                        >
                            Go back to Login
                        </button>
                    </div>
                </div>
            </div>
            {/*<div className="flex items-center justify-center space-x-3 text-gray-500 text-smallish mb-28">*/}
            {/*    <button>Terms of Use</button>*/}
            {/*    <div> |</div>*/}
            {/*    <button>Privacy Policy</button>*/}
            {/*</div>*/}
        </div>
    );
};

export default Verify;
