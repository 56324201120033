import Cookies from 'js-cookie';
import { isEmpty } from 'lodash';
import React from 'react';
import { Navigate, Outlet } from 'react-router-dom';
import { Loading } from '../components/Loading';
import { useAuth } from './AuthContext';

const PublicRoute = () => {
  const { user, isLoading } = useAuth();
  const sessionToken = Cookies.get('session_token');
  const userId = Cookies.get('user_id');

  if (sessionToken && userId) {
    if (isEmpty(user) || isLoading) {
      return <Loading />;
    }

    return user.user.is_teacher ? <Navigate to="/tclasses" /> : <Navigate to="/sclasses" />;
  }

  return <Outlet />;
};

export default PublicRoute;
