import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { gradeApi } from '../../../api.js';
import { useAuth } from '../../../auth/AuthContext.js';
import AssignmentList from './AssignmentList.js';
import ClassBar from './ClassBar.js';
import ClassMeanMedian from './ClassMeanMedian.js';
import StudentList from './StudentList.js';

const TClassStats = () => {
    let { class_id } = useParams();

    const [className, setClassName] = useState('');
    const [assignments, setAssignments] = useState([]);
    const [assignmentIds, setAssignmentIds] = useState([]);
    const [assignmentNames, setAssignmentNames] = useState({});
    const [assignmentStats, setAssignmentStats] = useState({});
    const [studentStats, setStudentStats] = useState({});
    const [totalScoreStats, setTotalScoreStats] = useState({});

    const [isErrorVisible, setIsErrorVisible] = useState(false);

    const { user } = useAuth();
    const [errorMessage, setErrorMessage] = useState('');

    const fetchAssignments = async () => {
        try {
            const response = await gradeApi.get({
                path: `/api/courses/${class_id}/teacher-assignments-lite/`,
                config: {
                    headers: {
                        Authorization: `Bearer ${user.session_token}`
                    }
                }
            });
            // console.log(response);
            setAssignments(
                response.data.assignments.filter(assignment => assignment.is_assignment_published)
            );
            setClassName(response.data.class_name);
        } catch (error) {
            setErrorMessage('Failed to fetch assignments. Please try again.');
            setIsErrorVisible(true);
        }
    };

    const fetchSubmissions = async id => {
        try {
            const response = await gradeApi.get({
                path: `/api/assignment/${id}/submissions-lite/`,
                config: {
                    headers: {
                        Authorization: `Bearer ${user.session_token}`
                    }
                }
            });
            return response.data.submissions;
        } catch (error) {
            setErrorMessage('Failed to fetch scores. Please try again.');
            setIsErrorVisible(true);
            return [];
        }
    };

    const fetchIdsAndNames = async id => {
        const ids = assignments.map(assignment => assignment.id).sort((a, b) => b - a);
        const names = {};
        assignments.forEach(assignment => {
            names[assignment.id] = assignment.title;
        });
        setAssignmentIds(ids);
        setAssignmentNames(names);
    };

    const fetchQuestions = async id => {
        try {
            const response = await gradeApi.get({
                path: `/api/assignment/${id}/questions/`,
                config: {
                    headers: {
                        Authorization: `Bearer ${user.session_token}`
                    }
                }
            });
            return response.data.questions;
        } catch (error) {
            setErrorMessage('Failed to fetch questions. Please try again.');
            setIsErrorVisible(true);
            return [];
        }
    };

    const performCalculations = async () => {
        const assignmentStats = {};
        const studentStats = {};
        const promises = assignmentIds.map(async id => {
            let count = 0;
            const submissions = await fetchSubmissions(id);
            count += 1;
            submissions.forEach(submission => {
                const userId = submission.user.id;
                const userName = `${submission.user.first_name} ${submission.user.last_name}`;

                if (!studentStats[userId]) {
                    studentStats[userId] = { grades: {}, totalScore: 0, name: userName };
                }
                studentStats[userId].grades[id] = submission.grade;
            });
            Object.keys(studentStats).forEach(userId => {
                if (!studentStats[userId].grades[id]) {
                    studentStats[userId].grades[id] = 0;
                }
            });

            const grades = submissions.map(submission => submission.grade);
            const questions = await fetchQuestions(id);
            const maxPossibleScore = questions.reduce((total, question) => {
                return (
                    total +
                    question.subQuestions.reduce((subTotal, subQuestion) => {
                        const maxRubricValue = Math.max(...subQuestion.rubricItems.map(item => item.value));
                        return subTotal + maxRubricValue;
                    }, 0)
                );
            }, 0);
            const minPossibleScore = questions.reduce((total, question) => {
                return (
                    total +
                    question.subQuestions.reduce((subTotal, subQuestion) => {
                        const minRubricValue = Math.min(...subQuestion.rubricItems.map(item => item.value));
                        return subTotal + minRubricValue;
                    }, 0)
                );
            }, 0);
            const mean =
                grades.reduce((accumulator, currentValue) => accumulator + currentValue, 0) / grades.length;
            const sortedGrades = grades.slice().sort((a, b) => a - b);
            const mid = Math.floor(sortedGrades.length / 2);
            const median =
                sortedGrades.length % 2 !== 0
                    ? sortedGrades[mid]
                    : (sortedGrades[mid - 1] + sortedGrades[mid]) / 2;
            const variance =
                grades.reduce(
                    (accumulator, currentValue) => accumulator + Math.pow(currentValue - mean, 2),
                    0
                ) / grades.length;
            const stdDeviation = Math.sqrt(variance);
            assignmentStats[id] = {
                grades: grades,
                mean: mean,
                median: median,
                std: stdDeviation,
                max_score: maxPossibleScore,
                min_score: minPossibleScore,
                name: assignmentNames[id]
            };
        });

        await Promise.all(promises);
        Object.keys(studentStats).forEach(userId => {
            const grades = Object.values(studentStats[userId].grades);
            const totalScore = grades.reduce((acc, grade) => acc + grade, 0);
            studentStats[userId].totalScore = totalScore;
        });
        setStudentStats(studentStats);
        setAssignmentStats(assignmentStats);

        const totalScores = Object.values(studentStats).map(stats => stats.totalScore);
        const meanTotalScore = totalScores.reduce((acc, score) => acc + score, 0) / totalScores.length;
        const sortedScores = totalScores.slice().sort((a, b) => a - b);
        const mid = Math.floor(sortedScores.length / 2);
        const medianTotalScore =
            sortedScores.length % 2 !== 0
                ? sortedScores[mid]
                : (sortedScores[mid - 1] + sortedScores[mid]) / 2;
        const variance =
            totalScores.reduce((acc, score) => acc + Math.pow(score - meanTotalScore, 2), 0) /
            totalScores.length;
        const stdDeviationTotalScore = Math.sqrt(variance);
        const highestTotalScore = Math.max(...totalScores);
        const lowestTotalScore = Math.min(...totalScores);
        const highestPossibleTotalScore = Object.values(assignmentStats).reduce(
            (acc, { max_score }) => acc + max_score,
            0
        );
        const lowestPossibleTotalScore = Object.values(assignmentStats).reduce(
            (acc, { min_score }) => acc + min_score,
            0
        );

        setTotalScoreStats({
            meanTotalScore,
            medianTotalScore,
            stdDeviationTotalScore,
            highestTotalScore,
            lowestTotalScore,
            highestPossibleTotalScore,
            lowestPossibleTotalScore
        });
    };

    useEffect(() => {
        fetchAssignments();
    }, []);

    useEffect(() => {
        if (assignments.length > 0) {
            fetchIdsAndNames();
        }
    }, [assignments]);

    useEffect(() => {
        if (assignmentIds.length > 0) {
            performCalculations();
        }
    }, [assignmentIds, assignmentNames]);

    // console.log(studentStats);
    return (
        <div id="ClassStatistics" className="flex flex-row p-8 justify-start">
            <div id="Main-Content-container" className="flex flex-col space-y-4 w-full">
                <div id="Overview" className="flex flex-row space-x-full w-full justify-between">
                    <h1 className="text-black text-xl font-medium">{className} Statistics</h1>
                </div>
                <div className="bg-white sexy-border p-6">
                    {isErrorVisible && (
                        <div className="bg-red-100 border border-red-400 text-red-700 px-4 py-3 rounded mb-4">
                            {errorMessage}
                        </div>
                    )}
                    {assignmentIds.length > 0 ? (
                        <div className="space-y-6">
                            <div>
                                <ClassBar data={assignmentStats} />
                            </div>
                            <div>
                                <ClassMeanMedian {...totalScoreStats} />
                            </div>
                            <div>
                                <AssignmentList class_id={class_id} assignmentStats={assignmentStats} />
                            </div>
                            <div>
                                <StudentList studentStats={studentStats} assignmentNames={assignmentNames} />
                            </div>
                        </div>
                    ) : (
                        <h1>No published assignments found in {className}.</h1>
                    )}
                </div>
            </div>
        </div>
    );
};

export default TClassStats;
